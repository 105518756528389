import {
  InputGroup, InputLeftElement, Input, InputGroupProps, Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

interface SearchBarProps extends InputGroupProps {
  name: string;
  placeholder?: string;
  onInput: (data: any) => void;
  variant?: string;
  searchIconColor?: string;
  inputProps?: object;
}

export default function SearchBar({
  name,
  placeholder = "Search",
  onInput,
  variant = "outline",
  searchIconColor = "fg.muted",
  inputProps = {},
  ...rest
}: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    onInput(searchInput);
  }, [searchInput, onInput]);

  return (
    <InputGroup
      borderRadius="md"
      maxW="md"
      display="inline-flex"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      {...rest}
    >
      <InputLeftElement height="100%">
        <Icon as={FiSearch} color={searchIconColor} fontSize="lg" />
      </InputLeftElement>
      <Input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder={placeholder}
        variant={variant}
        {...inputProps}
      />
    </InputGroup>
  );
}
