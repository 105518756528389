import {
  FormErrorMessage, FormHelperText, FormControl, Flex, FormLabel,
} from "@chakra-ui/react";
import clsx from "clsx";
import { FormFieldProps } from "components/Form/typings";
import lodash from "lodash";

export default function FormField({
  name,
  label,
  errors,
  helperText,
  required,
  disabled,
  children,
  sideLayout = false,
  overrideHelperSpace = false,
  variant,
  flexProps = {},
  labelProps = {},
  ...rest
}: FormFieldProps & { variant: string }) {
  // Use lodash.get to handle dot-notated names
  const error = lodash.get(errors, name);

  const bottomComponent = () => {
    if (error) {
      return (
        <FormErrorMessage mt="1" fontSize="xs" color="error.DEFAULT">
          {(error as any).message}
        </FormErrorMessage>
      );
    }
    if (helperText) {
      return (
        <FormHelperText fontSize="xs" color="helperText.500" mt="1">
          {helperText}
        </FormHelperText>
      );
    }
    return overrideHelperSpace ? null : (
      <FormHelperText fontSize="xs" mt="1">
        &nbsp;
      </FormHelperText>
    );
  };

  return sideLayout ? (
    <FormControl id={name} isInvalid={!!error} isRequired={required} isDisabled={disabled} {...rest}>
      <Flex {...flexProps}>
        <FormLabel {...labelProps}>{label}</FormLabel>
        {children}
      </Flex>
      {bottomComponent()}
    </FormControl>
  ) : (
    <FormControl id={name} isInvalid={!!error} isRequired={required} isDisabled={disabled} {...rest}>
      {["floating", "flushedFloating"].includes(variant) ? (
        <>
          {children}
          <FormLabel {...labelProps} className={clsx("floating-label", labelProps?.className)}>
            {label}
          </FormLabel>
        </>
      ) : (
        <>
          <FormLabel {...labelProps}>{label}</FormLabel>
          {children}
        </>
      )}
      {bottomComponent()}
    </FormControl>
  );
}
