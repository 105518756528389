import { useColorModeValue, useTheme } from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { UseControllerProps, useController } from "react-hook-form";
import { CalendarIcon } from "@chakra-ui/icons";
import { PropsWithChildren, useMemo } from "react";
import { setTimeFromOriginalDateTime } from "helpers/time";
import { DEFAULT_DATE_FORMAT } from "definitions/constants";
import clsx from "clsx";

type DatePickerProps = {
  name: string;
  id?: string;
  disabled?: boolean;
  minDate?: Date;
  defaultIsOpen?: boolean;
  usePortal?: boolean;
  preserveTime?: boolean;
  dateFormat?: string;
  variant?: string;
  isClearable?: boolean;
  onDateChange?: (date: Date) => void;
} & UseControllerProps &
PropsWithChildren;

export default function ControlledDatePicker({
  control,
  name,
  id,
  rules,
  disabled,
  defaultValue,
  minDate,
  defaultIsOpen,
  usePortal,
  preserveTime,
  dateFormat,
  children,
  onDateChange,
  isClearable,
  variant,
}: DatePickerProps) {
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  const theme = useTheme();
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  const stylingClassName = ["floating", "flushedFloating"].includes(formVariant) ? "floating-button" : "";

  const iconColorScheme = useColorModeValue(theme.colors.gray[600], theme.colors.gray[300]);

  const date = useMemo(() => {
    const dateString = value || defaultValue;
    if (!dateString) return undefined;
    return new Date(dateString);
  }, [value, defaultValue]);

  const dateTransformer = useMemo(
    () => (newDate: Date) => {
      if (preserveTime && date) {
        // this is destructive
        setTimeFromOriginalDateTime(newDate, date);
      }
      return newDate;
    },
    [date, preserveTime],
  );

  const datePickerClassName = date ? "has-value" : "";
  const hasErrorClassName = invalid ? "has-error" : "";

  return (
    <SingleDatepicker
      id={id || name}
      name={name}
      usePortal={usePortal}
      date={date}
      minDate={minDate}
      onDateChange={(val) => (onDateChange ? onDateChange(dateTransformer(val)) : onChange(dateTransformer(val)))}
      disabled={disabled}
      configs={{ dateFormat }}
      isClearable={isClearable}
      defaultIsOpen={defaultIsOpen}
      propsConfigs={{
        triggerBtnProps: {
          width: "100%",
          justifyContent: "start",
          color: "fg.muted",
          fontSize: "md",
          fontWeight: "normal",
          variant: formVariant,
          iconSpacing: "auto",
          className: clsx(stylingClassName, datePickerClassName, hasErrorClassName),
          rightIcon: <CalendarIcon color={iconColorScheme} />,
        },
        dayOfMonthBtnProps: {
          todayBtnProps: {
            background: "brand.400",
          },
        },
      }}
    >
      {children && ((_value) => children)}
    </SingleDatepicker>
  );
}

ControlledDatePicker.defaultProps = {
  id: undefined,
  disabled: undefined,
  minDate: undefined,
  defaultIsOpen: false,
  usePortal: false,
  preserveTime: false,
  dateFormat: DEFAULT_DATE_FORMAT,
  variant: undefined,
  onDateChange: undefined,
};
