/* eslint-disable import/prefer-default-export */

import { useQuery } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { apiPathPathById } from "helpers/url";
import { Path } from "models/automation";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export const pathKeys = {
  all: ["paths"] as const,
  lists: () => [...pathKeys.all, "list"] as const,
  list: (filters: string) => [...pathKeys.lists(), { filters }] as const,
  details: () => [...pathKeys.all, "detail"] as const,
  detail: (id: string) => [...pathKeys.details(), id] as const,
};

export const usePathDetailQuery = (id: string, options = {}) => useQuery<Path>({
  queryKey: pathKeys.detail(id),
  queryFn: async () => apiClient.get(apiPathPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const usePathListQuery = (queryString: string, options = {}) => {
  const query = queryString ? `?${queryString}` : "";
  return useQuery({
    queryKey: queryString ? pathKeys.list(queryString) : pathKeys.lists(),
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.paths.base)}${query}`),
    staleTime: 1000 * 60 * 3, // 3 minutes
    ...options,
  });
}
