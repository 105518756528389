import { Input, useTheme } from "@chakra-ui/react";
import { ControlInputFieldProps, TextInputFieldProps } from "components/Form/typings";
import { toTimeWithoutSeconds, toTimeWithSeconds } from "helpers/trigger";
import { useState, useEffect } from "react";
import { useController, useWatch } from "react-hook-form";
import FormField from "./FormField";

export default function TimePickerField({
  control,
  name,
  label,
  errors,
  placeholder,
  helperText,
  required,
  textTransform,
  defaultValue,
  variant,
  formFieldProps = {},
  ...props
}: ControlInputFieldProps & Pick<TextInputFieldProps, "textTransform">) {
  const theme = useTheme();
  const {
    field: {
      name: fieldName, onChange, onBlur, value, ref,
    },
  } = useController({
    name,
    control,
    rules: { required },
    ...(defaultValue ? { defaultValue } : {}),
  });

  // local value => without Seconds (as the input with type="time" does not have seconds)
  const [time, setTime] = useState(value ? toTimeWithoutSeconds(value) : "");
  // useForm value => with Seconds
  const watchedValue = useWatch({ control, name });

  // Format and set the local value when useForm value changes
  useEffect(() => {
    const formattedValue = toTimeWithoutSeconds(watchedValue);
    if (watchedValue && formattedValue !== time) {
      setTime(formattedValue);
    }
  }, [watchedValue, time]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setTime(inputValue);
    const formattedValue = toTimeWithSeconds(inputValue);
    onChange(formattedValue);
  };

  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
      {...formFieldProps}
    >
      <Input
        textTransform={textTransform}
        type="time"
        aria-label={`Select time for ${label}`}
        placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
        {...{
          name: fieldName,
          onChange: handleChange,
          onBlur,
          ref,
          value: time,
        }}
        _hover={{
          cursor: "pointer",
        }}
        {...props}
      />
    </FormField>
  );
}

TimePickerField.defaultProps = {
  formFieldProps: {},
};
