/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  Box,
  StepTitle,
  Circle,
  Button,
  Spinner,
  Text,
  Icon,
  Flex,
  StepSeparator,
  useToast,
  ButtonGroup,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
  useDisclosure,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
import { AiOutlineDatabase, AiOutlineIdcard } from "react-icons/ai";
import { BiGitMerge } from "react-icons/bi";
import { SubmitHandler, useForm, useFormState, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { startCase } from "lodash";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import { useNewHireJourneyDetailQuery, useUpdateNewHireJourney } from "features/NewHireJourney/hooks";
import ActionsByStage from "features/NewHireJourney/Schedule/ActionsByStage";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import ExternalLink from "components/Link";
import { dirtyValues } from "helpers/form";
import { useStageListQuery } from "features/Stage/hooks";
import AddPathsForm from "../Form/AddPathsForm";
import autoFormDefaultValues, { buildFormSchema } from "../Form/buildForm";
import { PENDING_FORM_SECTIONS } from "../Form/pendingNewHireForm";
import InfoAlert from "../Form/NewHireAlert";
import ReviewModal from "../Form/ReviewModal";
import useKickOffOnboarding from "../Form/useKickOffOnboarding";
import PendingNewHireForm from "../Form/NewHirePendingForm";
import { NewHireFormValues, pendingNewHireSchema, ONBOARDING_PLAN_URL_FIELD } from "../Form/definition";

interface AddInfoSectionProps {
  newHireJourneyId: string;
  form: ReturnType<typeof useForm>;
  isLoaded: boolean;
}

// Step components
function AddInfoSection({ form, newHireJourneyId, isLoaded }: AddInfoSectionProps) {
  return (
    <Flex width="100%" maxWidth="900px" justify="center">
      <PendingNewHireForm form={form} isLoaded={isLoaded} newHireJourneyId={newHireJourneyId} />
    </Flex>
  );
}

function ReviewSection({ newHireJourneyId, form: mainForm }: { newHireJourneyId: string; form: ReturnType<typeof useForm> }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data: company } = useCurrentCompanyQuery();
  const { data: newHireJourney } = useNewHireJourneyDetailQuery(newHireJourneyId || "");

  const { isValid } = useFormState({ control: mainForm.control });
  const watchedValues = useWatch({ control: mainForm.control });
  const { mutate } = useUpdateNewHireJourney(newHireJourney?.id || "");

  const form = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(pendingNewHireSchema.pick([ONBOARDING_PLAN_URL_FIELD.name as "onboardingPlanUrl"])),
    values: {
      onboardingPlanUrl: newHireJourney?.onboardingPlanUrl,
    },
  });
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = form;

  const onSubmit: SubmitHandler<NewHireFormValues> = (data) => {
    const filteredData = dirtyValues(data, dirtyFields);
    if (Object.keys(filteredData).length > 0) mutate(filteredData);
  };

  useEffect(() => {
    forceUpdate();
  }, [isValid, watchedValues]);

  return (
    <Box width="100%" maxWidth="900px" height="100%">
      <Box textAlign="center" mt="4" mb="4">
        {isValid ? (
          <Text color="fg.emphasized">🎉 You’ve completed the profile information! 🎉</Text>
        ) : (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle color="fg.subtle">Form Error!</AlertTitle>
            <AlertDescription color="fg.subtle">Please go back to the first step and fix the errors.</AlertDescription>
          </Alert>
        )}
      </Box>
      {!!company && company.hasOnboardingPlans ? (
        <>
          <Flex direction="column" mt="6" mb="1">
            <Text fontSize="lg" fontWeight="bold" color="fg.emphasized" my="2">
              Add Onboarding Plan Template
            </Text>
            <Text color="fg.subtle" fontSize="sm">
              Add the URL to the onboarding plan for the new hire to be filled out by the manager.
            </Text>
            {company?.onboardingPlanTemplatesUrl ? (
              <Text color="fg.subtle" fontSize="sm">
                You can create it from
                {" "}
                <ExternalLink as="span" to={company.onboardingPlanTemplatesUrl} text="this onboarding plan template" />
                .
              </Text>
            ) : null}
            <Box mt="4">
              <TextInputField
                {...ONBOARDING_PLAN_URL_FIELD}
                onBlur={handleSubmit(onSubmit)}
                register={register}
                errors={errors}
              />
            </Box>
          </Flex>
          <Divider my="8" />
        </>
      ) : null}
      <Flex direction="column" mb="1">
        <Text fontSize="lg" fontWeight="bold" color="fg.emphasized" my="2">
          Paths
        </Text>
        <AddPathsForm newHireJourney={newHireJourney} />
      </Flex>
    </Box>
  );
}

function BuildOnboardingSection({ newHireJourneyId }: { newHireJourneyId: string }) {
  const { data: newHireJourney, isLoading } = useNewHireJourneyDetailQuery(newHireJourneyId || "");

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" mb="2">
      <Flex maxWidth="100%" direction="column" mt="6">
        <Text fontSize="lg" fontWeight="bold" color="fg.emphasized" mb="2">
          Actions
        </Text>
        <InfoAlert />
        <ActionsByStage isLoading={isLoading} newHireJourney={newHireJourney} />
      </Flex>
    </Box>
  );
}

function fieldNamesToTitleize(fieldNames: string[]) {
  return fieldNames
    .filter((fieldName) => !["country", "googleLocationId", "rawLocation"].includes(fieldName))
    .map((fieldName) => startCase(fieldName))
    .join(", ");
}

const steps = [
  { title: "Add Info", icon: AiOutlineIdcard },
  { title: "Build Onboarding", icon: BiGitMerge },
  { title: "Review Automations", icon: AiOutlineDatabase },
];

function KickOff({ newHireJourneyId }: { newHireJourneyId: string }) {
  const { isOpen: reviewModalIsOpen, onOpen: openReviewModal, onClose: closeReviewModal } = useDisclosure();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    isClosable: true,
    position: "top",
    duration: 5000,
  });
  // prefetching stages
  useStageListQuery();
  const { data: newHireJourney, isLoading: isNewHireJourneyLoading } = useNewHireJourneyDetailQuery(newHireJourneyId);

  const schema = useMemo(() => buildFormSchema(PENDING_FORM_SECTIONS), []);

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    values: autoFormDefaultValues(PENDING_FORM_SECTIONS, newHireJourney),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
      keepTouched: true,
    },
  });

  const [firstName, googleLocationId] = useWatch({
    control: form.control,
    name: ["firstName", "googleLocationId"],
  });

  const hasLoaded = useRef<boolean>(false);
  const prevGoogleLocationId = useRef<string | null>(null);

  useEffect(() => {
    if (firstName && hasLoaded.current === false) {
      hasLoaded.current = true;
      prevGoogleLocationId.current = googleLocationId;
    }
  }, [firstName, googleLocationId]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <AddInfoSection isLoaded={!isNewHireJourneyLoading} form={form} newHireJourneyId={newHireJourneyId} />;
      case 1:
        return <ReviewSection form={form} newHireJourneyId={newHireJourneyId} />;
      case 2:
        return <BuildOnboardingSection newHireJourneyId={newHireJourneyId} />;
      default:
        return null;
    }
  };

  const handleSubmitAndAdvance = useCallback(async () => {
    setIsLoading(true);
    try {
      if (activeStep === 0) {
        const fieldsValid = await form.trigger();
        if (!fieldsValid) {
          const currentErrors = form.formState.errors;
          const errorFieldNames = fieldNamesToTitleize(Object.keys(currentErrors));
          toast({
            title: "Form Error",
            description: `There are errors in the following fields: ${errorFieldNames}`,
            status: "error",
          });
          return;
        }
      }

      // Proceed to the next step if validation is successful
      setActiveStep((prev) => prev + 1);
    } catch (err) {
      console.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [activeStep, form, setActiveStep, toast]);

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const { handleSubmit, isSubmitting } = useKickOffOnboarding({ newHireJourneyId, onClose: closeReviewModal });
  const headerBg = useColorModeValue("gray.50", "gray.500");
  const footerBg = useColorModeValue("white", "gray.800");

  return (
    <Flex width="100%" height="100%" direction="column" align="center">
      <Flex
        id="stepperHeader"
        position="sticky"
        justify="center"
        top="48px"
        zIndex="2"
        bg={headerBg}
        width="calc(100% + 48px)"
        px="8"
        py="4"
      >
        <Box width="100%" minWidth="220px" alignSelf="center">
          <Stepper index={activeStep} gap="3">
            {steps.map((step, index) => (
              <Step key={step.title} onClick={() => handleStepClick(index)} cursor="pointer">
                <StepIndicator
                  bg="transparent"
                  borderWidth="0 !important"
                  boxShadow="none !important"
                  sx={{
                    "[data-status=complete] &": {
                      background: "transparent",
                    },
                    "[data-status=active] &": {
                      background: "transparent",
                    },
                    "[data-status=incomplete] &": {
                      background: "transparent",
                    },
                  }}
                >
                  <StepStatus
                    complete={(
                      <Circle size="8" bg="transparent" borderWidth="2px" borderColor="successGreen.500">
                        <Circle size="6" bg="successGreen.500" borderColor="transparent">
                          <Icon as={step.icon} boxSize="20px" color="white" borderColor="transparent" />
                        </Circle>
                      </Circle>
                    )}
                    incomplete={(
                      <Circle size="8" bg="transparent" borderWidth="2px" borderColor="gray.400">
                        <Icon as={step.icon} boxSize="20px" color="gray.400" stroke="transparent" strokeWidth="0" />
                      </Circle>
                    )}
                    active={(
                      <Circle size="8" bg="transparent" borderWidth="2px" borderColor="brand.500">
                        <Circle size="6" bg="brand.500" borderColor="transparent">
                          <Icon as={step.icon} boxSize="20px" color="white" borderColor="transparent" />
                        </Circle>
                      </Circle>
                    )}
                  />
                </StepIndicator>
                <StepTitle color="fg.emphasized">{step.title}</StepTitle>
                <StepSeparator
                  _horizontal={{
                    "[data-status=complete] &": {
                      background: "successGreen.500",
                    },
                  }}
                />
              </Step>
            ))}
          </Stepper>
        </Box>
      </Flex>

      <Flex flex="1" width="100%" justify="center" minHeight="fit-content" height="100%">
        {renderStepContent(activeStep)}
      </Flex>

      <Flex
        position="sticky"
        bottom="0"
        zIndex="2"
        backgroundColor={footerBg}
        padding="10px"
        justifyContent="space-around"
        alignItems="center"
        width="calc(100% + 48px)"
        paddingRight="20"
        borderTop="1px solid var(--chakra-colors-boldBlue-500)"
      >
        <Box />

        <ButtonGroup gap="8">
          {activeStep !== 0 ? (
            <Button isDisabled={isLoading} variant="link" onClick={() => setActiveStep(activeStep - 1)}>
              Back
            </Button>
          ) : (
            <Box as="span" visibility="hidden">
              Back
            </Box>
          )}

          <Button
            isDisabled={activeStep === steps.length - 1 ? false : isLoading}
            onClick={activeStep === steps.length - 1 ? openReviewModal : handleSubmitAndAdvance}
            isLoading={isLoading}
            loadingText="Submitting"
          >
            {isLoading ? <Spinner size="sm" /> : activeStep === steps.length - 1 ? "Kick Off Onboarding" : "Next"}
          </Button>
        </ButtonGroup>
      </Flex>
      <ReviewModal
        newHireJourneyId={newHireJourneyId}
        isOpen={reviewModalIsOpen}
        onClose={closeReviewModal}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </Flex>
  );
}

export default KickOff;
