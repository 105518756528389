import { PropsWithChildren } from "react";
import { BaseAssignedAction } from "models/automation";
import { DynamicActionType } from "components/ActionType";
import ModalFormWrapper from "../ModalFormWrapper";
import ScheduledActionForm from ".";

interface AssignedActionButtonWithModalProps extends PropsWithChildren {
  assignedAction: BaseAssignedAction | null;
}

export default function AssignedActionButtonWithModal({
  assignedAction,
  children,
}: AssignedActionButtonWithModalProps) {
  return (
    <ModalFormWrapper
      eid={assignedAction?.id}
      size="5xl"
      modalHeader={
        <DynamicActionType actionType={assignedAction.actionType} />
      }
      button={(children as JSX.Element)}
    >
      {assignedAction && (
        <ScheduledActionForm assignedAction={assignedAction} />
      )}
    </ModalFormWrapper>
  );
}
