import { useMemo, useState } from "react";
import {
  Center, Checkbox, CheckboxGroup, Flex, HStack, SystemStyleObject,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { ExtendedNewHireJourney } from "models";
import { MAX_CARD_WIDTH } from "definitions/constants";
import { EmptyNewHireJourneyState } from "components/EmptyComponents/RandomEmptyState";
import BaseCardTable from "components/Table/CardTable/BaseCardTable";
import useTableQuery from "hooks/useTableQuery";
import { BlueBorderCard } from "components/Card/BlueBorderCard";
import SearchBar from "components/SearchBar";
import SmallArrowPagination from "components/Table/SmallArrowPagination";
import TableSortSelect from "components/TableSortSelect";
import LinkButton from "components/Button/LinkButton";
import NewHireJourneyCard from "./NewHireJourneyCard";
import { newHireJourneyKeys } from "../hooks";

const SORT_OPTIONS = [
  { id: "name", desc: false, label: "Name (from A)" },
  { id: "name", desc: true, label: "Name (from Z)" },
  { id: "createdAt", desc: true, label: "Added (Recent)" },
  { id: "createdAt", desc: false, label: "Added (Oldest)" },
  { id: "startDate", desc: true, label: "Starting (Soonest)" },
  { id: "startDate", desc: false, label: "Starting (Oldest)" },
  { id: "manager", desc: true, label: "Manager" },
  { id: "buddy", desc: true, label: "Buddy" },
  { id: "onboardingPlanUrl", desc: true, label: "Onboarding Plan" },
];

const columnHelper = createColumnHelper<ExtendedNewHireJourney>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "New Hire",
  }),
  columnHelper.accessor((row) => row, {
    id: "createdAt",
    header: "Added",
  }),
  columnHelper.accessor("startDate", {
    id: "startDate",
    header: "Start Date",
    sortDescFirst: false,
  }),
  columnHelper.accessor("manager", {
    id: "manager",
    header: "Manager",
  }),
  columnHelper.accessor("buddy", {
    id: "buddy",
    header: "Buddy",
  }),
  columnHelper.accessor((row) => row, {
    id: "onboardingPlanUrl",
    header: "Onboarding Plan Url",
  }),
];

interface NewHireJourneysTableProps {
  sx?: SystemStyleObject;
  getCardProps?: () => object;
}

export default function NewHireJourneyCardTable({ getCardProps, sx, ...rest }: NewHireJourneysTableProps) {
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const filters = useMemo(() => {
    const pageFilters: FilterMeta[] = [];
    if (selectedValues.includes("noBuddyAssigned")) {
      pageFilters.push({
        fieldName: "buddy_id",
        value: null,
        operator: "is",
      });
    }
    if (selectedValues.includes("noOnboardingPlan")) {
      pageFilters.push({
        fieldName: "onboarding_plan_url",
        value: null,
        operator: "is",
      });
    }
    return pageFilters;
  }, [selectedValues]);

  const additionalQueries = useMemo(() => {
    const queries: Record<string, string | string[]> = {
      includes: ["assigned_paths"],
    };
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);

  const { table, isLoading } = useTableQuery<ExtendedNewHireJourney>({
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<ExtendedNewHireJourney[]>>(`${API_ROUTES.newHireJourneys.base}${query}`, signal)
      .then((response) => response.payload),
    // an additional key of the query string is added to this in the generic table
    queryKey: newHireJourneyKeys.lists(),
    columns,
    meta: { pagination: { perPage: 20 }, sort: [{ sortBy: "startDate", direction: "desc" }], filters },
    additionalQueries,
  });

  return (
    <>
      <Center>
        <Flex pb="3" width="100%" maxWidth={MAX_CARD_WIDTH} justify="space-between">
          <LinkButton to={SHARED_COMPANY_ROUTES.newHireJourneys.overview}>Onboarding Overview</LinkButton>
          <SmallArrowPagination table={table} isLoading={isLoading} pb="0" />
        </Flex>
      </Center>
      <Center>
        <BlueBorderCard p="4" width="100%" maxWidth={MAX_CARD_WIDTH}>
          <Flex width="100%" align="center" direction={{ base: "column", xl: "row" }}>
            <Flex height="100%" align="center" justify="center" gap="8">
              <SearchBar minWidth="300" maxWidth="300" name="tasksSearch" onInput={setSearchText} my="4" />
              <TableSortSelect table={table} sortOptions={SORT_OPTIONS} />
            </Flex>
            <Flex height="100%" ml="4" align="center" justify="space-evenly" gap="8">
              <CheckboxGroup onChange={(value: any) => setSelectedValues(value)}>
                <HStack gap="4">
                  <Checkbox value="noBuddyAssigned">No Buddy Assigned</Checkbox>
                  <Checkbox value="noOnboardingPlan">No Onboarding Plan</Checkbox>
                </HStack>
              </CheckboxGroup>
            </Flex>
          </Flex>
        </BlueBorderCard>
      </Center>
      <BaseCardTable
        cardComponent={NewHireJourneyCard}
        emptyStateComponent={<EmptyNewHireJourneyState />}
        skeletonHeight="200px"
        getTdProps={() => ({
          py: "6px",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "2",
        })}
        sx={{
          "& tbody tr:first-of-type td:first-of-type": {
            paddingTop: "20px",
          },
          mt: "2px",
          ...sx,
        }}
        includePagination={false}
        {...{
          table,
          getCardProps,
          isLoading,
        }}
        {...rest}
      />
    </>
  );
}

NewHireJourneyCardTable.defaultProps = {
  getCardProps: () => ({}),
  sx: undefined,
};
