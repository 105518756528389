import { ExtendedNewHireJourney } from "models";
import CaminoNewHireIcon from "components/Icon/CaminoNewHire";
import CaminoManagerIcon from "components/Icon/CaminoManager";
import CaminoBuddyIcon from "components/Icon/CaminoBuddy";
import CaminoPeopleTeamIcon from "components/Icon/CaminoPeopleTeam";
import { PiUsersThree } from "react-icons/pi";
import { Box, BoxProps } from "@chakra-ui/react";
import SelectField from "./SelectField";

interface SelectSupportTeamProps extends BoxProps {
  setFilterByUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
  userId?: string;
  newHireJourney: ExtendedNewHireJourney;
}

export default function SelectSupportTeam({
  newHireJourney,
  setFilterByUserId,
  userId = "all",
  ...rest
}: SelectSupportTeamProps) {
  const options = [
    {
      value: "all",
      label: "Everyone",
      icon: PiUsersThree,
    },
    {
      value: newHireJourney?.user?.id || "",
      label: `${newHireJourney?.user?.fullName} (New Hire)`,
      icon: CaminoNewHireIcon,
    },
    {
      value: newHireJourney?.manager?.id || "",
      label: `${newHireJourney?.manager?.fullName || "TBD"} (Manager)`,
      icon: CaminoManagerIcon,
    },
    {
      value: newHireJourney?.buddy?.id || "",
      label: `${newHireJourney?.buddy?.fullName || "TBD"} (Buddy)`,
      icon: CaminoBuddyIcon,
    },
    {
      value: newHireJourney?.peopleTeamContact?.id || "",
      label: `${newHireJourney?.peopleTeamContact?.fullName || "TBD"} (People Team)`,
      icon: CaminoPeopleTeamIcon,
    },
  ];

  const handleSelect = (value: string) => {
    setFilterByUserId(["", "all"].includes(value) ? undefined : value);
  };

  return (
    <Box width="250px" {...rest}>
      <SelectField
        options={options}
        value={userId}
        onSelect={handleSelect}
        placeholder="Select a stakeholder"
        label="Filter by User"
      />
    </Box>
  );
}
