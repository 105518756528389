import AppPage from "layouts/AppPage";
import { useEffect, useState } from "react";
import { NewIncomingWebhook } from "models/integration/incomingWebhook";
import { useNavbar } from "hooks/useNavbar";
import NewCredentialsCard from "./NewCredentialsCard";
import ConnectedIntegrations from "./ConnectedIntegrations";
import AdditionalIntegrations from "./AdditionalIntegrations";

export default function CompanyIntegrationsPage() {
  const [newWebhookCredentials, setNewWebhookCredentials] = useState<NewIncomingWebhook | null>(null);
  const { setPageTitle, setBreadcrumbs } = useNavbar();

  useEffect(() => {
    setPageTitle("Integrations");
    setBreadcrumbs([{ name: "Integrations", isCurrentPage: true }]);
  }, [setPageTitle, setBreadcrumbs]);

  return (
    <AppPage>
      {newWebhookCredentials && <NewCredentialsCard webhookCredentials={newWebhookCredentials} />}
      <ConnectedIntegrations mb="4" />
      <AdditionalIntegrations setNewWebhookCredentials={setNewWebhookCredentials} />
    </AppPage>
  );
}
