import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";

interface ExistsProps {
  labelYes: string;
  labelNo: string;
  condition: boolean;
}

export default function Exists({ labelYes, labelNo, condition }: ExistsProps) {
  return (
    <Flex align="center" gap="2">
      <Text fontSize="sm" fontWeight="medium" color="chakra-body-text">
        {condition ? labelYes : labelNo}
      </Text>
      {condition ? (
        <CheckIcon aria-label="Check Yes" color="green.500" />
      ) : (
        <CloseIcon aria-label="Check No" color="red.500" />
      )}
    </Flex>
  );
}
