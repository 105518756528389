export const SHARED_ROUTES = {
  home: "/",
  login: "/login",
  authenticate: "/authenticate",
  hangout: "/hangout",
  profile: "/profile",
  settings: "/settings",
  showUser: "/users/:id",
  integrationSuccess: "/integration-success",
  tasks: {
    index: "/tasks",
    show: "/tasks/:id",
  },
};

export const ADMIN_ROUTES = {
  hub: "/admin",
  companies: {
    index: "/admin/companies",
    show: "/admin/companies/:id",
    create: "/admin/companies/new",
    emailConfiguration: {
      index: "/admin/companies/:companyId/email-configuration",
    },
  },
  users: {
    index: "/admin/users",
  },
  blazer: "/admin/blazer",
  jobs: "/admin/jobs",
};

export const PEOPLE_TEAM_ROUTES = {
  assignedPaths: {
    show: "/people-team/assigned-paths/:id",
  },
  assignedActions: {
    show: "/people-team/scheduled-actions/:id",
  },
  activityFeed: {
    index: "/people-team/activity-feed",
  },
  paths: {
    index: "/people-team/paths",
    show: "/people-team/paths/:id",
  },
  actions: {
    show: "/people-team/actions/:id",
  },
  templatePaths: {
    // index: "/people-team/template-paths",
    show: "/people-team/template-paths/:id",
  },
  templateActions: {
    // index: "/people-team/template-workflow-actions",
    show: "/people-team/template-actions/:id",
  },
  dashboard: "/people-team",
  stats: "/people-team/stats",
  workforce: "/people-team/workforce",
  company: {
    settings: "/people-team/company/settings",
    info: "/people-team/company/settings/info",
    notifiers: "/people-team/company/settings/notifiers",
    individualSettings: "/people-team/company/settings/individual",
    emailSettings: "/people-team/company/settings/email",
    integrations: {
      index: "/people-team/company/integrations",
      show: "/people-team/company/integrations/:id",
      success: "/people-team/company/integration-success",
    },
  },
  issues: {
    base: "/people-team/issues",
    warning: "/people-team/issues/warning",
    error: "/people-team/issues/error",
    new: "/people-team/issues/new",
  },
};

// Those acting on behalf of the company, managers and people team members
export const SHARED_COMPANY_ROUTES = {
  newHireJourneys: {
    pending: {
      index: "/new-hires/pending",
      show: "/new-hires/:id/pending",
    },
    index: "/new-hires",
    show: "/new-hires/:id",
    tabs: {
      tasks: {
        base: "/new-hires/:id/tasks",
        show: "/new-hires/:id/tasks/:taskId",
      },
      schedule: {
        base: "/new-hires/:id/schedule",
      },
      fields: {
        base: "/new-hires/:id/fields",
      },
      paths: {
        base: "/new-hires/:id/paths",
      },
      activityFeed: {
        base: "/new-hires/:id/activity-feed",
      },
    },
    overview: "/new-hires/overview",
  },
  newHires: {
    showAll: "/new-hires/:id/*",
  },
};

export const API_ROUTES = {
  autocomplete: {
    locations: {
      index: "/autocompletes/locations",
      show: "/autocompletes/locations/:id",
    },
    timezones: {
      show: "/autocompletes/timezones/:id",
    },
  },
  actions: {
    base: "/actions",
    show: "/actions/:id",
    testSend: "/actions/:id/test-send",
    duplicate: "/actions/:id/duplicate",
  },
  admin: {
    users: {
      base: "/admin/users",
      show: "/admin/users/:id",
      impersonations: {
        base: "/admin/users/impersonations",
        show: "/admin/users/:userId/impersonations",
      },
      automations: {
        base: "/admin/users/:userId/automations",
        validate: "/admin/users/:userId/automations/validate",
      },
    },
    companies: {
      base: "/admin/companies",
      show: "/admin/companies/:id",
      importUsers: "/admin/companies/:id/import-users",
      emailConfiguration: {
        index: "/admin/companies/:companyId/email-configuration",
      },
    },
  },
  sessions: {
    magicLinks: {
      base: "/sessions/magic-links",
    },
    show: "/sessions/:id",
  },
  companyValues: {
    base: "/company-values",
    show: "/company-values/:id",
  },
  companies: {
    show: "/companies/:id",
  },
  departments: {
    base: "/departments",
  },
  integrations: {
    index: "/integrations",
    show: "/integrations/:id",
    available: {
      index: "integrations/available",
    },
  },
  issues: {
    base: "/issues",
    count: "/issues/count",
  },
  feedback: "/feedback",
  messageServices: {
    base: "/message-services",
    show: "/message-services/:id",
    connections: {
      base: "/message-services/connections",
      show: "/message-services/connections/:id",
    },
    messages: {
      base: "/message-services/messages",
    },
    channels: {
      base: "/message-services/channels",
      show: "/message-services/channels/:id",
      sync: "/message-services/channels/sync",
    },
    users: {
      sync: "/message-services/users/sync",
    },
  },
  newHireJourneys: {
    base: "/onboarding-journeys",
    show: "/onboarding-journeys/:id",
    search: "/onboarding-journeys/search",
    pending: {
      base: "/onboarding-journeys/pending",
      count: "/onboarding-journeys/pending/count",
    },
    combinedActions: {
      show: "/onboarding-journeys/combined-actions/:id",
    },
    paths: "/onboarding-journeys/:id/paths",
  },
  notifiers: {
    base: "/notifiers",
    show: "/notifiers/:id",
  },
  notifierTargets: {
    base: "/notifier-targets",
    show: "/notifier-targets/:id",
  },
  oauth: {
    slack: {
      individual: {
        base: "/oauth/slack/individual",
        url: "/oauth/slack/individual/url",
      },
    },
  },
  onboardingJourneyNetworkRelations: {
    base: "/onboarding-journey-network-relations",
    show: "/onboarding-journey-network-relations/:id",
  },
  paths: {
    base: "/paths",
    show: "/paths/:id",
    template: "/paths/template",
    defaults: "/paths/defaults",
    duplicate: "/paths/:id/duplicate",
  },
  assignedPaths: {
    base: "/assigned-paths",
    show: "/assigned-paths/:id",
    bulk: "/assigned-paths/bulk",
  },
  assignedActions: {
    base: "/assigned-actions",
    show: "/assigned-actions/:id",
    newIssues: {
      base: "/assigned-actions/:id/issues",
    },
    testSend: "/assigned-actions/:id/test-send",
    forceSend: "/assigned-actions/:id/force-send",
    pause: "/assigned-actions/:id/pause",
    count: "/assigned-actions/count",
    issues: {
      base: "/assigned-actions/issues",
      past: "/assigned-actions/issues/past",
      future: "/assigned-actions/issues/future",
    },
    batch: {
      base: "/assigned-actions/batch",
      forceSend: "/assigned-actions/batch/force-send",
    },
  },
  stages: {
    base: "/stages",
    show: "/stages/:id",
  },
  templatePaths: {
    base: "/template-paths",
    show: "/template-paths/:id",
  },
  templatePathActions: {
    base: "/template-path-actions",
    show: "/template-path-actions/:id",
  },
  templateVariables: {
    base: "/template-variables",
  },
  tasks: {
    base: "/tasks",
    show: "/tasks/:id",
    count: "/tasks/count",
    batch: {
      base: "/tasks/batch",
    },
  },
  users: {
    base: "/users",
    search: "/users/search",
    show: "/users/:id",
  },
};
