export const ACTION_TYPE_FIELD = {
  type: "SELECT",
  label: "Action Type",
  placeholder: "Action Type",
  name: "actionType",
  options: [
    { label: "Email", value: "email" },
    { label: "Chat Message", value: "chat" },
    { label: "Task Notification", value: "task_notifier" },
    { label: "Task Reminder", value: "task_reminder" },
  ],
};

export const NAME_FIELD = {
  type: "text",
  label: "Name",
  placeholder: "Action Name",
  name: "name",
};

// DateTime
export const TRIGGER_DATE_TIME_FIELD = {
  type: "date",
  label: "Date",
  name: "trigger.data.datetime",
};

export const TRIGGER_DATE_FIELD = {
  type: "date",
  label: "Date",
  name: "triggerDate",
};

export const TIMEZONE_FIELD = {
  type: "timezone",
  label: "Timezone",
  name: "timezone",
};

export const TIME_OF_DAY_FIELD = {
  type: "TIME",
  label: "Send At",
  placeholder: "Send At",
  name: "timeOfDay",
};
