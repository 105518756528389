import {
  Card,
  CardBody,
  Stack,
  Skeleton,
  Flex,
  CardProps,
  Spinner,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useToast,
} from "@chakra-ui/react";
import { BaseTask } from "models/task";
import { ANIMATION_SPEED, TASK_TYPES } from "definitions/constants";
import { MarkCompleteToggleButton, MarkSkippedToggleButton } from "layouts/Task/TaskCardDropDown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { Task } from "models";
import { MinimalTaskStatus } from "features/Task/TaskStatus";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { MessageContent } from "components/Task/TaskContent";
import EmbeddedHtml from "components/DataDisplay/EmbeddedHtml";
import TaskExampleImage from "components/ExampleImage/TaskExampleImage";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import IssuesUserForm from "features/Issues/Forms/IssuesUserForm";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import apiClient from "services/ApiClient";
import { SimpleInfo, SimpleText } from "components/DataDisplay/SimpleInfo";
import { titleize } from "helpers/string";
import EditableTaskTitleField from "./EditableTaskTitleField";
import { CustomizeMessagePromptButton } from "./CustomizeMessagePromptButton";
import TaskCommunications from "./TaskCommunication";
import { taskKeys, useToggleTaskCompletion, useUpdateTask } from "../hooks";

interface BaseTaskCardProps extends CardProps {
  task: Task | undefined;
  isLoading: boolean;
}

interface TaskCardProps extends BaseTaskCardProps {
  onUpdate: (partialTask: Partial<BaseTask>) => void;
}

interface NonCustomTaskCardProps extends TaskCardProps {
  onSuccess: (response: object) => void;
}

function taskType(task: Task) {
  return task.taskType === TASK_TYPES.CUSTOM ? "Simple Task" : titleize(task.taskType);
}

export function TaskHeader({ task, onUpdate }: Pick<TaskCardProps, "task" | "onUpdate">) {
  return (
    <Flex direction="column" gap="1" align-items="flex-start">
      <Flex gap="8" align="center" justify="start">
        <EditableTaskTitleField title={task?.title || ""} onUpdate={onUpdate} />
        <MinimalTaskStatus excludedStatuses={["will-notify", "notified", "errored"]} width="fit-content" task={task as Task} />
      </Flex>
      <Flex gap="8" align="center">
        {task?.taskType && <SimpleText>{taskType(task)}</SimpleText>}
        {task?.assignee && <SimpleInfo label="Assignee">{task?.assignee?.fullName}</SimpleInfo>}
        {task?.newHireJourney && <SimpleInfo label="New Hire">{task?.newHireJourney?.user?.fullName}</SimpleInfo>}
      </Flex>
      <TaskCommunications task={task} my="2" />
    </Flex>
  );
}

function TaskNotifierExampleDropdown({ task }: { task?: Task }) {
  return (
    <Accordion allowToggle width="100%">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Task Notification Example
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>{task && <TaskExampleImage taskType={task?.taskType} />}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

TaskNotifierExampleDropdown.defaultProps = {
  task: undefined,
};

function BaseTaskCard({ task, isLoading, onUpdate, children }: TaskCardProps) {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    async (innerTask: BaseTask) => {
      queryClient.setQueryData(taskKeys.detail(innerTask?.id), innerTask);
    },
    [queryClient],
  );

  return (
    <Card mt="4" maxW="1200px">
      <Skeleton isLoaded={!isLoading && !!task} speed={ANIMATION_SPEED}>
        <CardBody paddingX="12" pt="8" width="100%">
          {task ? (
            <Stack gap="4" width="100%">
              <Flex justify="space-between" align-items="center">
                <TaskHeader task={task} onUpdate={onUpdate} />
                <Flex gap="4">
                  <MarkSkippedToggleButton task={task} onSuccess={onSuccess} />
                  <MarkCompleteToggleButton task={task} onSuccess={onSuccess} />
                </Flex>
              </Flex>
              {children}
            </Stack>
          ) : null}
        </CardBody>
      </Skeleton>
    </Card>
  );
}

function CustomAssignedTaskCard({ task, isLoading, onUpdate }: TaskCardProps) {
  const [description, setDescription] = useState(task?.description || "");

  useEffect(() => {
    setDescription(task?.description || "");
  }, [task]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <BaseTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate}>
      <EmbeddedHtml innerHtml={description} />
    </BaseTaskCard>
  );
}

function CreateOnboardingPlanAssignedTaskCard({ task, isLoading, onUpdate, onSuccess }: NonCustomTaskCardProps) {
  return (
    <BaseTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate}>
      <>
        {task && (
          <ModalFormWrapper
            modalHeader="Add Onboarding Plan"
            button={(
              <Button width="fit-content" variant="camino-secondary">
                Add Onboarding Plan
              </Button>
            )}
          >
            <IssuesUserForm
              userId={task.newHireJourney.user.id}
              cardLink={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: task.newHireJourney?.id || "" })}
              title="New Hire"
              fields={["onboardingPlanUrl"]}
              onClose={() => null}
              onSuccess={onSuccess}
            />
          </ModalFormWrapper>
        )}
        <TaskNotifierExampleDropdown task={task} />
      </>
    </BaseTaskCard>
  );
}

function AssignBuddyAssignedTaskCard({ task, isLoading, onUpdate, onSuccess }: NonCustomTaskCardProps) {
  return (
    <BaseTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate}>
      <>
        {task && (
          <ModalFormWrapper
            modalHeader="Hello"
            button={(
              <Button width="fit-content" variant="camino-secondary">
                Add Buddy
              </Button>
            )}
          >
            <IssuesUserForm
              userId={task.newHireJourney.user.id}
              cardLink={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: task.newHireJourney?.id || "" })}
              title="New Hire"
              fields={["buddyId"]}
              onClose={() => null}
              onSuccess={onSuccess}
            />
          </ModalFormWrapper>
        )}
        <TaskNotifierExampleDropdown task={task} />
      </>
    </BaseTaskCard>
  );
}

function CreateEmailAccountAssignedTaskCard({ task, isLoading, onUpdate, onSuccess }: NonCustomTaskCardProps) {
  return (
    <BaseTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate}>
      <>
        {task && (
          <ModalFormWrapper
            modalHeader="Add Email Address"
            button={(
              <Button width="fit-content" variant="camino-secondary">
                Add Email Address
              </Button>
            )}
          >
            <IssuesUserForm
              userId={task.newHireJourney.user.id}
              cardLink={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: task.newHireJourney?.id || "" })}
              title="New Hire"
              fields={["workEmail"]}
              onClose={() => null}
              onSuccess={onSuccess}
            />
          </ModalFormWrapper>
        )}
        <TaskNotifierExampleDropdown task={task} />
      </>
    </BaseTaskCard>
  );
}

function SendMessagePromptAssignedTaskCard({ task, isLoading, onUpdate, onSuccess }: NonCustomTaskCardProps) {
  return (
    <BaseTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate}>
      {task && (
        <>
          <EmbeddedHtml innerHtml={task.description} />
          <CustomizeMessagePromptButton task={task} onSuccess={onSuccess} />
          <MessageContent
            taskRecipient={task?.assignee?.fullName || "the recipient"}
            messageBody={task?.messageBody}
            messageTarget={task?.messageTarget}
          />
          <TaskNotifierExampleDropdown task={task} />
        </>
      )}
    </BaseTaskCard>
  );
}

export default function AssignedTaskCard({ task, isLoading }: BaseTaskCardProps) {
  const toast = useToast({
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const { mutate } = useUpdateTask({ task });

  const { mutate: onUpdate } = useUpdateTask({
    task,
    onSuccess: () => {
      toast({
        title: "Task updated!",
      });
    },
  });

  const onSuccessMarkTaskCompleted = useCallback(
    () => {
      if (task?.status === "completed") {
        return null;
      }
      return mutate({ status: "completed" });
    },
    [mutate, task?.status],
  );

  switch (task?.taskType) {
    case TASK_TYPES.CUSTOM:
      return <CustomAssignedTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate} />;
    case TASK_TYPES.CREATE_ONBOARDING_PLAN:
      return (
        <CreateOnboardingPlanAssignedTaskCard
          task={task}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSuccess={onSuccessMarkTaskCompleted}
        />
      );
    case TASK_TYPES.ASSIGN_BUDDY:
      return (
        <AssignBuddyAssignedTaskCard
          task={task}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSuccess={onSuccessMarkTaskCompleted}
        />
      );
    case TASK_TYPES.CREATE_EMAIL_ACCOUNT:
      return (
        <CreateEmailAccountAssignedTaskCard
          task={task}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSuccess={onSuccessMarkTaskCompleted}
        />
      );
    case TASK_TYPES.SEND_MESSAGE_PROMPT:
      return (
        <SendMessagePromptAssignedTaskCard
          task={task}
          isLoading={isLoading}
          onUpdate={onUpdate}
          onSuccess={onSuccessMarkTaskCompleted}
        />
      );
    default:
      return <CustomAssignedTaskCard task={task} isLoading={isLoading} onUpdate={onUpdate} />;
  }
}
