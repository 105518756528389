import {
  Box, HStack,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { useCallback } from "react";
import { BaseAssignedAction } from "models/automation";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import TimePickerField from "components/FormElements/Fields/TimePickerField";
import { buildISODate, convertToLocalDate, convertToLocalTimeString } from "helpers/time";
import { SelectedTimezone } from "models/timezone";
import moment from "moment";
import { TIME_OF_DAY_FIELD, TIMEZONE_FIELD, TRIGGER_DATE_FIELD } from "./fields";
import { SharedSchema } from "./schema";

export const triggerValues = (action: BaseAssignedAction): Partial<SharedSchema> => ({
  trigger: action?.trigger,
  timezone: action?.timezone,
  triggerDate: action?.trigger?.type === "date_time" ? convertToLocalDate(action?.trigger?.data?.datetime, action?.timezone || "UTC") : undefined,
  timeOfDay: action?.trigger?.type === "date_time" ? convertToLocalTimeString(action?.trigger?.data?.datetime, action?.timezone || "UTC") : undefined,
});

interface TriggerDateTimeProps {
  assignedActionId: string;
  form: UseFormReturn<SharedSchema>;
}

export default function TriggerDateTime({ assignedActionId, form }: TriggerDateTimeProps) {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = form;

  const handleDateChange = useCallback(
    (newDate: Date | undefined) => {
      if (newDate) {
        const [time, timezone] = getValues(["timeOfDay", "timezone"]);
        const newIsoDate = buildISODate(newDate, time || "09:00", timezone || "UTC");
        setValue("trigger.data.datetime", new Date(newIsoDate), { shouldDirty: true });
        setValue("triggerDate", new Date(newDate), { shouldDirty: true });
      }
    },
    [getValues, setValue],
  );

  const handleTimeChange = useCallback(
    (newTime: string) => {
      if (newTime) {
        const [triggerDate, timezone] = getValues(["triggerDate", "timezone"]);
        const newIsoDate = buildISODate(triggerDate, newTime, timezone);
        setValue("trigger.data.datetime", new Date(newIsoDate), { shouldDirty: true });
        setValue("timeOfDay", newTime, { shouldDirty: true });
      }
    },
    [getValues, setValue],
  );

  const handleTimeZoneChange = useCallback(
    (newTimeZone: SelectedTimezone) => {
      if (newTimeZone) {
        const [trigger] = getValues(["trigger"]);
        const triggerDateTime = trigger?.data?.datetime;
        if (triggerDateTime) {
          const newLocalDate = convertToLocalDate(triggerDateTime, newTimeZone?.value || "UTC");
          const newLocalTime = convertToLocalTimeString(triggerDateTime, newTimeZone?.value || "UTC");
          setValue("triggerDate", newLocalDate, { shouldDirty: true });
          setValue("timeOfDay", newLocalTime, { shouldDirty: true });
          setValue("timezone", newTimeZone.value, { shouldDirty: true });
        } else {
          console.error(`Cannot find trigger for action ${assignedActionId}`);
        }
      }
    },
    [getValues, assignedActionId, setValue],
  );

  return (
    <>
      <Box width="350px">
        <DatePickerField
          {...TRIGGER_DATE_FIELD}
          minDate={moment().subtract(1, "days").toDate()}
          errors={errors}
          control={control}
          onDateChange={(date: Date | undefined) => {
            handleDateChange(date);
          }}
        />
      </Box>
      <HStack width="100%">
        <TimePickerField
          {...TIME_OF_DAY_FIELD}
          width="170px"
          formFieldProps={{ width: "auto" }}
          control={control}
          errors={errors}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleTimeChange(event.target.value);
          }}
        />
        <Box width="350px !important">
          <TimezoneSelectField
            {...TIMEZONE_FIELD}
            size="sm"
            control={control}
            errors={errors}
            formFieldProps={{ width: "100%" }}
            onChange={(newTimeZone: SelectedTimezone) => {
              handleTimeZoneChange(newTimeZone);
            }}
          />
        </Box>
      </HStack>
    </>
  );
}
