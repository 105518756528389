import React, { useState, useEffect } from "react";
import { chakraComponents, GroupBase, OptionBase, OptionProps, SelectComponentsConfig, SingleValue, SingleValueProps } from "chakra-react-select";
import { Flex, Icon } from "@chakra-ui/react";
import FormField from "components/FormElements/Fields/FormField";
import Select from ".";

interface Option extends OptionBase {
  value: string;
  label: string;
  icon?: React.ElementType;
}

interface CustomSelectProps {
  options: Option[];
  onSelect: (value: string) => void;
  value?: string;
  placeholder?: string;
  label?: string;
  variant?: string;
  isClearable?: boolean;
  formFieldProps?: object;
}

const customComponents: SelectComponentsConfig<Option, false, GroupBase<Option>> = {
  Option: ({ children, data, ...props }: OptionProps<Option, false, GroupBase<Option>>) => (
    <chakraComponents.Option data={data} {...props}>
      {data.icon && (
        <Icon
          as={data.icon}
          mr={2}
          h={5}
          w={5}
        />
      )}
      {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }: SingleValueProps<Option, false, GroupBase<Option>>) => (
    <chakraComponents.SingleValue {...props}>
      <Flex align="center">
        {props.data.icon && (
          <Icon
            as={props.data.icon}
            mr={2}
            h={5}
            w={5}
          />
        )}
        {children}
      </Flex>
    </chakraComponents.SingleValue>
  ),
};

export default function CustomSelect({
  options,
  label,
  onSelect = () => {},
  placeholder = "Select an option",
  variant = "flushedFloating",
  isClearable = false,
  formFieldProps = {},
  value: externalValue,
  ...props
}: CustomSelectProps) {
  // Internal state
  const [internalValue, setInternalValue] = useState<string | undefined>(externalValue);

  // Sync internal state with external value
  useEffect(() => {
    setInternalValue(externalValue);
  }, [externalValue]);

  // Handle change
  const handleChange = (selectedOption: SingleValue<Option>) => {
    const selectedValue = selectedOption?.value || "";
    setInternalValue(selectedValue); // Update internal state
    onSelect(selectedValue); // Notify parent
  };

  // Find the selected option
  const selectedOption = options.find((option) => option.value === internalValue) || null;

  return (
    <FormField
      label={label}
      isRequired={false}
      variant={variant}
      {...formFieldProps}
    >
      <Select
        options={options}
        isClearable={isClearable}
        value={selectedOption}
        onChange={handleChange}
        placeholder={["floating", "flushedFloating"].includes(variant) ? " " : placeholder}
        components={customComponents}
        {...props}
      />
    </FormField>
  );
}
