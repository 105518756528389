/* eslint-disable import/prefer-default-export */

import { API_ROUTES } from "definitions/constants/routeConstants";
import { AutoFormSection } from "models/autoForm";

export const PENDING_FORM_SECTIONS: AutoFormSection[] = [
  {
    title: "Profile",
    emoji: "😄",
    fields: [
      {
        name: "firstName",
        type: "string",
        label: "First Name",
        accessor: "user.firstName",
        writer: "user.firstName",
        required: true,
      },
      {
        name: "lastName",
        type: "string",
        label: "Last Name",
        accessor: "user.lastName",
        writer: "user.lastName",
        required: true,
      },
      {
        name: "preferredName",
        type: "string",
        label: "Preferred Name",
        accessor: "user.preferredName",
        writer: "user.preferredName",
      },
      {
        name: "pronouns",
        type: "string",
        label: "Pronouns",
        accessor: "user.pronouns",
        writer: "user.pronouns",
      },
    ],
  },
  {
    title: "Role",
    emoji: "📋",
    fields: [
      {
        name: "startDate",
        type: "date",
        label: "Start Date",
        accessor: "startDate",
        writer: "onboardingJourney.startDate",
        required: true,
      },
      {
        name: "title",
        type: "string",
        label: "Title",
        accessor: "user.title",
        writer: "user.title",
        required: true,
      },
      {
        name: "department",
        type: "createableSingleSelect",
        label: "Department",
        accessor: "user.department",
        writer: "user.department",
        optionsUrl: API_ROUTES.departments.base,
      },
      {
        name: "employmentType",
        type: "singleSelect",
        label: "Employment Type",
        accessor: "user.employmentType",
        writer: "user.employmentType",
        options: [
          { value: "fullTime", label: "Full Time" },
          { value: "partTime", label: "Part Time" },
          { value: "contractor", label: "Contractor" },
        ],
      },
    ],
  },
  {
    title: "Contact Information",
    emoji: "📥",
    fields: [
      {
        name: "personalEmail",
        type: "email",
        label: "Personal Email",
        accessor: "user.personalEmail",
        writer: "user.personalEmail",
        required: true,
      },
      {
        name: "workEmail",
        type: "email",
        label: "Work Email",
        accessor: "user.workEmail",
        writer: "user.workEmail",
      },
      {
        name: "linkedinUrl",
        type: "string",
        label: "LinkedIn Url",
        accessor: "user.linkedinUrl",
        writer: "user.linkedinUrl",
      },
      {
        name: "phoneNumber",
        type: "string",
        label: "Phone",
        accessor: "user.phoneNumber",
        writer: "user.phoneNumber",
      },
    ],
  },
  {
    title: "Location",
    emoji: "📍",
    fields: [
      {
        name: "workMode",
        type: "singleSelect",
        label: "Work Mode",
        accessor: "user.workMode",
        writer: "user.workMode",
        options: [
          { value: "remote", label: "Remote" },
          { value: "onsite", label: "Onsite" },
          { value: "hybrid", label: "Hybrid" },
        ],
      },
      {
        name: "location",
        type: "locationSelect",
        label: "Location",
        accessor: "user.location",
        writer: "user.location",
        required: true,
        hiddenFields: [
          { name: "country", accessor: "user.country", writer: "user.country" },
          { name: "googleLocationId", accessor: "user.googleLocationId", writer: "user.googleLocationId" },
          { name: "rawLocation", accessor: "user.rawLocation", writer: "user.rawLocation" },
        ],
        associatedFields: [
          { name: "timezone", accessor: "user.timezone", writer: "user.timezone" },
        ],
      },
      {
        name: "timezone",
        type: "timezoneSelect",
        label: "Timezone",
        accessor: "user.timezone",
        writer: "user.timezone",
        required: true,
      },
    ],
  },
  {
    title: "Support Team",
    emoji: "🌱",
    fields: [
      {
        name: "managerId",
        type: "userSelect",
        label: "Manager",
        accessor: "user.managerId",
        writer: "user.managerId",
        required: true,
      },
      {
        name: "peopleTeamContactId",
        type: "userSelect",
        label: "People Team Contact",
        accessor: "peopleTeamContactId",
        writer: "onboardingJourney.peopleTeamContactId",
        required: true,
      },
      {
        name: "buddyId",
        type: "userSelect",
        label: "Buddy",
        accessor: "buddyId",
        writer: "onboardingJourney.buddyId",
        helperText: "Typically assigned by manager. Can add now if known",
      },
    ],
  },
];
