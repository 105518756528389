import { useToast } from "@chakra-ui/react";
import { useUpdateTask } from "features/Task/hooks";
import { Task } from "models";

interface UseSubmitUpdateTask {
  task: Task;
  onSuccess?: () => void;
}

export default function useSubmitUpdateTaskForm({ task, onSuccess }: UseSubmitUpdateTask) {
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isPending: isLoading } = useUpdateTask({
    task,
    onSuccess: () => {
      toast({
        title: "Task updated!",
      });
      onSuccess?.();
    },
  });

  return { onSubmit, isLoading };
}
