const colors = {
  brand: {
    DEFAULT: "#282D6F",
    50: "#E3E4EC",
    100: "#BABCD2",
    200: "#8D90B3",
    300: "#636796",
    400: "#464A82",
    500: "#282D6F",
    600: "#232767",
    700: "#1C1F5D",
    800: "#141651",
    900: "#0A043C",
    opacity5: "rgba(10,4,60, 0.05)",
    opacity10: "rgba(10,4,60, 0.1)",
  },
  caminoGreen: {
    DEFAULT: "#83D97B",
    50: "#F0FAF0",
    100: "#DBF3DA",
    200: "#C3EDC2",
    300: "#A9E7A6",
    400: "#95E090",
    500: "#83D97B",
    600: "#78C970",
    700: "#6BB564",
    800: "#60A359",
    900: "#4C8246",
  },
  caminoOrange: {
    DEFAULT: "#F59511",
    50: "#FEF3E0",
    100: "#FCDFB3",
    200: "#FACA81",
    300: "#F8B550",
    400: "#F6A62C",
    500: "#F59511",
    600: "#F18B0F",
    700: "#EB7B0C",
    800: "#E56C0A",
    900: "#DB5508",
  },
  brandSecondary: {
    DEFAULT: "#6976D1",
    50: "#FFFFFF",
    100: "#EDEEF9",
    200: "#D1D4F1",
    300: "#B3B9E7",
    400: "#8491D9",
    500: "#6976D1",
    600: "#4F5DC8",
    700: "#424CB3",
    800: "#3A41A7",
    900: "#252586",
  },
  boldBlue: {
    DEFAULT: "#9ACDFF",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#F4F8FF",
    300: "#ECF5FF",
    400: "#C3E1FF",
    500: "#9ACDFF",
    600: "#62B1FF",
    700: "#2A95FF",
    800: "#007AF1",
    900: "#005DB9",
    950: "#004F9D",
  },
  ocean: {
    DEFAULT: "#C7E2D3",
    50: "#E2F0E8",
    100: "#E2F0E8",
    200: "#E2F0E8",
    300: "#FDFEFD",
    400: "#E2F0E8",
    500: "#C7E2D3",
    600: "#A2CFB6",
    700: "#7DBC99",
    800: "#58A97C",
    900: "#448461",
    950: "#3B7253",
  },
  coral: {
    DEFAULT: "#8af3cf",
    50: "#eefdf8",
    100: "#defcf1",
    200: "#cdfaea",
    300: "#bcf8e3",
    400: "#acf7dd",
    500: "#9bf5d6",
    600: "#8af3cf",
    700: "#79f1c8",
    800: "#69f0c1",
    900: "#58eeba",
    950: "#4fd6a7",
    1000: "#7cdaba",
    1050: "#46be95",
  },
  mellowYellow: {
    DEFAULT: "#F6EFA2",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FDFCEC",
    400: "#FAF5C7",
    500: "#F6EFA2",
    600: "#F1E66F",
    700: "#ECDD3C",
    800: "#DBCA15",
    900: "#A79B10",
    950: "#8E830E",
  },
  neutral: {
    DEFAULT: "#FBE8C2",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FEF6E8",
    500: "#FBE8C2",
    600: "#F8D48D",
    700: "#F4C059",
    800: "#F1AC24",
    900: "#CF8F0E",
    950: "#B57C0C",
  },
  sortaOrange: {
    DEFAULT: "#EAB840",
    50: "#FCF5E5",
    100: "#FAEFD3",
    200: "#F6E1AE",
    300: "#F2D38A",
    400: "#EEC665",
    500: "#EAB840",
    600: "#DAA118",
    700: "#A77C12",
    800: "#75560D",
    900: "#423107",
    950: "#291E05",
  },
  rose: {
    DEFAULT: "#EABDB8",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FDF8F7",
    400: "#F3DAD7",
    500: "#EABDB8",
    600: "#DD958D",
    700: "#D06D61",
    800: "#C14739",
    900: "#95372C",
    950: "#802F26",
  },
  notBlack: {
    DEFAULT: "#393939",
    50: "#959393",
    100: "#8B8B8B",
    200: "#767676",
    300: "#626262",
    400: "#4D4D4D",
    500: "#393939",
    600: "#1D1D1D",
    700: "#010101",
    800: "#000000",
    900: "#000000",
    950: "#000000",
  },
  caminoNewErrorRed: {
    DEFAULT: "#E92E4D",
    50: "#FAD4DA",
    100: "#F9C2CB",
    200: "#F59DAB",
    300: "#F1788C",
    400: "#ED536C",
    500: "#E92E4D",
    600: "#CA1533",
    700: "#971026",
    800: "#640B19",
    900: "#31050D",
    950: "#180306",
    opacity5: "#E92E4D0D",
    opacity10: "#E92E4D1A",
    opacity50: "#E92E4D80",
  },
  linkBlue: {
    DEFAULT: "#4097EB",
    500: "#4097EB",
    opacity5: "rgba(64, 151, 235, 0.05)",
    opacity10: "rgba(64, 151, 235, 0.1)",
  },
  caminoPurple: {
    DEFAULT: "rgba(52, 32, 220, 1)",
    500: "rgba(52, 32, 220, 1)",
    opacity5: "rgba(52, 32, 220, .05)",
    opacity10: "rgba(52, 32, 220, .1)",
  },
  caminoLilac: {
    DEFAULT: "rgba(152, 162, 219, 1)",
    500: "rgba(152, 162, 219, 1)",
    opacity5: "rgba(152, 162, 219, .05)",
    opacity10: "rgba(152, 162, 219, .1)",
  },
  caminoYellow: {
    DEFAULT: "rgba(234, 184, 64, 1)",
    500: "rgba(234, 184, 64, 1)",
    opacity5: "rgba(234, 184, 64, .05)",
    opacity10: "rgba(234, 184, 64, .1)",
  },
  skippedGrey: {
    DEFAULT: "#C3C0C0",
    500: "#C3C0C0",
  },
  resolveGrey: {
    DEFAULT: "#F4F4F9",
    500: "#F4F4F9",
    HOVER: "#E0E0E9",
  },
  sentGreen: {
    DEFAULT: "#27C26F",
    500: "#27C26F",
  },
  helperText: {
    DEFAULT: "#959393",
    500: "#959393",
  },
  valueBlack: {
    DEFAULT: "#535353",
    500: "#535353",
  },
  searchBarGrey: {
    DEFAULT: "#C1BFBF",
    500: "#C1BFBF",
  },
  tagGrey: {
    DEFAULT: "#F9F9F9",
    500: "#F9F9F9",
  },
  tableUnderlineBorder: {
    DEFAULT: "#D9D9D9",
    500: "#D9D9D9",
  },
  tableBorder: {
    DEFAULT: "#E0E0E0",
    500: "#E0E0E0",
  },
  hasValueBorder: {
    DEFAULT: "#BBDDFE",
    500: "#BBDDFE",
  },
  multiSelectBlue: {
    DEFAULT: "rgba(89, 197, 238, 0.1)",
    500: "rgba(89, 197, 238, 0.1)",
  },
  successGreen: {
    DEFAULT: "#77D1A1",
    500: "#77D1A1",
  },
  error: {
    DEFAULT: "#EC7464",
    500: "#EC7464",
    opacity5: "rgba(236, 116, 100, 0.05)",
    opacity10: "rgba(236, 116, 100, 0.1)",
  },
  statusRed: {
    bg: "#FFECEA",
    text: "#EB5D4B",
  },
  creamBg: {
    DEFAULT: "#FDFDFD",
    500: "#FDFDFD",
  },
  dateYellow: {
    DEFAULT: "rgba(234, 184, 64)",
    500: "rgba(234, 184, 64)",
    background: "rgba(234, 184, 64, 0.05)",
  },
  dateBlue: {
    DEFAULT: "rgba(64, 151, 235, 1)",
    500: "rgba(64, 151, 235, 1)",
    background: "rgba(64, 151, 235, 0.05)",
  },
  dateGreen: {
    DEFAULT: "rgba(119, 209, 161, 1)",
    500: "rgba(119, 209, 161, 1)",
    background: "rgba(119, 209, 161, 0.05)",
  },
  border: {
    default: "gray.200",
  },
} as const;

export type Colors = typeof colors;
export default colors;
