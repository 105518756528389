import { useToast } from "@chakra-ui/react";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { combinedActionKeys } from "features/CombinedAction/hooks";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { fromActionId, toActionId } from "helpers/newHireJourney";
import { apiAssignedActionBatchPathByIds, apiAssignedActionPathById } from "helpers/url";
import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { NewHireJourneyWithAssignedActions } from "models";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { taskKeys } from "features/Task/hooks";
import { assignedPathKeys } from "features/AssignedPath/hooks";
import { NewHireJourney } from "models/newHire";
import useCaminoStore from "hooks/useCaminoStore";

export function selectAssignedActionIds(ids: string[]): string[] {
  return ids.reduce((acc, currentId) => {
    const { actionType, id } = fromActionId(currentId);
    if (actionType !== "task" || !actionType) {
      acc.push(id);
    }
    return acc;
  }, [] as string[]);
}

export const assignedActionKeys = {
  all: ["assignedActions"] as const,
  lists: () => [...assignedActionKeys.all, "list"] as const,
  list: (filters: string) => [...assignedActionKeys.lists(), { filters }] as const,
  details: () => [...assignedActionKeys.all, "detail"] as const,
  detail: (id: string) => [...assignedActionKeys.details(), id] as const,
  batch: (ids: string[]) => [...assignedActionKeys.all, "batch", ids.sort().toString] as const,
  newHireJourneys: () => [...assignedActionKeys.lists(), "newHireJourney"] as const,
  newHireJourney: (newHireJourneyId: string, filters: string = "") => [
    ...assignedActionKeys.newHireJourneys(),
    newHireJourneyId,
    ...(filters ? [filters] : []),
  ] as const,
};

export const useAssignedActionDetailQuery = (id: string, options = {}) => useQuery<AssignedAction>({
  queryKey: assignedActionKeys.detail(id),
  queryFn: async () => apiClient.get(apiAssignedActionPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useAssignedActionListQuery = (queryString?: string) => {
  const query = queryString ? `?${queryString}` : "";
  return useQuery({
    queryKey: queryString ? assignedActionKeys.list(queryString) : assignedActionKeys.lists(),
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${query}`),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });
};

export const useAssignedActionByNewHireListQuery = (newHireJourney: NewHireJourney, queryString?: string) => {
  const query = queryString ? `?${queryString}` : "";
  return useQuery<BaseAssignedAction[]>({
    queryKey: assignedActionKeys.newHireJourney(newHireJourney.id, queryString),
    enabled: !!newHireJourney,
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${query}`),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });
};

const useAssignedActionBatchArguments = (combinedIds: string[]) => {
  const queryClient = useQueryClient();
  const assignedActionIds = selectAssignedActionIds(combinedIds);
  return {
    queryKey: assignedActionKeys.batch(assignedActionIds),
    queryFn: async () => {
      const assignedActions = await apiClient.get(apiAssignedActionBatchPathByIds(assignedActionIds));
      assignedActions.forEach((assignedAction: AssignedAction) => {
        queryClient.setQueryData(combinedActionKeys.detail(toActionId(assignedAction)), assignedAction);
      });
      return assignedActions;
    },
    staleTime: 1000 * 60 * 3, // 3 minutes
  };
};

export const useAssignedActionBatchQuery = (combinedIds: CombinedActionId[], options = {}) => useQuery({
  ...useAssignedActionBatchArguments(combinedIds),
  ...options,
});

export const usePreloadAssignedActionBatchQuery = (combinedIds: CombinedActionId[], options = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...useAssignedActionBatchArguments(combinedIds), ...options });
};

interface AssignedActionUpdateProps {
  action: BaseAssignedAction | undefined;
  onSuccess?: (response: any) => void;
  onError?: (error: unknown) => void;
}

export function useUpdateAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: (data: Partial<AssignedAction>) => apiClient.put<AssignedAction>(apiAssignedActionPathById(action?.id || ""), data),
    onSuccess: (res) => {
      onSuccess?.(res);
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.lists() });
      queryClient.setQueryData(assignedActionKeys.detail(res.id), (oldData: AssignedAction | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
      queryClient.setQueryData(newHireJourneyKeys.detail(action?.newHireJourneyId || ""), (oldData: NewHireJourneyWithAssignedActions | undefined) => {
        if (!oldData) {
          return null;
        }
        return {
          ...oldData,
          assignedActions: (oldData.assignedActions || []).map((assignedAction: AssignedAction) => (assignedAction.id === res.id ? res : assignedAction)),
        };
      });
      queryClient.setQueryData(combinedActionKeys.detail(toActionId(action)), (oldData: CombinedAction | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      onError?.(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
  return { isReady, ...mutation };
}

function invalidateAssignedActionQueries(queryClient: QueryClient, action: BaseAssignedAction | undefined) {
  queryClient.invalidateQueries({ queryKey: assignedActionKeys.lists() });
  queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action?.id || "") });
  queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(action?.newHireJourneyId || "") });
  queryClient.invalidateQueries({ queryKey: assignedPathKeys.detail(action?.scheduledWorkflowId || "") });
  if (action?.actionType === "task_notifier") {
    queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toActionId(action.task) || "") });
    queryClient.invalidateQueries({ queryKey: taskKeys.detail(action?.task?.id || "") });
  } else {
    queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toActionId(action) || "") });
  }
}

export function useDeleteAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.delete<AssignedAction>(apiAssignedActionPathById(action?.id || "")),
    onSuccess: (res) => {
      onSuccess?.(res);
      invalidateAssignedActionQueries(queryClient, action);
      toast({
        title: "Action removed!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Remove Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}

export function useForceSendAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.post<{ success: boolean }>(
      generatePath(API_ROUTES.assignedActions.forceSend, { id: action?.id || "" }),
      {},
    ),
    onSuccess: (res) => {
      invalidateAssignedActionQueries(queryClient, action);
      onSuccess?.(res);
      toast({
        title: "Action sent!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Send Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}

export function useSkipAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.post<{ success: boolean }>(
      generatePath(API_ROUTES.assignedActions.pause, { id: action?.id || "" }),
      {},
    ),
    onSuccess: (res) => {
      invalidateAssignedActionQueries(queryClient, action);
      onSuccess?.(res);
      toast({
        title: "Success!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Skip Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}

export function useTestSendAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const isReady = !!action;
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.post<{ success: boolean }>(
      generatePath(API_ROUTES.assignedActions.testSend, { id: action?.id || "" }),
      {},
    ),
    onSuccess: (res) => {
      onSuccess?.(res);
      toast({
        title: `A test was sent to ${user?.fullName}`,
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Skip Error", mutateError);
      onError?.(mutateError);
      toast({ title: `There was an error trying to send to ${user?.fullName}`, status: "error" });
    },
  });
  return { isReady, ...mutation };
}
