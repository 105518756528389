import { Button } from "@chakra-ui/react";
import { useSyncUsers } from "features/Integrations/hooks";

interface SyncUsersButtonProps {
  onSuccess?: () => void;
}

export default function SyncUsersButton({ onSuccess = () => { } }: SyncUsersButtonProps) {
  const {
    performRequest: syncChannels,
    state: { loading: isLoading },
  } = useSyncUsers();
  return (
    <Button onClick={() => syncChannels()} isLoading={isLoading} loadingText="Syncing Users">
      Sync Users
    </Button>
  );
}
