import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { combinedActionKeys } from "features/CombinedAction/hooks";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { fromActionId, toActionId } from "helpers/newHireJourney";
import { apiAssignedActionPathById, apiStagePathById } from "helpers/url";
import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { NewHireJourneyWithAssignedActions } from "models";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { CombinedAction } from "models/automation/combinedAction";

export function selectAssignedActionIds(ids: string[]): string[] {
  return ids.reduce((acc, currentId) => {
    const { actionType, id } = fromActionId(currentId);
    if (actionType !== "task" || !actionType) {
      acc.push(id);
    }
    return acc;
  }, [] as string[]);
}

const stagesKeys = {
  all: ["stages"] as const,
  lists: () => [...stagesKeys.all, "list"] as const,
  list: (filters: string) => [...stagesKeys.lists(), { filters }] as const,
  details: () => [...stagesKeys.all, "detail"] as const,
  detail: (id: string) => [...stagesKeys.details(), id] as const,
};

export const useStageDetailQuery = (id: string, options = {}) => useQuery<AssignedAction>({
  queryKey: stagesKeys.detail(id),
  queryFn: async () => apiClient.get(apiStagePathById(id)),
  staleTime: Infinity,
  ...options,
});

const stageQueryArguments = (queryString?: string) => {
  const query = queryString ? `?${queryString}` : "";
  return {
    queryKey: queryString ? stagesKeys.list(queryString) : stagesKeys.lists(),
    queryFn: async () => apiClient.get(`${API_ROUTES.stages.base}${query}`),
    staleTime: Infinity,
    placeholderData: [],
  };
};

export const useStageListQuery = (queryString?: string, options = {}) => useQuery({
  ...stageQueryArguments(queryString),
  ...options,
});

// This does not preload unless you use <Suspense> react components
// https://tanstack.com/query/latest/docs/framework/react/guides/prefetching#prefetch-in-components
export const usePreloadStageListQuery = (queryString: string, options = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...stageQueryArguments(queryString), ...options });
};

interface AssignedActionUpdateProps {
  action: BaseAssignedAction | undefined;
  onSuccess?: (response: any) => void;
  onError?: (error: unknown) => void;
}

export function useUpdateAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: (data: Partial<AssignedAction>) => apiClient.put<AssignedAction>(apiAssignedActionPathById(action?.id || ""), data),
    onSuccess: (res) => {
      onSuccess?.(res);
      queryClient.setQueryData(assignedActionKeys.detail(res.id), (oldData: AssignedAction | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
      queryClient.setQueryData(newHireJourneyKeys.detail(action?.newHireJourneyId || ""), (oldData: NewHireJourneyWithAssignedActions | undefined) => {
        if (!oldData) {
          return null;
        }
        return {
          ...oldData,
          assignedActions: (oldData.assignedActions || []).map((assignedAction: AssignedAction) => (assignedAction.id === res.id ? res : assignedAction)),
        };
      });
      queryClient.setQueryData(combinedActionKeys.detail(toActionId(action)), (oldData: CombinedAction | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      onError?.(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
  return { isReady, ...mutation };
}

export function useDeleteAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.delete<AssignedAction>(apiAssignedActionPathById(action?.id || "")),
    onSuccess: (res) => {
      onSuccess?.(res);
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action?.id || "") });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(action?.newHireJourneyId || "") });
      queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toActionId(action) || "") });
      toast({
        title: "Action removed!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Remove Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}

export function useForceSendAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.post<{ success: boolean }>(
      generatePath(API_ROUTES.assignedActions.forceSend, { id: action?.id || "" }),
      {},
    ),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action?.id || "") });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(action?.newHireJourneyId || "") });
      queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toActionId(action) || "") });
      onSuccess?.(res);
      toast({
        title: "Action sent!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Send Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}

export function useSkipAssignedAction({ action, onSuccess, onError }: AssignedActionUpdateProps) {
  const isReady = !!action;
  const queryClient = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: () => apiClient.post<{ success: boolean }>(
      generatePath(API_ROUTES.assignedActions.pause, { id: action?.id || "" }),
      {},
    ),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.detail(action?.id || "") });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(action?.newHireJourneyId || "") });
      queryClient.invalidateQueries({ queryKey: combinedActionKeys.detail(toActionId(action) || "") });
      onSuccess?.(res);
      toast({
        title: "Success!",
      });
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Skip Error", mutateError);
      onError?.(mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return { isReady, ...mutation };
}
