import { useQuery } from "@tanstack/react-query";
import { apiPendingNewHireJourneysPath } from "helpers/url";
import apiClient from "services/ApiClient";

export const pendingNewHireKeys = {
  all: ["pendingNewHires"] as const,
  lists: () => [...pendingNewHireKeys.all, "list"] as const,
  list: (filters: string) => [...pendingNewHireKeys.lists(), { filters }] as const,
  count: () => [...pendingNewHireKeys.all, "count"] as const,
};

export const usePendingNewHireListQuery = (options = {}) => useQuery({
  queryKey: pendingNewHireKeys.lists(),
  queryFn: async () => apiClient.get(apiPendingNewHireJourneysPath()),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});
