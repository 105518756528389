import { CreateToastFnReturn, useToast } from "@chakra-ui/react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import {
  EmailType, Action, PathActionable, PathRelationTarget, PathTrigger,
} from "models/automation";
import { PartialPathTrigger, fromPathTrigger, toPathTrigger } from "helpers/workflowActionableConverters";
import { useState } from "react";
import { LegacyTargetType, TargetType } from "models/record_target";
import { pathKeys } from "features/Path/hooks";
import Form from "./Form";
import { Schema } from "./schema";
import { actionKeys } from "features/Action/hooks";

const values = (actionable: PathActionable | undefined) => {
  if (!actionable) return {};

  const { content } = actionable;

  return {
    ...actionable,
    content: {
      ...content,
      body: content.bodyTrix ?? content.body,
      description: content.descriptionTrix ?? content.description,
      messageBody: content?.messageBody?.bodyTrix ?? content?.messageBody?.body,
      messageTarget: content.messageTarget,
    },
    dueDateTrigger: actionable.dueDateTrigger && fromPathTrigger(actionable.dueDateTrigger),
    trigger: fromPathTrigger(actionable.trigger),
    reminders: (actionable as any).reminders?.map((reminder: { message?: string; trigger: PathTrigger }) => ({
      ...reminder,
      trigger: fromPathTrigger(reminder.trigger),
    })),
  };
};

type FormData = {
  workflowAction: {
    workflowId: string;
    actionable: {
      trigger: PartialPathTrigger | undefined;
      dueDateTrigger: PartialPathTrigger | undefined;
      reminders: { trigger: PartialPathTrigger | undefined; message?: string | undefined }[] | undefined;
      content: {
        body?: string | undefined;
        title?: string | undefined;
        description?: string | undefined;
        subject?: string | undefined;
        emailType?: EmailType | undefined;
        messageBody?: string | undefined;
        messageTarget?: {
          targetType: TargetType | undefined;
          targetId: string | undefined;
        };
      };
      name: string;
      actionType: string;
      relationTarget: PathRelationTarget | undefined | null;
      targetType: LegacyTargetType;
      specificTargetableId: string | undefined | null;
      specificTargetableType: string | undefined | null;
    };
  };
};

function PathActionForm({
  onSuccess,
  workflowId,
  workflowActionId = undefined,
  performRequest,
  ...formProps
}: {
  performRequest: (data: FormData) => Promise<Action>;
  onSuccess: (res: Action, queryClient: QueryClient, toast: CreateToastFnReturn) => void;
  workflowId: string;
  workflowActionId?: string;
  onClose: () => void;
  isUpdate: boolean;
  proceedText: string;
  defaultValues: Partial<Schema>;
}) {
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Schema) => {
    const { trigger, reminders, content, ...rest } = data;
    const formData: FormData = {
      workflowAction: {
        workflowId,
        actionable: {
          ...rest,
          relationTarget: rest.targetType === "relation" ? rest.relationTarget : null,
          specificTargetableId: ["specific_user", "message_service_channel"].includes(rest.targetType)
            ? rest.specificTargetableId
            : null,
          content: { title: rest.actionType === "task" ? (content.title ?? rest.name) : undefined, ...content },
          trigger: trigger && toPathTrigger(trigger),
          dueDateTrigger: rest.actionType === "task" && data.dueDateTrigger?.data ? toPathTrigger(data.dueDateTrigger) : undefined,
          reminders: reminders?.map((reminder) => ({ ...reminder, trigger: reminder.trigger && toPathTrigger(reminder.trigger) })),
        },
      },
    };

    try {
      setIsLoading(true);
      const res = await performRequest(formData);
      setIsLoading(false);
      onSuccess(res, queryClient, toast);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({ title: "Error", description: "There was an error, please try again.", status: "error" });
    }
  };

  return (
    <Form
      {...formProps}
      onSubmit={onSubmit}
      workflowId={workflowId}
      workflowActionId={workflowActionId}
      isLoading={isLoading}
    />
  );
}

export default function ActionUpdateForm({ action, onClose = undefined }: { action: Action; onClose?: () => void }) {
  return (
    <PathActionForm
      workflowId={action.workflowId}
      workflowActionId={action.id}
      performRequest={(data) => apiClient.put<Action>(
        generatePath(API_ROUTES.actions.show, {
          id: action.id,
        }),
        data,
      )}
      onSuccess={(res, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: pathKeys.detail(res.workflowId) });
        queryClient.invalidateQueries({ queryKey: actionKeys.detail(res.id) });
        toast({ title: "Action updated.", status: "success" });
        onClose?.();
      }}
      defaultValues={values(action?.actionable)}
      isUpdate
      onClose={() => onClose?.()}
      proceedText="Update Action"
    />
  );
}
