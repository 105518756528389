import { useController } from "react-hook-form";
import clsx from "clsx";
import Select from "components/Select";
import { ControlledSelectProps, OptionType } from ".";

export default function ControlledSingleSelect({
  control,
  name,
  id,
  rules,
  options,
  className,
  ...props
}: ControlledSelectProps) {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name, control, rules });
  return (
    <Select
      options={options}
      name={name}
      ref={ref}
      menuPortalTarget={document.body}
      onChange={(val) => onChange(val?.value)}
      onBlur={onBlur}
      classNamePrefix="chakra-react-select"
      className={clsx("chakra-react-select-container-single-select", className)}
      value={options?.find((option) => (option as OptionType).value === value) || null}
      colorScheme="brand"
      {...props}
    />
  );
}

ControlledSingleSelect.defaultProps = {
  isClearable: true,
  useBasicStyles: true,
};
