import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import apiClient from "services/ApiClient";
import { NewHireJourney } from "models/newHire";
import { apiNewHireJourneyPathById, newHireJourneysPath } from "helpers/url";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { pendingNewHireKeys } from "../hooks";

interface UseKickOffOnboardingProps {
  newHireJourneyId: string;
  onClose: () => void;
}

export default function useKickOffOnboarding({ newHireJourneyId, onClose }: UseKickOffOnboardingProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate: onSubmit, isPending: isSubmitting } = useMutation({
    mutationFn: (_data: object) => apiClient.put<NewHireJourney>(apiNewHireJourneyPathById(newHireJourneyId), {
      onboardingJourney: {
        pending: false,
        workflowsStatus: "enabled",
      },
    }),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.lists() });
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.details() });
      queryClient.invalidateQueries({ queryKey: pendingNewHireKeys.lists() });
      queryClient.invalidateQueries({ queryKey: pendingNewHireKeys.count() });
      toast({
        title: `🎉 Success!`,
      });
      onClose();
    },
    onError: (mutateError) => {
      console.log("Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
      onClose();
    },
  });

  const handleSubmit = useCallback(() => {
    onSubmit(
      {},
      {
        onSuccess: () => navigate(newHireJourneysPath()),
      },
    );
  }, [onSubmit, navigate]);

  return {
    handleSubmit,
    isSubmitting,
  };
}
