import {
  Box, Link, Tag, Text, Tooltip,
} from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { PEOPLE_TEAM_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { timeAtSpecificDayMonth } from "helpers/time";
import { BaseAssignedAction } from "models/automation";
import { automationTriggerText } from "helpers/workflowActionableConverters";
import { scheduledActionStatusConverter, scheduledActionToStatusColor } from "helpers/scheduledWorkflowConverters";
import { scheduledTriggerSort } from "helpers/trigger";
import ScheduledActionRowButton from "components/ScheduledActionRowButton";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { EMOJI_ICONS } from "definitions/constants/icons";
import ControlledDataTable, { ControlledDataTableProps } from "./ControlledDataTable";
import ReceiverLinkOrTBD, { DisplayTypeAndTarget } from "./CellDisplayHelpers";

const columnHelper = createColumnHelper<BaseAssignedAction | AssignedAction>();

function TriggerComponent({
  action,
}: {
  action: AssignedAction;
}) {
  const { trigger } = action;

  switch (trigger.type) {
    case "date_time":
      return (
        <Box>
          <Text>{`${timeAtSpecificDayMonth(trigger.data.datetime)} `}</Text>
        </Box>
      );
    case "dependency":
      return <Text>Task Completion</Text>;
    default:
      return null;
  }
}

const makeColumns = () => {
  const columnDefs = [
    columnHelper.accessor((row) => row, {
      id: "actionType",
      header: "Type",
      cell: (row) => (
        <DisplayTypeAndTarget
          actionType={row.getValue()?.actionType}
          taskType={row.getValue()?.task?.taskType}
          relationTarget={row.getValue()?.workflowAction.relationTarget}
        />
      ),
      maxSize: 20,
      minSize: 20,
      size: 20,
      meta: {
        sx: {
          paddingLeft: 6,
          paddingRight: 4,
        },
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "target",
      header: "Recipient/Assignee",
      cell: (row) => {
        const rowValue = row.getValue();
        const { targetable } = rowValue;
        return <ReceiverLinkOrTBD receiver={targetable} />;
      },
      minSize: 139,
      maxSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "newHireJourney",
      header: "New Hire",
      cell: (row) => (
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
              id: row.getValue()?.scheduledWorkflow?.onboardingJourneyId || "",
            }),
          }}
        >
          {(row.getValue() as AssignedAction)?.onboardingJourney?.user?.fullName}
        </Link>
      ),
      minSize: 139,
      maxSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "taskName",
      header: "Name",
      cell: (row) => (
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, {
              id: row.getValue()?.id,
            }),
          }}
        >
          {row.getValue().workflowAction.actionable.name}
        </Link>
      ),
      size: 60,
      minSize: 60,
      maxSize: 60,
    }),
    columnHelper.accessor((row) => row, {
      id: "referenceTrigger",
      cell: (row) => (row.getValue()?.trigger.data.customized
        ? `${EMOJI_ICONS.triggerAt} Manually Set`
        : `${EMOJI_ICONS.triggerAt} ${automationTriggerText({
          ...row.getValue()?.workflowAction?.actionable.trigger,
        })}`),
      minSize: 40,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "trigger",
      header: "Scheduled",
      cell: (row) => <TriggerComponent action={row.getValue()} />,
      minSize: 40,
      sortingFn: (a, b) => scheduledTriggerSort(a.original.trigger, b.original.trigger),
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      header: "Status",
      cell: (row) => (
        <Tooltip label={(row.getValue().issues || []).map((i) => i.message).join(", ")}>
          <Tag
            textAlign="center"
            paddingY="4px"
            size="md"
            variant="solid"
            colorScheme={scheduledActionToStatusColor(row.getValue())}
          >
            {scheduledActionStatusConverter(row.getValue().status)}
          </Tag>
        </Tooltip>
      ),
      maxSize: 139,
      minSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "moreOptions",
      header: "",
      cell: (row) => (
        <ScheduledActionRowButton
          action={row.getValue() as AssignedAction}
        />
      ),
    }),
  ];
  return columnDefs;
};

interface AssignedActionsTableProps {
  data: BaseAssignedAction[];
  tableDefaultSort?: { id: string; desc: boolean };
  emptyStateComponent?: React.ReactNode;
  isLoaded?: boolean;
}

export default function AssignedActionsTable({
  data,
  isLoaded = true,
  tableDefaultSort = { id: "trigger", desc: false },
  emptyStateComponent = null,
}: AssignedActionsTableProps) {
  const columns = makeColumns();

  const tableProps: ControlledDataTableProps<BaseAssignedAction | AssignedAction> = {
    data,
    columns,
    initialPagination: { pageSize: 80, pageIndex: 0 },
    defaultSort: [tableDefaultSort],
    textWrap: true,
    emptyStateComponent: isLoaded ? emptyStateComponent : null,
    isLoading: !isLoaded,
  };

  return (
    <ControlledDataTable {...tableProps} />
  );
}
