import { PathRelationTarget } from "models/automation";

// V1 will not support fields for adding custom resources to an Employee's Onboarding Plan
export const FEATURE_FLAG_ADD_CUSTOM_RESOURCES = false;

export const DUPLICATE_ONBOARDING_PLAN_FEATURE_FLAG = false;

export const ANIMATION_SPEED = 1.75;

export const PAGE_SIZE_QUERY_PARAM = "ps";
export const PAGE_INDEX_QUERY_PARAM = "pi";

export const DEFAULT_DATE_FORMAT = "PPPP";
export const DATE_PICKER_FORMAT_MOMENT = "yyyy-MM-DD";
export const DATE_PICKER_FORMAT = "yyyy-MM-dd";

export const MAX_CARD_WIDTH = "1000px";

export const QUERY_KEYS = {
  assignedActions: "assignedActions",
  templatePath: "templatePath",
  templatePaths: "templatePaths",
  templatePathAction: "templatePathAction",
  templateVariables: "templateVariables",
};

export const TIME_OPTIONS = [
  { label: "0:00", value: "0:00" },
  { label: "1:00", value: "1:00" },
  { label: "2:00", value: "2:00" },
  { label: "3:00", value: "3:00" },
  { label: "4:00", value: "4:00" },
  { label: "5:00", value: "5:00" },
  { label: "6:00", value: "6:00" },
  { label: "7:00", value: "7:00" },
  { label: "8:00", value: "8:00" },
  { label: "9:00", value: "9:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
  { label: "24:00", value: "24:00" },
];

export enum TimeRangeKeys {
  ALL_TIME = "ALL_TIME",
  INFINITE_TO_NOW = "INFINITE_TO_NOW",
  LAST_MONTH_TO_NOW = "LAST_MONTH_TO_NOW",
  LAST_TWO_WEEKS_TO_NOW = "LAST_TWO_WEEKS_TO_NOW",
  LAST_WEEK = "LAST_WEEK",
  THIS_WEEK_START_TO_NOW = "THIS_WEEK_START_TO_NOW",
  LAST_WEEK_THROUGH_NOW = "LAST_WEEK_THROUGH_NOW",
  YESTERDAY = "YESTERDAY",
  TODAY_UP_TO_NOW = "TODAY_UP_TO_NOW",
  TODAY = "TODAY",
  NOW_TO_END_OF_TODAY = "NOW_TO_END_OF_TODAY",
  TOMORROW = "TOMORROW",
  NOW_TO_THIS_WEEK_END = "NOW_TO_THIS_WEEK_END",
  THIS_WEEK = "THIS_WEEK",
  NOW_PLUS_ONE_WEEK = "NOW_PLUS_ONE_WEEK",
  NEXT_WEEK = "NEXT_WEEK",
  NOW_PLUS_ONE_MONTH = "NOW_PLUS_ONE_MONTH",
  NOW_TO_INFINITE = "NOW_TO_INFINITE",
}

export interface TimeRangeValue {
  label: string;
  value: TimeRangeKeys;
}

export type TimeRangeType = {
  [K in keyof typeof TimeRangeKeys]: TimeRangeValue;
};

// REMEMBER TO UPDATE THE TIME RANGE FILTER IN HELPERS/FILTERS.TS WHEN UPDATING THIS
export const TIME_RANGE: Record<TimeRangeKeys, TimeRangeValue> = {
  [TimeRangeKeys.ALL_TIME]: {
    label: "All time",
    value: TimeRangeKeys.ALL_TIME,
  },
  [TimeRangeKeys.INFINITE_TO_NOW]: {
    label: "All",
    value: TimeRangeKeys.INFINITE_TO_NOW,
  },
  [TimeRangeKeys.LAST_MONTH_TO_NOW]: {
    label: "Past month",
    value: TimeRangeKeys.LAST_MONTH_TO_NOW,
  },
  [TimeRangeKeys.LAST_TWO_WEEKS_TO_NOW]: {
    label: "Past 2 weeks",
    value: TimeRangeKeys.LAST_TWO_WEEKS_TO_NOW,
  },
  [TimeRangeKeys.LAST_WEEK]: {
    label: "Last week",
    value: TimeRangeKeys.LAST_WEEK,
  },
  [TimeRangeKeys.THIS_WEEK_START_TO_NOW]: {
    label: "This week",
    value: TimeRangeKeys.THIS_WEEK_START_TO_NOW,
  },
  [TimeRangeKeys.LAST_WEEK_THROUGH_NOW]: {
    label: "Last week to today",
    value: TimeRangeKeys.LAST_WEEK_THROUGH_NOW,
  },
  [TimeRangeKeys.YESTERDAY]: {
    label: "Yesterday",
    value: TimeRangeKeys.YESTERDAY,
  },
  [TimeRangeKeys.TODAY_UP_TO_NOW]: {
    label: "Today",
    value: TimeRangeKeys.TODAY_UP_TO_NOW,
  },
  [TimeRangeKeys.TODAY]: {
    label: "Today",
    value: TimeRangeKeys.TODAY,
  },
  [TimeRangeKeys.NOW_TO_END_OF_TODAY]: {
    label: "Today",
    value: TimeRangeKeys.NOW_TO_END_OF_TODAY,
  },
  [TimeRangeKeys.TOMORROW]: {
    label: "Tomorrow",
    value: TimeRangeKeys.TOMORROW,
  },
  [TimeRangeKeys.NOW_TO_THIS_WEEK_END]: {
    label: "This week",
    value: TimeRangeKeys.NOW_TO_THIS_WEEK_END,
  },
  [TimeRangeKeys.THIS_WEEK]: {
    label: "This week",
    value: TimeRangeKeys.THIS_WEEK,
  },
  [TimeRangeKeys.NOW_PLUS_ONE_WEEK]: {
    label: "In the next week",
    value: TimeRangeKeys.NOW_PLUS_ONE_WEEK,
  },
  [TimeRangeKeys.NEXT_WEEK]: {
    label: "Next week",
    value: TimeRangeKeys.NEXT_WEEK,
  },
  [TimeRangeKeys.NOW_PLUS_ONE_MONTH]: {
    label: "In the next month",
    value: TimeRangeKeys.NOW_PLUS_ONE_MONTH,
  },
  [TimeRangeKeys.NOW_TO_INFINITE]: {
    label: "Today onwards",
    value: TimeRangeKeys.NOW_TO_INFINITE,
  },
};

export type IntegrationPartner = "ashby" | "greenhouse" | "lever" | "slack";

export const PARTNER_INSTRUCTION_URLS: { [key in IntegrationPartner]: string } = {
  ashby: "https://example.com",
  greenhouse: "https://example.com",
  lever: "https://example.com",
  slack: "https://example.com",
};

export const TASK_TYPES = {
  CUSTOM: "custom",
  ASSIGN_BUDDY: "assign_buddy",
  CREATE_ONBOARDING_PLAN: "create_onboarding_plan",
  CREATE_EMAIL_ACCOUNT: "create_email_account",
  SEND_MESSAGE_PROMPT: "send_message",
  SCHEDULE_MEETING: "schedule_meeting",
};

export const COMMUNICATION_TYPE = {
  CHAT: "chat",
  EMAIL: "email",
  TASK_NOTIFIER: "task_notifier",
  TASK_REMINDER: "task_reminder",
};

export const ACTIONABLE_TYPE = {
  CHAT: "chat",
  EMAIL: "email",
  TASK: "task",
};

export const RELATION_TARET_TYPE: Record<string, PathRelationTarget> = {
  BUDDY: "buddy",
  NEW_HIRE: "new_hire",
  MANAGER: "manager",
  PEOPLE_TEAM: "people_team_contact",
};

export const ASSIGNED_ACTION_STATUS_TYPES = {
  PENDING: "pending",
  PROCESSED: "processed",
  ERROR: "error",
  SKIPPED: "skipped",
  REMOVED: "removed",
};

export const TARGET_TYPE = {
  ONBOARDING_JOURNEY_NETWORK_RELATION: "OnboardingJourneyNetworkRelation",
  USER: "User",
  MESSAGE_SERVICE_CHANNEL: "MessageServiceChannel",
};

export const STAGE = {
  PRE: "Pre-Boarding",
  DAY_1: "Day 1",
  DAY_2: "Day 2",
  WEEK_1: "Week 1",
  WEEK_2: "Week 2",
  MONTH_1: "Month 1",
  MONTH_2: "Month 2",
  MONTH_3: "Month 3",
  MONTHS_3_6: "Months 3-6",
};
