import { Box, Flex } from "@chakra-ui/react";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import InnerSidebarNavButton from "layouts/Sidebar/InnerSidebarNavButton";
import { PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";

const SIDEBAR_LIST: InnerSidebarRow[] = [
  { label: "Company Info", route: generatePath(PEOPLE_TEAM_ROUTES.company.info) },
  { label: "Individual Settings", route: generatePath(PEOPLE_TEAM_ROUTES.company.individualSettings) },
  { label: "Email Settings", route: generatePath(PEOPLE_TEAM_ROUTES.company.emailSettings) },
  { label: "Notifications", route: generatePath(PEOPLE_TEAM_ROUTES.company.notifiers) },
];

type InnerSidebarRow = {
  label: string;
  route: string;
};

type InnerSidebarProps = PropsWithChildren<{
  sidebarList?: InnerSidebarRow[];
}>;

export default function InnerSidebar({ children, sidebarList = SIDEBAR_LIST }: InnerSidebarProps) {
  return (
    <Box display="flex" height="100%">
      <Flex
        ml={-6}
        mt={-6}
        mr={6}
        p={6}
        pt={10}
        bg="bg.surface"
        w="200px"
        borderX="1px"
        borderTop="1px"
        borderColor="gray.200"
        height="calc(100% + var(--chakra-sizes-12))"
        gap="1"
        direction="column"
      >
        {sidebarList.map((item) => (
          <InnerSidebarNavButton key={item.route} route={item.route}>
            {item.label}
          </InnerSidebarNavButton>
        ))}
      </Flex>
      <Box width="100%" p={4}>
        {children}
      </Box>
    </Box>
  );
}
