import {
  Box, HStack, Heading, Stack, Tag, useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useCallback, useEffect } from "react";
import { BaseAssignedAction } from "models/automation";
import { scheduledActionToStatusColor, scheduledActionStatusConverter } from "helpers/scheduledWorkflowConverters";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import { useUpdateAssignedAction } from "features/AssignedAction/hooks";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import TimePickerField from "components/FormElements/Fields/TimePickerField";
import { buildISODate, convertToLocalDate, convertToLocalTimeString } from "helpers/time";
import { SelectedTimezone } from "models/timezone";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Schema, formSchema as schema } from "./schema";
import {
  BODY_FIELD,
  EMAIL_TYPE_FIELD,
  MESSAGE_FIELD,
  NAME_FIELD,
  SUBJECT_FIELD,
  TIME_OF_DAY_FIELD,
  TIMEZONE_FIELD,
  TRIGGER_DATE_FIELD,
} from "./definitions";

const values = (action: BaseAssignedAction): Schema => ({
  body:
    action.actionType === "email" || action.actionType === "chat"
      ? (action?.content?.bodyTrix ?? action?.content?.body)
      : undefined,
  emailType: action.actionType === "email" ? action?.emailType : undefined,
  message: action.actionType === "task_reminder" ? action?.content?.message : undefined,
  name: action?.name,
  subject: action.actionType === "email" ? action?.content?.subject : undefined,
  actionType: action?.actionType,
  trigger: action?.trigger,
  timezone: action?.timezone,
  triggerDate: action?.trigger?.type === "date_time" ? convertToLocalDate(action?.trigger?.data?.datetime, action?.timezone || "UTC") : undefined,
  timeOfDay: action?.trigger?.type === "date_time" ? convertToLocalTimeString(action?.trigger?.data?.datetime, action?.timezone || "UTC") : undefined,
});

interface ScheduledActionFormProps {
  assignedAction: BaseAssignedAction | null;
  onClose?: () => void;
}

export default function ScheduledActionForm({ assignedAction, onClose = undefined }: ScheduledActionFormProps) {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema),
    defaultValues: values(assignedAction),
  });
  const toast = useToast({
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate, isPending: isLoading } = useUpdateAssignedAction({
    action: assignedAction,
    onSuccess: () => {
      toast({
        title: "Action Updated.",
        status: "success",
      });
      onClose?.();
    },
  });

  const onSubmit = useCallback((data: Schema) => {
    const {
      emailType,
      subject,
      body,
      message,
      triggerDate,
      timeOfDay,
      ...rest
    } = data;
    const formData = {
      ...rest,
      emailType,
      content: {
        subject,
        body,
        message,
      },
    };
    mutate(formData as unknown as Partial<AssignedAction>);
  }, [mutate]);

  const handleDateChange = useCallback(
    (newDate: Date | undefined) => {
      if (newDate) {
        const [time, timezone] = getValues(["timeOfDay", "timezone"]);
        const newIsoDate = buildISODate(newDate, time || "09:00", timezone || "UTC");
        setValue("trigger.data.datetime", new Date(newIsoDate), { shouldDirty: true });
        setValue("triggerDate", new Date(newDate), { shouldDirty: true });
      }
    },
    [getValues, setValue],
  );

  const handleTimeChange = useCallback(
    (newTime: string) => {
      if (newTime) {
        const [triggerDate, timezone] = getValues(["triggerDate", "timezone"]);
        const newIsoDate = buildISODate(triggerDate, newTime, timezone);
        setValue("trigger.data.datetime", new Date(newIsoDate), { shouldDirty: true });
        setValue("timeOfDay", newTime, { shouldDirty: true });
      }
    },
    [getValues, setValue],
  );

  const handleTimeZoneChange = useCallback(
    (newTimeZone: SelectedTimezone) => {
      if (newTimeZone) {
        const [trigger] = getValues(["trigger"]);
        const triggerDateTime = trigger?.data?.datetime;
        if (triggerDateTime) {
          const newLocalDate = convertToLocalDate(triggerDateTime, newTimeZone?.value || "UTC");
          const newLocalTime = convertToLocalTimeString(triggerDateTime, newTimeZone?.value || "UTC");
          setValue("triggerDate", newLocalDate, { shouldDirty: true });
          setValue("timeOfDay", newLocalTime, { shouldDirty: true });
          setValue("timezone", newTimeZone.value, { shouldDirty: true });
        } else {
          console.error(`Cannot find trigger for action ${assignedAction?.id}`);
        }
      }
    },
    [getValues, assignedAction?.id, setValue],
  );

  useEffect(() => {
    setFocus("name");
    reset();
  }, [reset, setFocus]);

  const [actionType, trigger] = watch(["actionType", "trigger"]);
  const proceedText = "Update Action";
  const targetType = assignedAction?.workflowAction.actionable.targetType;

  return (
    <>
      <Stack alignItems="start">
        {assignedAction && (
          <Tag
            textAlign="center"
            paddingY="4px"
            size="md"
            variant="solid"
            colorScheme={scheduledActionToStatusColor(assignedAction)}
          >
            {scheduledActionStatusConverter(assignedAction?.status)}
          </Tag>
        )}

        {actionType === "email" && (
          <Box width="30%" alignItems="start">
            <SingleSelectField
              {...EMAIL_TYPE_FIELD}
              isClearable={false}
              required
              errors={errors}control={control}
            />
          </Box>
        )}

        <Box width="100%">
          <TextInputField
            {...NAME_FIELD}
            required
            errors={errors}
            register={register}
          />
        </Box>

        {trigger && trigger.type === "date_time" && (
          <HStack width="100%">
            <Box width="350px">
              <DatePickerField
                {...TRIGGER_DATE_FIELD}
                errors={errors}
                control={control}
                onDateChange={(date: Date | undefined) => {
                  handleDateChange(date);
                }}
              />
            </Box>
            <TimePickerField
              {...TIME_OF_DAY_FIELD}
              width="170px"
              formFieldProps={{ width: "auto" }}
              control={control}
              errors={errors}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleTimeChange(event.target.value);
              }}
            />
            <Box width="350px !important">
              <TimezoneSelectField
                {...TIMEZONE_FIELD}
                size="sm"
                control={control}
                errors={errors}
                formFieldProps={{ width: "100%" }}
                onChange={(newTimeZone: SelectedTimezone) => {
                  handleTimeZoneChange(newTimeZone);
                }}
              />
            </Box>
          </HStack>
        )}

        {actionType && ["email", "chat"].includes(actionType) && (
          <Heading size="xs" mt="6">
            Content
          </Heading>
        )}

        {actionType && ["task_reminder"].includes(actionType) && (
          <TextInputField {...MESSAGE_FIELD} errors={errors} register={register} />
        )}

        <>
          {actionType === "email" && <TextInputField {...SUBJECT_FIELD} errors={errors} register={register} />}
          {actionType === "email" && (
            <ActionRichTextAreaField
              actionType={actionType}
              targetType={targetType}
              {...BODY_FIELD}
              errors={errors}
              control={control}
            />
          )}
          {actionType === "chat" && (
            <ActionRichTextAreaField
              actionType={actionType}
              targetType={targetType}
              {...BODY_FIELD}
              label="Message"
              placeholder="Message"
              errors={errors}
              control={control}
            />
          )}
        </>
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={() => onClose?.()}
          proceedText={proceedText}
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}
