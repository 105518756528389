import { Props, SingleValueProps } from "chakra-react-select";
import TimezoneSelect from "react-timezone-select";
import { components } from "react-select";
import { useController, UseControllerProps } from "react-hook-form";
import { useColorModeValue, useTheme } from "@chakra-ui/react";
import { DEFAULT_INPUT_VARIANT } from "definitions/constants/styling";

function DisplayTimezoneWithNoOffsetNumber({ children, ...props }: SingleValueProps) {
  const match = ((children as string) || "").match(/\(([^)]+)\)(?!.*\()/);
  const modifiedValue = match ? match[1] : children;
  return <components.SingleValue {...props}>{modifiedValue}</components.SingleValue>;
}

interface TimezoneSelectProps {
  setDefault?: boolean;
}

type ControlledSelectProps = UseControllerProps & Props & TimezoneSelectProps;

function ControlledTimezone({
  control,
  name,
  rules,
  variant,
  placeholder,
  setDefault = false,
  ...rest
}: ControlledSelectProps) {
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name,
    control,
    rules,
    ...(setDefault ? { defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone } : {}),
  });

  const handleChange = (event: any) => {
    onChange(event.value);
  };

  const menuBg = useColorModeValue("menu.bg", "var(--chakra-colors-gray-700)");
  const optionBg = useColorModeValue("card.bg", "transparent");
  const optionFocusBackground = useColorModeValue("var(--chakra-colors-gray-100)", "var(--chakra-colors-whiteAlpha-100)");

  const chakraTheme = useTheme();
  const formVariant = variant || DEFAULT_INPUT_VARIANT;

  return (
    <TimezoneSelect
      value={value ?? ""}
      components={{ SingleValue: DisplayTimezoneWithNoOffsetNumber }}
      onBlur={onBlur}
      onChange={handleChange}
      menuPortalTarget={document.body}
      labelStyle="altName"
      useBasicStyles
      name={name}
      variant={formVariant}
      className={`timezone chakra-react-select-container-single-select${value ? " chakra-react-select--has-value" : ""}`}
      classNamePrefix="chakra-react-select"
      placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
      styles={{
        option: (provided, state) => ({
          ...provided,
          color: "var(--chakra-colors-fg-muted)",
          backgroundColor: state.isFocused ? optionFocusBackground : menuBg,
          cursor: "pointer",
          fontSize: "var(--chakra-fontSizes-md)",
        }),
        control: (styles, state) => ({
          ...styles,
          ...chakraTheme.components.Input.baseStyle.field,
          boxShadow: "none",
          borderRadius: "var(--chakra-radii-lg)",
          cursor: "pointer",
          backgroundColor: optionBg,
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        singleValue: (styles) => ({
          ...styles,
          color: "var(--chakra-colors-fg-muted)",
          backgroundColor: "transparent",
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
        menuList: (provided) => ({ ...provided, background: menuBg }),
        menu: (provided) => ({ ...provided, cursor: "pointer", background: menuBg }),
      }}
      {...rest}
    />
  );
}

export default ControlledTimezone;
