import { Alert, AlertIcon, AlertTitle, AlertDescription, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { pendingNewHirePathById } from "helpers/url";

interface PendingNewHireAlertBannerProps {
  newHireId: string;
}

export default function PendingNewHireAlertBanner({ newHireId }: PendingNewHireAlertBannerProps) {
  const alertTextColor = useColorModeValue("fg.subtle", "inherit");
  return (
    <RouterLink to={pendingNewHirePathById(newHireId)}>
      <Alert status="warning" borderTopRadius="10px">
        <AlertIcon />
        <AlertTitle color={alertTextColor}>This new hire is pending!</AlertTitle>
        <AlertDescription color={alertTextColor}>
          Visit the pending new hire page to add additional information and kick off the automations.
        </AlertDescription>
      </Alert>
    </RouterLink>
  );
}
