import React from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import { useRemoveAssignedPath } from "features/AssignedPath/hooks";

interface RemovePathConfirmationProps {
  assignedPathId: string;
  newHireJourneyId: string;
  iconSize?: string;
}

export default function RemovePathConfirmation({
  assignedPathId,
  newHireJourneyId,
  iconSize,
}: RemovePathConfirmationProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const { mutate: onDelete } = useRemoveAssignedPath(assignedPathId, onClose);

  return (
    <>
      <IconButton
        icon={<FiTrash2 />}
        variant="tertiary"
        aria-label="Remove Path"
        onClick={onOpen}
        {...(iconSize ? { size: iconSize } : {})}
      />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Path
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&apos;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => onDelete()} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

RemovePathConfirmation.defaultProps = {
  iconSize: null,
};
