import { HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { useState } from "react";
import { MessageServiceChannel } from "models/integration/messageService";
import { Notifier, NotifierTarget } from "models/notifiers";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import NotifierTargetTypeSelector, { SelectNotifierTargetValue } from "./NotifierTargetTypeSelector";
import { notifierKeys } from "../hooks";

const DEFAULT_SET_NOTIFIER_TARGET_TYPE = "message_channel";

const generateSchema = (notifierTargetType: SelectNotifierTargetValue) => {
  switch (notifierTargetType) {
    case "message_channel":
      return yup.object({
        deliveryMethod: yup.string().required(),
        notifierId: yup.string().required(),
        recipientId: yup.string().required().label("This"),
        recipientType: yup.string().required(),
        emailType: yup.string().notRequired(),
        customEmail: yup.string().notRequired(),
      });
    case "message_user":
    case "email_user":
      return yup.object({
        deliveryMethod: yup.string().required(),
        notifierId: yup.string().required(),
        recipientId: yup.string().required().label("This"),
        recipientType: yup.string().required(),
        emailType: yup.string().notRequired(),
        customEmail: yup.string().notRequired(),
      });
    case "email_custom":
      return yup.object({
        deliveryMethod: yup.string().required(),
        notifierId: yup.string().required(),
        customEmail: yup.string().required().label("This"),
        recipientId: yup.string().notRequired(),
        recipientType: yup.string().notRequired(),
        emailType: yup.string().notRequired(),
      });
    default:
      return yup.object({});
  }
};

type Schema = yup.InferType<typeof schema>;

type FormData = Schema;

export default function NotifierTargetForm({ notifierId, onClose }: { notifierId: string; onClose: () => void }) {
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [notifierTargetType, setNotifierTargetType] = useState(DEFAULT_SET_NOTIFIER_TARGET_TYPE);
  const [cacheKey, updateCacheKey] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    register,
    resetField,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(generateSchema(notifierTargetType)),
    defaultValues: { notifierId },
  });

  const { mutate: onSubmit, isPending: isLoading } = useMutation(
    {
      mutationFn: async (data: FormData) => apiClient.post<NotifierTarget>(generatePath(API_ROUTES.notifierTargets.base), { notifierTarget: data }),
      onSuccess: (newNotifierTarget) => {
        queryClient.setQueryData<Notifier[]>(notifierKeys.lists(), (prevData) => {
          if (!prevData) return prevData; // Handle case where data is null or undefined
          return prevData.map((notifier) => (notifier.id === newNotifierTarget.notifierId
            ? { ...notifier, ...{ notifierTargets: [...notifier.notifierTargets, newNotifierTarget] } }
            : notifier));
        });
        onClose();
        toast({
          title: "Notification Recipient Added!",
          status: "success",
        });
      },
      onError: (e) => {
        console.log(e);
        toast({ title: "Error", description: "There was an error, please try again.", status: "error" });
      },
    },
  );

  return (
    <Stack spacing="3">
      <NotifierTargetTypeSelector
        defaultNotifierTargetType={DEFAULT_SET_NOTIFIER_TARGET_TYPE}
        setNotifierTargetType={setNotifierTargetType}
        setValue={setValue}
        resetField={resetField}
        updateCacheKey={updateCacheKey}
      />
      {notifierTargetType === "message_channel" && (
        <GenericAsyncSelectField
          fetchPath={API_ROUTES.messageServices.channels.base}
          extractValue={(v: MessageServiceChannel) => v.id}
          embedOption={(v) => ({
            value: v.id,
            label: `#${v.name}`,
            rawValue: v,
          })}
          name="recipientId"
          label="Select Channel"
          control={control}
          errors={errors}
          cacheKey={cacheKey}
        />
      )}
      {["message_user", "email_user"].includes(notifierTargetType) && (
        <UserSelectField cacheKey={cacheKey} control={control} name="recipientId" label="Select User" errors={errors} />
      )}
      {notifierTargetType === "email_custom" && (
        <TextInputField name="customEmail" label="Email Address" errors={errors} register={register} />
      )}
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Add Notification Recipient"
          isLoading={isLoading}
        />
      </HStack>
    </Stack>
  );
}
