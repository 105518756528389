import {
  useDisclosure,
  CreateToastFnReturn,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { HThreeDotMenu } from "components/Menu/ThreeDotMenu";
import { DuplicatePathForm, UpdatePathForm } from "components/Forms/PathForm";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { Path } from "models/automation";
import { PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";
import { pathKeys } from "./hooks";

interface PathMenuButtonProps extends PropsWithChildren {
  path: Path;
}

const CONFIRM_DELETE = {
  header: "Delete Path",
  body: "Are you sure you'd like to delete this path?",
  confirmButtonText: "Yes, delete",
};

export default function PathMenuButton({ path }: PathMenuButtonProps) {
  const { isOpen, onOpen: handleEdit, onClose } = useDisclosure();
  const { isOpen: duplicateOpen, onOpen: handleDuplicate, onClose: onDuplicateClose } = useDisclosure();

  const { performRequest: handleDelete } = useDeferredApiCall<{ success: true }>(
    generatePath(API_ROUTES.paths.show, { id: path?.id || "" }),
    {
      method: "DELETE",
      onSuccess: (_, queryClient, toast, navigate) => {
        queryClient.invalidateQueries({ queryKey: pathKeys.lists() });
        toast({ title: "Path Deleted" });
        if (window.location.pathname !== generatePath(PEOPLE_TEAM_ROUTES.paths.index)) {
          navigate(generatePath(PEOPLE_TEAM_ROUTES.paths.index));
        }
      },
      onFailure: (_, _1: QueryClient, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );

  return (
    <>
      <HThreeDotMenu>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <ButtonWithConfirm {...CONFIRM_DELETE} intent="warning" handleConfirm={handleDelete}>
          <MenuItem>Delete</MenuItem>
        </ButtonWithConfirm>
      </HThreeDotMenu>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>Update Path</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <UpdatePathForm workflow={path} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal closeOnOverlayClick={false} isOpen={duplicateOpen} onClose={onDuplicateClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>Duplicate Path</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <DuplicatePathForm path={path} onClose={onDuplicateClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
