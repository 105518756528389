import { AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Text, Accordion, Icon, AccordionProps } from "@chakra-ui/react";
import { IssueTypeMap } from "features/Issues/definitions";
import { removeSecondOrderIssues } from "features/Issues/helpers";
import { AssignedActionIssue } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { LuOctagonX } from "react-icons/lu";
import { TbAlertTriangleFilled } from "react-icons/tb";

interface IndividualIssueExpanderProps {
  assignedAction: AssignedAction;
  issue: AssignedActionIssue;
}

function IndividualIssueExpander({ issue, assignedAction }: IndividualIssueExpanderProps) {
  const issueTypeFunction = IssueTypeMap[issue.type] || ((assignedAction, _issue) => null);
  const issueInfo = issueTypeFunction(assignedAction, issue);
  if (!issueInfo) {
    console.error("issueInfo", issueInfo);
    return null;
  }
  const icon = issue.severity === "error" ? LuOctagonX : TbAlertTriangleFilled;
  return (
    <AccordionItem width="100%">
      <h2>
        <AccordionButton>
          <Icon as={icon} color={issue.severity === "error" ? "error" : "warning"} mr="2" />
          <Box as="span" flex="1" textAlign="left">
            {issueInfo.humanReadable}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text whiteSpace="normal" textAlign="start" fontSize="sm" mb={2} color="fg.muted">
          {issueInfo.additionalContext}
        </Text>
        <Text whiteSpace="normal" textAlign="start" fontSize="sm" fontWeight="bold" fontStyle="italic" color="fg.muted">
          {issueInfo.recommendation}
        </Text>
        {issueInfo.resolutionOptions(assignedAction)}
      </AccordionPanel>
    </AccordionItem>
  );
}

interface IssueExpanderProps extends AccordionProps {
  assignedAction: AssignedAction;
}

export default function IssueExpander({ assignedAction, ...rest }: IssueExpanderProps) {
  return (
    <Accordion allowMultiple width="100%" {...rest}>
      { removeSecondOrderIssues(assignedAction?.issues || [], assignedAction).map((issue) => (
        <IndividualIssueExpander assignedAction={assignedAction} issue={issue} key={JSON.stringify(issue)} />
      )) }
    </Accordion>
  );
}
