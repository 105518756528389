import ROLE from "models/role";
import {
  ADMIN_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_COMPANY_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";

import CompaniesPage from "pages/Admin/CompaniesPage";
import CompanyPage from "pages/Admin/CompaniesPage/show";
import PeopleTeamDashboardPage from "pages/PeopleTeam/DashboardPage";
import LoginPage from "pages/Unauthenticated/LoginPage";
import ProfilePage from "pages/ProfilePage";
import SettingsPage from "pages/SettingsPage";
import Home from "pages/Home";
import AuthenticatePage from "pages/Unauthenticated/AuthenticatePage";
import WorkforcePage from "pages/PeopleTeam/WorkforcePage";
import UserPage from "pages/UserPage";
import HangoutPage from "pages/HangoutPage";
import PathsPage from "pages/PeopleTeam/PathsPage";
import PathPage from "pages/PeopleTeam/PathsPage/PathPage";
import PathActionPage from "pages/PeopleTeam/PathsPage/ActionPage";
import AssignedPathPage from "pages/PeopleTeam/AssignedPathPage";
import CompanyInfoPage from "pages/PeopleTeam/Settings/CompanyInfoPage";
import CompanyIntegrationsPage from "pages/PeopleTeam/CompanyIntegrationsPage";
import NewHiresPage from "pages/Shared/NewHires/NewHiresPage";
import NewHirePage from "pages/Shared/NewHires/NewHirePage";
import ScheduledActionPage from "pages/PeopleTeam/AssignedActionPage";
import TemplatePathPage from "pages/PeopleTeam/TemplatePathPage";
import TemplatePathActionPage from "pages/PeopleTeam/TemplatePathActionPage";
import StatsPage from "pages/PeopleTeam/StatsPage";
import AdminUsersPage from "pages/Admin/UsersPage";
import AdminHubPage from "pages/Admin/HubPage";
import TaskPage from "pages/Shared/Tasks/TaskPage";
import TasksPage from "pages/Shared/Tasks/TasksPage";
import IssuesPage from "pages/PeopleTeam/IssuesPage";
import InvertedIssuesPage from "pages/PeopleTeam/InvertedIssuesPage";
import IntegrationSuccessPage from "pages/PeopleTeam/IntegrationSuccessPage";
import IntegrationPage from "pages/PeopleTeam/IntegrationPage";
import UnauthenticatedIntegrationSuccessPage from "pages/Unauthenticated/UnauthenticatedIntegrationSuccessPage";
import ActivityFeedPage from "pages/PeopleTeam/ActivityFeedPage";
import NotifiersPage from "pages/PeopleTeam/Settings/NotificationsSettingsPage";
import { Navigate } from "react-router-dom";
import IndividualPage from "pages/PeopleTeam/Settings/IndividualSettingsPage";
import EmailSettingsPage from "pages/PeopleTeam/Settings/EmailSettingsPage";
import PendingNewHiresPage from "pages/Shared/NewHires/PendingNewHiresPage";
import PendingNewHirePage from "pages/Shared/NewHires/PendingNewHirePage";
import NewHiresOverviewPage from "pages/Shared/NewHires/NewHiresOverviewPage";
import AuthenticatedRoute from "../routes/AuthenticatedRoute";
import UnauthenticatedRoute from "../routes/UnauthenticatedRoute";
import { DarkMode } from "@chakra-ui/color-mode";
import EmailConfigurationPage from "pages/Admin/CompaniesPage/EmailConfigurationPage";

interface Route {
  path: string;
  title: string;
  component: JSX.Element;
  permission: ROLE[];
}

// UNAUTHENTICATED ROUTES
const UNAUTHENTICATED_ROUTES_PERMISSIONS = {
  permission: [],
};

const LOGIN_ROUTE: Route = {
  path: SHARED_ROUTES.login,
  title: "Login",
  component: <LoginPage />,
  ...UNAUTHENTICATED_ROUTES_PERMISSIONS,
};

const AUTHENTICATE_ROUTE: Route = {
  path: SHARED_ROUTES.authenticate,
  title: "Authenticate",
  component: <AuthenticatePage />,
  ...UNAUTHENTICATED_ROUTES_PERMISSIONS,
};

const UNAUTHENTICATED_INTEGRATION_SUCCESS_ROUTE: Route = {
  path: SHARED_ROUTES.integrationSuccess,
  title: "Integration Success",
  component: <UnauthenticatedIntegrationSuccessPage />,
  ...UNAUTHENTICATED_ROUTES_PERMISSIONS,
};

// SHARED ROUTES
const SHARED_ROUTES_PERMISSIONS = {
  permission: Object.values(ROLE),
};

const SHARED_HOME_ROUTE: Route = {
  path: SHARED_ROUTES.home,
  title: "Home",
  component: <Home />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_PROFILE_ROUTE: Route = {
  path: SHARED_ROUTES.profile,
  title: "Profile",
  component: <ProfilePage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const INTEGRATION_SUCCESS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.integrations.success,
  title: "Integration Success",
  component: <IntegrationSuccessPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_SETTINGS_ROUTE: Route = {
  path: SHARED_ROUTES.settings,
  title: "Settings",
  component: <SettingsPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_SHOW_USER_ROUTE: Route = {
  path: SHARED_ROUTES.showUser,
  title: "View User",
  component: <UserPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_SHOW_TASKS_ROUTE: Route = {
  path: SHARED_ROUTES.tasks.index,
  title: "Show All Tasks",
  component: <TasksPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_SHOW_TASK_ROUTE: Route = {
  path: SHARED_ROUTES.tasks.show,
  title: "Show Task",
  component: <TaskPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

const SHARED_HANGOUT_ROUTE: Route = {
  path: SHARED_ROUTES.hangout,
  title: "Hangout",
  component: <HangoutPage />,
  ...SHARED_ROUTES_PERMISSIONS,
};

// PEOPLE_TEAM ROUTES
const PEOPLE_TEAM_ROUTES_PERMISSIONS = {
  permission: [ROLE.PEOPLE_TEAM],
};

const PEOPLE_TEAM_DASHBOARD_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.dashboard,
  title: "People Team Dashboard",
  component: <PeopleTeamDashboardPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_ISSUES_QUEUE_WARNING_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.issues.warning,
  title: "Warning Issues",
  component: <IssuesPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_ISSUES_QUEUE_ERROR_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.issues.error,
  title: "Errored Issues",
  component: <IssuesPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_ISSUES_ROUTE_NEW: Route = {
  path: PEOPLE_TEAM_ROUTES.issues.new,
  title: "New Issues",
  component: <InvertedIssuesPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SHOW_WORKFLOW_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.paths.show,
  title: "Show Path",
  component: <PathPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SHOW_SCHEDULED_WORKFLOW_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.assignedPaths.show,
  title: "Show Assigned Path",
  component: <AssignedPathPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SHOW_SCHEDULED_ACTIONS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.activityFeed.index,
  title: "Show Activity Feed",
  component: <ActivityFeedPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SHOW_SCHEDULED_ACTION_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.assignedActions.show,
  title: "Show Assigned Action",
  component: <ScheduledActionPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_WORKFLOWS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.paths.index,
  title: "View Paths",
  component: <PathsPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SHOW_WORKFLOW_ACTION_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.actions.show,
  title: "Show Action",
  component: <PathActionPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_TEMPLATE_WORKFLOW_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.templatePaths.show,
  title: "Template Path",
  component: <TemplatePathPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_TEMPLATE_WORKFLOW_ACTION_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.templateActions.show,
  title: "Template Action",
  component: <TemplatePathActionPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_STATS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.stats,
  title: "People Team Stats",
  component: <StatsPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_WORKFORCE_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.workforce,
  title: "People Team Workforce",
  component: <WorkforcePage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_SETTINGS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.settings,
  title: "Company Settings",
  component: <Navigate to={PEOPLE_TEAM_ROUTES.company.info} replace />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_COMPANY_INFO_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.info,
  title: "Company Info",
  component: <CompanyInfoPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_NOTIFICATION_SETTINGS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.notifiers,
  title: "Notifications Setup",
  component: <NotifiersPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_INDIVIDUAL_SETTINGS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.individualSettings,
  title: "Individual Settings",
  component: <IndividualPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_EMAIL_SETTINGS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.emailSettings,
  title: "Email Settings",
  component: <EmailSettingsPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_COMPANY_INTEGRATIONS_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.integrations.index,
  title: "Integrations",
  component: <CompanyIntegrationsPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

const PEOPLE_TEAM_COMPANY_INTEGRATION_ROUTE: Route = {
  path: PEOPLE_TEAM_ROUTES.company.integrations.show,
  title: "Integration Page",
  component: <IntegrationPage />,
  ...PEOPLE_TEAM_ROUTES_PERMISSIONS,
};

// SHARED COMPANY ROUTES
const SHARED_COMPANY_ROUTES_PERMISSIONS = {
  permission: [ROLE.PEOPLE_TEAM, ROLE.MANAGER],
};

const SHARED_COMPANY_PENDING_NEW_HIRE_JOURNEYS_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHireJourneys.pending.index,
  title: "Pending New Hires",
  component: <PendingNewHiresPage />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

const SHARED_COMPANY_NEW_HIRE_JOURNEYS_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHireJourneys.index,
  title: "New Hires",
  component: <NewHiresPage isManager={false} />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

const SHARED_COMPANY_PENDING_NEW_HIRE_JOURNEY_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHireJourneys.pending.show,
  title: "Pending New Hire",
  component: <PendingNewHirePage />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

const SHARED_COMPANY_NEW_HIRE_JOURNEYS_OVERVIEW_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHireJourneys.overview,
  title: "Onboarding Overview",
  component: <NewHiresOverviewPage />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

const SHARED_COMPANY_NEW_HIRE_JOURNEY_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHires.showAll,
  title: "New Hire",
  component: <NewHirePage />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

const SHARED_COMPANY_NEW_HIRE_JOURNEY_TASKS_SHOW_ROUTE: Route = {
  path: SHARED_COMPANY_ROUTES.newHireJourneys.tabs.tasks.show,
  title: "New Hire Task",
  component: <TaskPage />,
  ...SHARED_COMPANY_ROUTES_PERMISSIONS,
};

// Camino Team Routes

const CAMINO_TEAM_ROUTES_PERMISSIONS = {
  permission: [ROLE.CAMINO_TEAM],
};

const ADMIN_HUB_ROUTE: Route = {
  path: ADMIN_ROUTES.hub,
  title: "Admin Hub",
  component: <AdminHubPage />,
  ...CAMINO_TEAM_ROUTES_PERMISSIONS,
};

const ADMIN_COMPANY_ROUTE: Route = {
  path: ADMIN_ROUTES.companies.show,
  title: "Company",
  component: <CompanyPage />,
  ...CAMINO_TEAM_ROUTES_PERMISSIONS,
};

const ADMIN_COMPANY_EMAIL_CONFIGURATION_ROUTE: Route = {
  path: ADMIN_ROUTES.companies.emailConfiguration.index,
  title: "Email Configuration",
  component: <EmailConfigurationPage />,
  ...CAMINO_TEAM_ROUTES_PERMISSIONS,
};

const ADMIN_COMPANIES_ROUTE: Route = {
  path: ADMIN_ROUTES.companies.index,
  title: "Manage Companies",
  component: <CompaniesPage />,
  ...CAMINO_TEAM_ROUTES_PERMISSIONS,
};

const ADMIN_MANAGE_USERS_ROUTE: Route = {
  path: ADMIN_ROUTES.users.index,
  title: "Manage Users",
  component: <AdminUsersPage />,
  ...CAMINO_TEAM_ROUTES_PERMISSIONS,
};

const initialAuthenticatedRoutes = [
  SHARED_HOME_ROUTE,
  SHARED_PROFILE_ROUTE,
  INTEGRATION_SUCCESS_ROUTE,
  UNAUTHENTICATED_INTEGRATION_SUCCESS_ROUTE,
  SHARED_SETTINGS_ROUTE,
  SHARED_SHOW_USER_ROUTE,
  SHARED_SHOW_TASKS_ROUTE,
  SHARED_SHOW_TASK_ROUTE,
  SHARED_HANGOUT_ROUTE,
  PEOPLE_TEAM_DASHBOARD_ROUTE,
  PEOPLE_TEAM_ISSUES_QUEUE_WARNING_ROUTE,
  PEOPLE_TEAM_ISSUES_QUEUE_ERROR_ROUTE,
  PEOPLE_TEAM_ISSUES_ROUTE_NEW,
  PEOPLE_TEAM_WORKFLOWS_ROUTE,
  PEOPLE_TEAM_SHOW_WORKFLOW_ROUTE,
  PEOPLE_TEAM_SHOW_SCHEDULED_WORKFLOW_ROUTE,
  PEOPLE_TEAM_SHOW_SCHEDULED_ACTION_ROUTE,
  PEOPLE_TEAM_SHOW_SCHEDULED_ACTIONS_ROUTE,
  PEOPLE_TEAM_SHOW_WORKFLOW_ACTION_ROUTE,
  PEOPLE_TEAM_TEMPLATE_WORKFLOW_ROUTE,
  PEOPLE_TEAM_TEMPLATE_WORKFLOW_ACTION_ROUTE,
  PEOPLE_TEAM_STATS_ROUTE,
  PEOPLE_TEAM_WORKFORCE_ROUTE,
  PEOPLE_TEAM_SETTINGS_ROUTE,
  PEOPLE_TEAM_COMPANY_INFO_ROUTE,
  PEOPLE_TEAM_NOTIFICATION_SETTINGS_ROUTE,
  PEOPLE_TEAM_INDIVIDUAL_SETTINGS_ROUTE,
  PEOPLE_TEAM_EMAIL_SETTINGS_ROUTE,
  PEOPLE_TEAM_COMPANY_INTEGRATIONS_ROUTE,
  PEOPLE_TEAM_COMPANY_INTEGRATION_ROUTE,
  SHARED_COMPANY_PENDING_NEW_HIRE_JOURNEYS_ROUTE,
  SHARED_COMPANY_NEW_HIRE_JOURNEYS_ROUTE,
  SHARED_COMPANY_PENDING_NEW_HIRE_JOURNEY_ROUTE,
  SHARED_COMPANY_NEW_HIRE_JOURNEYS_OVERVIEW_ROUTE,
  SHARED_COMPANY_NEW_HIRE_JOURNEY_ROUTE,
  SHARED_COMPANY_NEW_HIRE_JOURNEY_TASKS_SHOW_ROUTE,
  ADMIN_HUB_ROUTE,
  ADMIN_COMPANY_ROUTE,
  ADMIN_COMPANIES_ROUTE,
  ADMIN_MANAGE_USERS_ROUTE,
  ADMIN_COMPANY_EMAIL_CONFIGURATION_ROUTE,
];

const authenticatedRoutes = initialAuthenticatedRoutes.map((route) => ({
  ...route,
  component: <AuthenticatedRoute permission={route.permission}>{route.component}</AuthenticatedRoute>,
}));

const unauthenticatedRoutes = [AUTHENTICATE_ROUTE, LOGIN_ROUTE, UNAUTHENTICATED_INTEGRATION_SUCCESS_ROUTE].map(
  (route) => ({
    ...route,
    component: <UnauthenticatedRoute>{route.component}</UnauthenticatedRoute>,
  }),
);

export default unauthenticatedRoutes.concat(authenticatedRoutes);
