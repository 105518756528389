import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import {
  Button,
  Center,
  Tag,
  TagLabel,
  Text,
  VStack,
  Card,
  CardBody,
  Stack,
  Box,
  Switch,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavbar } from "hooks/useNavbar";
import { titleize } from "helpers/string";
import { DeleteIcon } from "@chakra-ui/icons";
import { webhookUrl } from "helpers/integrations";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import CardFieldRow from "components/DataDisplay/CardFieldRow";
import moment from "moment";
import { useDeleteIntegration, useIntegrationDetailQuery, useToggleActiveIntegration } from "features/Integrations/hooks";
import SyncChannelsButton from "./CompanyIntegrationsPage/SyncChannelsButton";
import SyncUsersButton from "./CompanyIntegrationsPage/SyncUsersButton";

const CONFIRM_DELETE = {
  header: "CONFIRM you want to delete this integration",
  body: "This cannot be undone! Be sure you want to remove this integration now.",
  confirmButtonText: "Confirm Delete",
};

export default function IntegrationPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const [isActive, setIsActive] = useState(false);

  const { performRequest: toggleIsActive } = useToggleActiveIntegration(id || "");

  const handleToggle = useCallback(() => {
    toggleIsActive({ integration: { isActive: !isActive } });
    setIsActive((a) => !a);
  }, [setIsActive, toggleIsActive, isActive]);

  const { data, isLoading } = useIntegrationDetailQuery(id || "");

  useEffect(() => {
    if (data && !isLoading) {
      setPageTitle(`${titleize(data?.partner)} Integration`);
      setBreadcrumbs([
        {
          name: "Integrations",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.company.integrations.index),
          isCurrentPage: false,
        },
        {
          name: `${titleize(data?.partner)} Integration`,
          isCurrentPage: true,
        },
      ]);
      setIsActive(data.isActive);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  const { performRequest: handleDelete } = useDeleteIntegration(id || "", () => navigate(PEOPLE_TEAM_ROUTES.company.integrations.index));

  return (
    <AppPage>
      <Center>
        <Card>
          <CardBody>
            <VStack align="stretch" spacing={4}>
              <Text fontSize="xl">Integration Details</Text>
              {data && data.partner !== "slack" && (
                <Flex alignItems="flex-start" mt="2">
                  <FormLabel fontWeight="bold">Is Active</FormLabel>
                  <Switch colorScheme="brand" id="isActive" onChange={handleToggle} isChecked={isActive} />
                </Flex>
              )}
              <Stack>
                <Text color="fg.emphasized" fontWeight="bold">
                  Partner
                </Text>
                <Tag size="lg" colorScheme="green" width="fit-content">
                  <TagLabel>{data && titleize(data.partner)}</TagLabel>
                </Tag>
              </Stack>
              {data && data.partner === "slack" && (
                <Flex direction="column" gap="8" mt="4">
                  <Stack>
                    <Text color="fg.emphasized" fontWeight="bold">
                      Slack Workspace
                    </Text>
                    <Tag size="lg" colorScheme="brand" width="fit-content">
                      <TagLabel>{data && data?.messageService?.url}</TagLabel>
                    </Tag>
                  </Stack>
                  <Stack gap="4">
                    <Stack>
                      <Text color="fg.emphasized" fontWeight="bold">
                        Channels last synced
                      </Text>
                      <Tag size="lg" colorScheme="brand" width="fit-content">
                        <TagLabel>{data && moment(data?.messageService?.channelsLastSyncedAt).fromNow()}</TagLabel>
                      </Tag>
                    </Stack>
                    <SyncChannelsButton />
                  </Stack>
                  <Stack gap="4">
                    <Stack>
                      <Text color="fg.emphasized" fontWeight="bold">
                        Users last synced
                      </Text>
                      <Tag size="lg" colorScheme="brand" width="fit-content">
                        <TagLabel>{data && moment(data?.messageService?.usersLastSyncedAt).fromNow()}</TagLabel>
                      </Tag>
                    </Stack>
                    <SyncUsersButton />
                  </Stack>
                </Flex>
              )}
              {data && data.partner !== "slack" && (
                <Stack>
                  <Text color="fg.emphasized" fontWeight="bold">
                    Event
                  </Text>
                  <Tag size="lg" colorScheme="brand" width="fit-content">
                    <TagLabel>{data && data.event}</TagLabel>
                  </Tag>
                </Stack>
              )}
              {data && data.partner !== "slack" && (
                <CardFieldRow
                  fieldName="Webhook Url"
                  displayValue={webhookUrl(data) as string}
                  copyableValue={webhookUrl(data)}
                  flexDirection="column"
                  fontSize="md"
                  headerProps={{ fontWeight: "bold" }}
                />
              )}
              {/* Display other fields similarly */}
              {data && (
                <Box mt="4">
                  <ButtonWithConfirm {...CONFIRM_DELETE} handleConfirm={handleDelete}>
                    <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={handleDelete}>
                      Delete Integration
                    </Button>
                  </ButtonWithConfirm>
                </Box>
              )}
            </VStack>
          </CardBody>
        </Card>
      </Center>
    </AppPage>
  );
}
