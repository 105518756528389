import { Box, SystemStyleObject, TableProps } from "@chakra-ui/react";

import {
  ColumnDef, ColumnHelper, DeepKeys, getCoreRowModel, useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { MetaQuery, ResponseModel } from "services/ApiClient/responseModel";
import useQueryString from "hooks/useQueryString";
import { BaseTable } from "./TableComponents";

export interface IColumnDefinition<T> {
  attribute: DeepKeys<T>;
  label: string;
  disableSorting?: true;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const simpleColumn = <T,>(
  columnDefinition: IColumnDefinition<T>,
  colHelper: ColumnHelper<T>,
  enableMultiSort: boolean = false,
) => colHelper.accessor(columnDefinition.attribute, {
  header: columnDefinition.label,
  enableSorting: !columnDefinition.disableSorting,
  enableMultiSort,
});

export interface GenericTableProps<T> extends TableProps {
  fetchData: (query: string, signal?: AbortSignal) => Promise<ResponseModel<T[]>>;
  queryKey: readonly string[];
  columns: ColumnDef<T, any>[];
  meta?: MetaQuery;
  sx?: SystemStyleObject;
  additionalQueries?: Record<string, string | undefined | string[]>;
  textWrap?: boolean;
  includeNumberedRow?: boolean;
  emptyStateComponent?: React.ReactNode;
}

export default function GenericTable<T>({
  fetchData,
  queryKey,
  columns,
  meta,
  sx,
  additionalQueries,
  textWrap,
  includeNumberedRow,
  emptyStateComponent,
  ...props
}: GenericTableProps<T>) {
  const {
    queryString, pagination, setPagination, sortBy, setSortBy,
  } = useQueryString(meta, additionalQueries);

  const { data, isLoading, error } = useQuery({
    queryKey: queryKey.concat([queryString]),
    queryFn: async ({ signal }) => fetchData(queryString.length ? `?${queryString}` : "", signal),
    placeholderData: (previousData: any) => previousData,
    refetchOnWindowFocus: false,
  });

  const pageCount = data?.meta?.pagination?.totalPages ?? 1;

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount,
    state: {
      pagination,
      sorting: sortBy,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortBy,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: true,
    enableMultiSort: true,
  });

  if (error) {
    return <Box>{`An error has occurred ${error.message}`}</Box>;
  }

  return (
    <BaseTable
      table={table}
      meta={meta}
      sx={sx}
      textWrap={textWrap}
      isLoading={isLoading}
      includeNumberedRow={includeNumberedRow}
      emptyStateComponent={emptyStateComponent}
      {...props}
    />
  );
}

GenericTable.defaultProps = {
  meta: undefined,
  sx: undefined,
  additionalQueries: undefined,
  textWrap: false,
  includeNumberedRow: false,
  emptyStateComponent: null,
};
