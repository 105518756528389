/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import { Box, ComponentWithAs, Flex, FlexProps, Icon, IconProps, Skeleton, Text, useColorModeValue } from "@chakra-ui/react";

interface GreyBlackLabelProps extends FlexProps {
  label: string;
  icon?: IconType | ((props: IconProps) => Element) | ComponentWithAs<"svg", IconProps>;
  labelProps?: object;
  iconProps?: object;
  labelContainerProps?: object;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export interface GreyBlackFieldProps extends GreyBlackLabelProps {
  valueProps?: object;
  isLoading?: boolean;
}

function getSizeProps(size: GreyBlackFieldProps["size"]) {
  let labelFontSize;
  let valueFontSize;
  let boxSize;
  switch (size) {
    case "xs":
      labelFontSize = "2xs";
      valueFontSize = "xs";
      boxSize = "2";
      break;
    case "md":
      labelFontSize = "sm";
      valueFontSize = "md";
      boxSize = "4";
      break;
    case "lg":
      labelFontSize = "md";
      valueFontSize = "lg";
      boxSize = "5";
      break;
    case "xl":
      labelFontSize = "lg";
      valueFontSize = "xl";
      boxSize = "6";
      break;
    default:
      labelFontSize = "xs";
      valueFontSize = "sm";
      boxSize = "3";
      break;
  }
  return {
    labelProps: {
      fontSize: labelFontSize,
    },
    valueProps: {
      fontSize: valueFontSize,
    },
    iconProps: {
      boxSize,
    },
  };
}

export function GreyBlackLabel({
  label,
  icon = undefined,
  size = "sm",
  labelProps = {},
  iconProps = {},
  labelContainerProps = {},
}: GreyBlackLabelProps) {
  const { labelProps: sizeLabelProps, iconProps: sizeIconProps } = getSizeProps(size);
  const textColor = useColorModeValue("notBlack.50", "notBlack.100");
  return (
    <Flex align="center" gap="1" {...labelContainerProps}>
      {icon && <Icon {...sizeIconProps} as={icon} {...iconProps} />}
      <Text {...sizeLabelProps} color={textColor} fontWeight="normal" {...labelProps}>
        {label}
      </Text>
    </Flex>
  );
}

export default function GreyBlackField({
  label,
  icon = undefined,
  size = "sm",
  isLoading = false,
  valueProps = {},
  labelProps = {},
  iconProps = {},
  labelContainerProps = {},
  children,
  ...rest
}: GreyBlackFieldProps) {
  const valueColor = useColorModeValue("valueBlack.DEFAULT", "var(--chakra-colors-body-text)");
  const { labelProps: sizeLabelProps, valueProps: sizeValueProps, iconProps: sizeIconProps } = getSizeProps(size);
  return (
    <Flex align="flex-start" direction="column" gap=".5" {...rest}>
      <GreyBlackLabel
        {...{ label, icon, size, labelProps: { ...sizeLabelProps, ...labelProps }, iconProps: { ...sizeIconProps, ...iconProps }, labelContainerProps }}
      />
      {isLoading ? (
        <Skeleton height="1.5rem" width="100%" />
      ) : (
        typeof children === "string" ? (
          <Box as="span" height="7" display="inline-flex" alignItems="center">
            <Text {...sizeValueProps} color={valueColor} fontWeight="normal" lineHeight="1.2" {...valueProps}>
              {children}
            </Text>
          </Box>
        ) : (
          children
        )
      )}
    </Flex>
  );
}

export function HorizontalGreyBlackField({
  label,
  icon = undefined,
  size = "sm",
  isLoading = false,
  labelProps = {},
  valueProps = {},
  iconProps = {},
  labelContainerProps = {},
  children,
  ...rest
}: GreyBlackFieldProps) {
  const valueColor = useColorModeValue("valueBlack.DEFAULT", "var(--chakra-colors-whiteAlpha-900)");
  const { labelProps: sizeLabelProps, valueProps: sizeValueProps, iconProps: sizeIconProps } = getSizeProps(size);
  return (
    <Flex align="center" gap="2" {...rest}>
      <GreyBlackLabel
        {...{ label, icon, size, labelProps: { ...sizeLabelProps, ...labelProps }, iconProps: { ...sizeIconProps, ...iconProps }, labelContainerProps }}
      />
      {isLoading ? (
        <Skeleton height="1.5rem" width="100%" />
      ) : (
        typeof children === "string" ? (
          <Text {...sizeValueProps} color={valueColor} fontWeight="normal" lineHeight="1.2" {...valueProps}>
            {children}
          </Text>
        ) : (
          children
        )
      )}
    </Flex>
  );
}
