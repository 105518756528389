import {
  Accordion,
  AccordionItem,
  Card,
  Flex,
  HStack,
  Heading,
  Text,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  List,
  ListItem,
  Tag,
  VStack,
  Badge,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { RandomEmptyIssueState } from "components/EmptyComponents/RandomEmptyState";
import ControlledCardTable, { ControlledCardTableProps } from "components/Table/CardTable/ControlledCardTable";
import { useIssueListQuery } from "features/Issues/hooks";
import useNavbar from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { useEffect } from "react";

type Issue = {
  id: string;
  issueType: string; // TODO: Replace with enum or union type
  newHireName: string;
  newHireJourneyId: string;
  actions: { id: string; actionType: string; name: string; status: "error" | "processed" }[];
};
const columnHelper = createColumnHelper<Issue>();
const columnDefs = [
  columnHelper.accessor((row) => row, {
    id: "issueType",
    header: "Type",
  }),
  columnHelper.accessor((row) => row, {
    id: "newHireJourneyId",
    header: "New Hire",
  }),
];

function IssueCard({ rowData }: { rowData: Issue }) {
  const { issueType, actions, newHireName } = rowData;
  return (
    <Card p={4} boxShadow="md" mb="2" maxW="1000px">
      <Flex flexDir="column" minH="100px">
        <VStack align="start" pb="4px">
          <Heading size="sm" as="h2">
            {issueType}
          </Heading>
          <Text fontSize="xl">{`New Hire: ${newHireName}`}</Text>
        </VStack>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Text fontSize="lg">Affected actions</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <List>
                {(actions ?? []).map((action) => (
                  <ListItem key={action.id}>
                    <HStack>
                      <Text>{action.name}</Text>
                      <Badge colorScheme="cyan">{action.actionType}</Badge>
                      <Tag color="yellow">{action.status}</Tag>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Card>
  );
}

export default function IssuesPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const { data, isLoading } = useIssueListQuery({
    placeholderData: [],
  });

  console.log(data, "data");

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Issues",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Issues");
  }, [setBreadcrumbs, setPageTitle]);

  const tableProps: Pick<
  ControlledCardTableProps<Issue>,
  "columns" | "initialPagination" | "emptyStateComponent" | "data"
  > = {
    columns: columnDefs,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    emptyStateComponent: <RandomEmptyIssueState />,
    data,
  };

  return (
    <AppPage>
      <ControlledCardTable {...tableProps} isLoading={isLoading} cardComponent={IssueCard} includePagination={false} />
    </AppPage>
  );
}
