/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

function CalendarMonth(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M9.5 2H9V1H8V2H4V1H3V2H2.5C1.945 2 1.505 2.45 1.505 3L1.5 10C1.5 10.2652 1.60536 10.5196 1.79289 10.7071C1.98043 10.8946 2.23478 11 2.5 11H9.5C10.05 11 10.5 10.55 10.5 10V3C10.5 2.45 10.05 2 9.5 2ZM9.5 10H2.5V5H9.5V10ZM9.5 4H2.5V3H9.5V4Z"
        fill="#959393"
      />
    </Icon>
  );
}
export default CalendarMonth;
