import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import { useQueryClient } from "@tanstack/react-query";
import { TASK_TYPES } from "definitions/constants";
import { Task } from "models";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TargetableTypeField from "components/FormElements/Fields/TargetableTypeField";
import { findTaskNotifier, isTask } from "features/Task/helpers";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { taskTargetText } from "helpers/workflowActionableConverters";
import { Box, Stack } from "@chakra-ui/react";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { Schema, schema } from "./schema";
import { DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD, TITLE_FIELD } from "./fields";
import useSubmitUpdateTaskForm from "./useSubmitUpdateTaskForm";
import TriggerDateTime, { triggerValues } from "../Shared/TriggerDateTime";
import { customDateTimeButtonProps, showDatepicker } from "../Shared/helpers";
import { TriggerExplanation } from "../Shared/DisplayInfo";

const values = (task: Task): Schema => {
  if (!isTask(task)) {
    throw new Error("Action is not of type task");
  }

  return {
    taskType: task?.taskType,
    title: task?.title,
    description: task?.description,
    messageTarget: task?.messageTarget
      ? {
        targetType: task?.messageTarget?.targetType,
        targetId: task?.messageTarget?.target?.id,
      }
      : null,
    messageBody: task?.messageBody?.bodyTrix ?? task?.messageBody?.body,
    ...triggerValues(findTaskNotifier(task)),
    trigger: findTaskNotifier(task)?.trigger,
  };
};

interface UpdateTaskFormProps {
  task: Task;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateTaskForm = forwardRef(
  ({ task, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateTaskFormProps, ref) => {
    const queryClient = useQueryClient();
    const taskNotifier = findTaskNotifier(task);
    const [showDatePicker, setShowDatePicker] = useState(taskNotifier && showDatepicker(taskNotifier));
    const form = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(task),
    });
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      register,
      reset,
    } = form;
    const { onSubmit: onSubmitForm, isLoading: onSubmitLoading } = useSubmitUpdateTaskForm({
      task,
      onSuccess,
    });

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields);
          const { timezone, timeOfDay, triggerDate, ...rest } = updateData;
          const { trigger, ...updatedTaskData } = rest;
          const submitData = {};
          if (timezone) {
            submitData.notifier = { timezone };
          }
          if (Object.keys(updatedTaskData).length > 0) {
            submitData.task = updatedTaskData;
          }
          if (trigger) {
            submitData.trigger = trigger;
          }
          if (updateData.messageTarget?.targetId && !updateData.messageTarget?.messageTarget) {
            updateData.messageTarget.targetType = data?.messageTarget?.targetType;
          }
          if (Object.keys(updateData).length === 0 && !timezone && !trigger) {
            onSuccess?.();
            onSuccessCallback?.();
            return;
          }
          onSubmitForm(submitData, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(task?.newHireJourneyId) });
              reset({}, { keepValues: true });
              onSuccessCallback?.();
              onSuccess?.();
            },
            onError: (error) => {
              console.error("error", error);
            },
          });
        })();
      },
      [handleSubmit, dirtyFields, onSubmitForm, onSuccess, queryClient, task?.newHireJourneyId, reset],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    return (
      <>
        <Box my="4">
          <HorizontalGreyBlackField size="lg" label="To">
            {taskTargetText(task)}
          </HorizontalGreyBlackField>
        </Box>
        <Stack my="2" gap="2" direction="column" align="start">
          <TriggerExplanation assignedAction={taskNotifier} />
          {showDatePicker ? (
            <TriggerDateTime
              form={form}
              assignedActionId={taskNotifier?.id}
            />
          ) : (
            <ButtonWithConfirm
              {...customDateTimeButtonProps("Task")}
              intent="confirmation"
              handleConfirm={() => setShowDatePicker(true)}
            />
          )}
        </Stack>
        <TextInputField {...TITLE_FIELD} errors={errors} register={register} />
        {[TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT].includes(task.taskType) && (
          <ActionRichTextAreaField
            actionType="task"
            targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
            {...DESCRIPTION_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {TASK_TYPES.SEND_MESSAGE_PROMPT === task.taskType && (
          <>
            <TargetableTypeField<Schema>
              errors={errors}
              control={control}
              label="Message Recipient"
              fieldName="messageTarget"
              targetTitle="Message Target"
            />
            <ActionRichTextAreaField
              actionType="task"
              targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
              {...MESSAGE_SUGGESTION_FIELD}
              errors={errors}
              control={control}
            />
          </>
        )}
      </>
    );
  },
);

export default UpdateTaskForm;
