import { useQuery } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { apiActionPathById } from "helpers/url";
import { Action } from "models/automation";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export const actionKeys = {
  all: ["actions"] as const,
  lists: () => [...actionKeys.all, "list"] as const,
  list: (filters: string) => [...actionKeys.lists(), { filters }] as const,
  details: () => [...actionKeys.all, "detail"] as const,
  detail: (id: string) => [...actionKeys.details(), id] as const,
};

export const useActionDetailQuery = (id: string, options = {}) => useQuery<Action>({
  queryKey: actionKeys.detail(id),
  queryFn: async () => apiClient.get(apiActionPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useActionListQuery = (queryString?: string) => {
  const query = queryString ? `?${queryString}` : "";
  return useQuery({
    queryKey: queryString ? actionKeys.list(queryString) : actionKeys.lists(),
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.actions.base)}${query}`),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });
};
