/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { NetworkingError } from "services/ApiClient/networkingError";
import { PEOPLE_TEAM_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import UnknownError from "components/EmptyComponents/UnknownError";
import ScheduledActionCard from "components/Path/ActionCard/ScheduledActionCard";
import { getPathName } from "helpers/path";
import { useNavbar } from "hooks/useNavbar";
import { actionableTypeConverter } from "helpers/workflowActionableConverters";
import { useAssignedActionDetailQuery } from "features/AssignedAction/hooks";
import { retry3TimesPolicy } from "helpers/query";

export default function AssignedActionPage() {
  const { id } = useParams<{ id: string }>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const {
    data,
    isLoading,
    error: queryError,
  } = useAssignedActionDetailQuery(id || "", {
    retry: (failureCount: number, error: any) => retry3TimesPolicy(failureCount, error),
    refetchOnWindowFocus: !isNotFound,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: data?.onboardingJourney?.user?.firstName,
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
            id: data?.onboardingJourney?.id,
          }),
          isCurrentPage: false,
        },
        {
          name: `${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.scheduledWorkflow?.workflow)}`,
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, {
            id: data?.scheduledWorkflow?.id,
          }),
          isCurrentPage: false,
        },
        {
          name: `${actionableTypeConverter(data?.workflowAction?.actionable)}: ${data?.name}`,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(`${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.scheduledWorkflow?.workflow)}`);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Assigned Action" />
        ) : (
          <UnknownError resourceId={id} resourceName="Assigned Action" />
        )
      ) : (
        <Box>{data && <ScheduledActionCard isLoading={isLoading} scheduledAction={data} />}</Box>
      )}
    </AppPage>
  );
}
