import { PathTimingTriggerData, AssignedActionDateTimeTrigger } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export function customDateTimeButtonProps(displayTaskType: "Message" | "Email" | "Task") {
  return (
    {
      header: "Custom Date and Time",
      body: `Are you sure you want to customize the date and time for when this ${displayTaskType} is sent? Doing so will override the default date and time for sending this ${displayTaskType}.`,
      confirmButtonText: "Yes",
      buttonText: "Add Custom Date and Time",
    }
  );
}

export function hasImmediateTrigger(assignedAction: AssignedAction): boolean {
  return (
    (assignedAction.workflowAction?.actionable?.trigger?.data as PathTimingTriggerData)?.referenceEvent === "immediate"
  );
}

export function hasDependencyTrigger(assignedAction: AssignedAction): boolean {
  return assignedAction?.trigger?.type === "dependency";
}

export function hasCustomizedDateTimeTrigger(assignedAction: AssignedAction): boolean {
  return !!(assignedAction?.trigger as AssignedActionDateTimeTrigger)?.data?.customized;
}

export function showDatepicker(assignedAction: AssignedAction): boolean {
  // if it has a custom datetime trigger, show the datepicker
  if (hasCustomizedDateTimeTrigger(assignedAction)) {
    return true;
  }
  // otherwise only show it if the trigger is a date_time trigger
  return !hasImmediateTrigger(assignedAction) && !hasDependencyTrigger(assignedAction);
}

export function displayActionType(actionType: string) {
  switch (actionType) {
    case "task_notifier":
      return "Task";
    case "task":
      return "Task";
    case "task_reminder":
      return "Reminder";
    case "chat":
      return "Message";
    case "meeting":
      return "Meeting";
    case "email":
      return "Email";
    default:
      console.error(`Unknown action type ${actionType}`);
      return undefined;
  }
}
