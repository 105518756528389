import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import {
  Card,
  CardBody,
  Center,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useNavbar } from "hooks/useNavbar";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import PendingNewHireAlertBanner from "components/AlertBanner/PendingNewHireAlertBanner";
import Schedule from "../../../../features/NewHireJourney/Schedule";
import TasksTab from "./Tabs/TasksTab";
import NewHireJourneyTitleBar from "../NewHiresTitleBar";
import FieldsTab from "./Tabs/FieldsTab";
import PathsTab from "./Tabs/PathsTab";
import ActivityFeedTab from "./Tabs/ActivityFeedTab";

function NewHirePage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useNewHireJourneyDetailQuery(id || "");

  useEffect(() => {
    if (data?.user?.fullName && !isLoading) {
      setPageTitle(`${data?.user?.fullName ? `${data.user.fullName}'s Onboarding` : ""}`);
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: data?.user?.fullName,
          isCurrentPage: true,
        },
      ]);
    }
  }, [data?.user?.fullName, isLoading, setBreadcrumbs, setPageTitle]);

  const memoizedTabs = useMemo(
    () => [
      { path: "schedule", label: "Schedule", content: <Schedule newHireJourney={data} isLoading={isLoading} /> },
      { path: "tasks", label: "Tasks", content: <TasksTab newHireJourney={data} /> },
      { path: "fields", label: "Fields", content: <FieldsTab newHireJourney={data} /> },
      { path: "paths", label: "Paths", content: <PathsTab newHireJourney={data} /> },
      { path: "activity-feed", label: "Activity", content: <ActivityFeedTab newHireJourney={data} /> },
    ],
    [data, isLoading],
  );

  // Extract the current tab from the URL
  const activeTabPath = location.pathname.split("/").pop();

  // Find the index of the active tab
  const activeTabIndex = memoizedTabs.findIndex((tab) => tab.path === activeTabPath);

  useEffect(() => {
    if (activeTabIndex === -1 && memoizedTabs.length > 0) {
      navigate(`/new-hires/${id}/${memoizedTabs[0].path}`, { replace: true });
    }
  }, [activeTabIndex, navigate, id, memoizedTabs]);

  // Handle tab change to navigate to the correct route
  const handleTabChange = (index: number) => {
    navigate(`/new-hires/${id}/${memoizedTabs[index].path}`);
  };

  return (
    <AppPage>
      {data?.isPending && (
        <PendingNewHireAlertBanner newHireId={id as string} />
      )}
      <NewHireJourneyTitleBar newHireJourney={data} />
      <Card mt="6">
        <CardBody>
          <Tabs index={activeTabIndex} onChange={handleTabChange} size="lg" variant="underline" isLazy>
            <TabList>
              {memoizedTabs.map(({ path, label }) => (
                <Tab key={path} color="fg.muted">{label}</Tab>
              ))}
            </TabList>
            <TabIndicator />
            <TabPanels>
              {memoizedTabs.map(({ path, content }) => (
                <TabPanel key={path}>
                  {data ? content : (
                    <Center>
                      <Spinner thickness="4px" size="xl" />
                    </Center>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </AppPage>
  );
}

export default React.memo(NewHirePage);
