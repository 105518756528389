import { Button, ButtonProps } from "@chakra-ui/react";
import { LinkProps as ReactRouterLinkProps, Link as ReactRouterLink } from "react-router-dom";

type LinkButtonProps = Pick<ReactRouterLinkProps, "to"> & ButtonProps & { linkProps?: object };

export default function LinkButton({ to, linkProps = {}, children, ...rest }: LinkButtonProps) {
  return (
    <ReactRouterLink to={to} {...linkProps}>
      <Button cursor="pointer" {...rest}>
        {children}
      </Button>
    </ReactRouterLink>
  );
}
