/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { NetworkingError } from "services/ApiClient/networkingError";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import UnknownError from "components/EmptyComponents/UnknownError";
import PathActionCard from "components/Path/ActionCard/PathActionCard";
import { useNavbar } from "hooks/useNavbar";
import { retry3TimesPolicy } from "helpers/query";
import { useActionDetailQuery } from "features/Action/hooks";

export default function ActionPage() {
  const { id } = useParams<{ id: string }>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const {
    data,
    isLoading,
    error: queryError,
  } = useActionDetailQuery(id || "", {
    retry: (failureCount: number, error: any) => retry3TimesPolicy(failureCount, error),
    refetchOnWindowFocus: !isNotFound,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "Paths",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.index),
          isCurrentPage: false,
        },
        {
          name: data.workflow?.name,
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: data?.workflowId || "" }),
          isCurrentPage: false,
        },
        {
          name: data.actionable?.name,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(data.actionable?.name);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Action" />
        ) : (
          <UnknownError resourceId={id} resourceName="Action" />
        )
      ) : (
        <Box>{data && <PathActionCard isLoading={isLoading} workflowAction={data} />}</Box>
      )}
    </AppPage>
  );
}
