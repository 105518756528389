import { Flex, Icon } from "@chakra-ui/react";
import { IoMdFlag } from "react-icons/io";
import { Select as ChakraSelect, OptionProps, SingleValueProps, chakraComponents } from "chakra-react-select";
import { StageInfo } from "features/NewHireJourney/OverviewTable/definition";
import { useMemo } from "react";
import { OptionType } from ".";

interface StageSelectProps {
  stages: StageInfo[];
  setSelectedStageId: (stageId: string) => void;
  selectedStageId: string;
}

function Option({ children, ...props }: OptionProps) {
  return (
    <chakraComponents.Option {...props}>
      <Flex align="center">
        <Icon as={IoMdFlag} mt="2px" mr={3} color="fg.muted" />
        {children}
      </Flex>
    </chakraComponents.Option>
  );
}

function SingleValue({ children, ...props }: SingleValueProps) {
  return (
    <chakraComponents.SingleValue {...props}>
      <Flex align="center">
        <Icon as={IoMdFlag} mt="2px" mr={3} color="fg.muted" />
        {children}
      </Flex>
    </chakraComponents.SingleValue>
  );
}

export default function StageSelect({ stages = [], setSelectedStageId, selectedStageId }: StageSelectProps) {
  const handleChange = ({ value, label }: { value: string; label: string }) => {
    setSelectedStageId(value);
  };

  const options = useMemo(() => [{ name: "All Stages", id: "all" }, ...stages.sort((a, b) => a.position - b.position)].map((stage) => ({ value: stage.id, label: stage.name })), [stages]);

  return (
    <ChakraSelect
      className="chakra-react-select-container-single-select"
      classNamePrefix="chakra-react-select"
      value={options.find((option) => (option as OptionType).value === selectedStageId)}
      onChange={handleChange}
      isSearchable={false}
      useBasicStyles
      menuPortalTarget={document.body}
      variant="flushedFloating"
      name="stage-select"
      options={options}
      components={{
        Option,
        SingleValue,
      }}
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          width: "190px",
        }),
        control: (provided) => ({
          ...provided,
          minWidth: "190px",
          cursor: "pointer",
        }),
      }}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
      }}
    />
  );
}
