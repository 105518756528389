import {
  Card, CardBody, Checkbox, Flex, Stack,
} from "@chakra-ui/react";
import AppPage from "layouts/AppPage";
import SearchBar from "components/SearchBar";
import { useState, useMemo, useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import OverviewTable from "features/NewHireJourney/OverviewTable";
import { ACTIONABLE_TYPE, RELATION_TARET_TYPE } from "definitions/constants";
import { ColumnDefinition, StageInfo } from "features/NewHireJourney/OverviewTable/definition";
import useQueryString from "hooks/useQueryString";
import { ActionableType, PathRelationTarget } from "models/automation";
import SelectableActionType from "components/Selectable/SelectableActionType";
import SelectableRelationTargetType from "components/Selectable/SelectableTargetType";
import StageSelect from "components/Form/Select/StageSelect";
import { useStageListQuery } from "features/Stage/hooks";

export default function NewHireJourneysOverviewPage() {
  const [searchText, setSearchText] = useState("");
  const [selectedTarget, setSelectedTarget] = useState<PathRelationTarget | "">("");
  const [selectedStageId, setSelectedStageId] = useState<string>("all");
  const [selectedActionType, setSelectedActionType] = useState<ActionableType | "">("");
  const [showEmptyStages, setShowEmptyStages] = useState<boolean>(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([{ name: "Onboarding Overview", isCurrentPage: true }]);
    setPageTitle("Onboarding Overview");
  }, [setBreadcrumbs, setPageTitle]);

  const { queryString } = useQueryString({
    filters: [],
  });

  const { data: stages } = useStageListQuery(queryString, {
    refetchOnWindowFocus: false,
  });

  const columnFilterFn = (columnDefinitions: ColumnDefinition[]) => {
    let filteredColumns = columnDefinitions;

    // Apply column filter based on selectedTarget
    switch (selectedTarget) {
      case RELATION_TARET_TYPE.NEW_HIRE:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "new_hire");
        break;
      case RELATION_TARET_TYPE.MANAGER:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "manager");
        break;
      case RELATION_TARET_TYPE.BUDDY:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "buddy");
        break;
      case RELATION_TARET_TYPE.PEOPLE_TEAM:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.relationTarget === "people_team_contact",
        );
        break;
      default:
        break;
    }

    // Apply action type filter based on selectedActionType
    switch (selectedActionType) {
      case ACTIONABLE_TYPE.EMAIL:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.EMAIL,
        );
        break;
      case ACTIONABLE_TYPE.CHAT:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.CHAT,
        );
        break;
      case ACTIONABLE_TYPE.TASK:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.TASK,
        );
        break;
      default:
        break;
    }

    return filteredColumns;
  };

  const stageFilter = useMemo(
    () => (inputStages: StageInfo[]) => {
      if (selectedStageId && selectedStageId !== "all") {
        return inputStages.filter((inputStage) => inputStage.id === selectedStageId);
      } else if (selectedStageId === "all" && !showEmptyStages) {
        return inputStages.filter((inputStage) => inputStage.columnDefinitions.length > 0);
      }
      return inputStages;
    },
    [selectedStageId, showEmptyStages],
  );

  return (
    <AppPage>
      <Card>
        <CardBody as={Stack} direction="column" w="100%" alignItems="flex-start">
          <Flex align="center" gap="12">
            <SearchBar minWidth="400px" width="400px" name="tasksSearch" onInput={setSearchText} my="4" />
            <StageSelect
              stages={stages}
              setSelectedStageId={setSelectedStageId}
              selectedStageId={selectedStageId}
            />
            <Checkbox
              colorScheme="brand"
              size="lg"
              isChecked={showEmptyStages}
              onChange={(e) => setShowEmptyStages(!showEmptyStages)}
            >
              Show Empty Stages
            </Checkbox>
          </Flex>
          <Flex
            direction={{ base: "column", lg: "row" }}
            justify={{ base: "flex-start", lg: "space-between" }}
            gap="4"
            mt="4"
            width="100%"
          >
            <SelectableRelationTargetType
              relationTargetType={selectedTarget}
              setRelationTargetType={setSelectedTarget}
            />
            <SelectableActionType actionType={selectedActionType} setActionType={setSelectedActionType} />
          </Flex>
        </CardBody>
      </Card>
      <Card mt="8" p="4">
        <CardBody>
          <OverviewTable
            stages={stages}
            searchText={searchText}
            stageFilterFn={stageFilter}
            columnFilterFn={columnFilterFn}
            selectedStageId={selectedStageId}
          />
        </CardBody>
      </Card>
    </AppPage>
  );
}
