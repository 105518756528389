import { useToast } from "@chakra-ui/react";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { Link, generatePath, useNavigate } from "react-router-dom";
import apiClient from "services/ApiClient";
import { isNetworkingError } from "services/ApiClient/networkingError";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { User } from "models/user";
import useCaminoStore from "hooks/useCaminoStore";
import { pendingNewHireKeys } from "features/PendingNewHire/hooks";
import { NewHireFormValues } from "./definitions";
import NewHireFormFields from "./Fields";
import { newHireJourneyKeys } from "../hooks";

interface NewHireFormProps {
  onClose: () => void;
}

export default function NewHireForm({ onClose }: NewHireFormProps) {
  const navigate = useNavigate();
  const currentUserId = useCaminoStore((state) => state.currentUser?.id);
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isPending: isLoading } = useMutation({
    mutationFn: (data: NewHireFormValues) => {
      const { startDate, ...rest } = data;
      return apiClient.post<User>(API_ROUTES.users.base, {
        user: rest,
        onboardingJourney: {
          startDate,
          peopleTeamContactId: currentUserId,
          workflows: {},
        },
      });
    },
    onSuccess: async (resp) => {
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.lists() });
      queryClient.invalidateQueries({ queryKey: pendingNewHireKeys.lists() });
      queryClient.invalidateQueries({ queryKey: pendingNewHireKeys.count() });
      onClose();
      toast({
        title: "New Hire Added.",
        description: (
          <Link
            to={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: resp?.onboardingJourney?.id || "" })}
          >
            Click to view
          </Link>
        ),
      });
      navigate(generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.index));
    },
    onError: (mutateError, data) => {
      console.log("userCreateError", mutateError);
      // have to add a user-defined type guard in case its not a NetworkingError
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
      if (isNetworkingError(mutateError)) {
        if (mutateError.status === 409) {
          toast({
            title: "Duplicate Error",
            description: `A user with the email address already exists.`,
            status: "error",
          });
        } else {
          toast({
            title: "Error",
            description: "There was an error, please try again.",
            status: "error",
          });
        }
      }
    },
  });

  return (
    <NewHireFormFields
      defaultValues={{}}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      proceedText="Create New Hire"
    />
  );
}
