import { useColorModeValue, useTheme } from "@chakra-ui/react";
import { GroupBase, OptionBase, ReactSelectBaseProps, Select } from "chakra-react-select";
import { DEFAULT_INPUT_VARIANT } from "definitions/constants/styling";

interface SelectProps extends ReactSelectBaseProps<OptionBase, false, GroupBase<OptionBase>> {
  chakraStyles: object;
  styles: object;
}

export default function CaminoSelect<OptionType extends OptionBase>({
  variant,
  placeholder,
  chakraStyles,
  options,
  styles,
  ...rest
}: SelectProps) {
  const formVariant = variant || DEFAULT_INPUT_VARIANT;
  const menuBg = useColorModeValue("menu.bg", "var(--chakra-colors-gray-700)");
  const optionBg = useColorModeValue("card.bg", "transparent");
  const optionFocusBackground = useColorModeValue("var(--chakra-colors-gray-100)", "var(--chakra-colors-whiteAlpha-100)");
  const chakraTheme = useTheme();

  return (
    <Select<OptionType, false, GroupBase<OptionType>>
      useBasicStyles
      menuPortalTarget={document.body}
      className="chakra-react-select-container-single-select"
      classNamePrefix="chakra-react-select"
      placeholder={["floating", "flushedFloating"].includes(formVariant) ? " " : placeholder}
      options={options}
      colorScheme="brand"
      chakraStyles={{
        option: (provided) => ({
          ...provided,
          color: "fg.emphasized",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
          cursor: "pointer",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "fg.emphasized",
        }),
        control: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
        ...chakraStyles,
      }}
      styles={{
        option: (provided, state) => ({
          ...provided,
          color: "var(--chakra-colors-fg-muted)",
          backgroundColor: state.isFocused ? optionFocusBackground : menuBg,
          cursor: "pointer",
          fontSize: "var(--chakra-fontSizes-md)",
        }),
        control: (provided) => ({
          ...provided,
          ...chakraTheme.components.Input.baseStyle.field,
          boxShadow: "none",
          borderRadius: "var(--chakra-radii-lg)",
          cursor: "pointer",
          backgroundColor: optionBg,
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "var(--chakra-colors-fg-muted)",
          backgroundColor: "transparent",
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
        menuList: (provided) => ({ ...provided, background: menuBg }),
        menu: (provided) => ({ ...provided, cursor: "pointer", background: menuBg }),
        ...styles,
      }}
      {...rest}
    />
  );
}
