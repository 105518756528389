import { useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { User } from "models/user";
import useCaminoStore from "hooks/useCaminoStore";
import apiClient from "services/ApiClient";
import ErrorText from "components/Form/ErrorText";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import { userKeys } from "features/User/hooks";
import ProfileDropzone from "../Form/ProfileDropzone";
import {
  SideBySideEmailField,
  SideBySideTextInputField,
  SideBySideTimezone,
  SideBySideTextAreaField,
} from "../Form/SideBySideFields";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";

const profilePageHeader = (isCurrentUser: boolean) => (isCurrentUser ? "Your Profile" : "User Profile");
const profilePageHeaderHelpText = (isCurrentUser: boolean) => (isCurrentUser
  ? "This info is helpful for new hires and other employees to know who you are"
  : "This info is helpful for other employees.");

const FIRST_NAME_FIELD = {
  name: "firstName",
  label: "First Name",
};

const LAST_NAME_FIELD = {
  name: "lastName",
  label: "Last Name",
};

const EMAIL_FIELD = {
  name: "workEmail",
  label: "Work Email",
};

const PERSONAL_EMAIL_FIELD = {
  name: "personalEmail",
  label: "Personal Email",
};

const PRONOUNS_FIELD = {
  name: "pronouns",
  label: "Pronouns",
};

const PROFILE_IMAGE_FIELD = {
  name: "avatarRef",
  label: "Profile Image",
};

const TIMEZONE_FIELD = {
  name: "timezone",
  label: "Timezone",
};

const LOCATION_FIELD = {
  name: "location",
  label: "Location",
};

const TITLE_FIELD = {
  name: "title",
  label: "Job Title",
};

const BIO_FIELD = {
  name: "bio",
  label: "Bio",
  helperText: "Write a short introduction about you",
};

const LINKEDIN_URL_FIELD = {
  name: "linkedinUrl",
  label: "LinkedIn",
  helperText: "Please add your LinkedIn Url",
};

export default function ProfileForm({ user, onSave }: { user: User; onSave?: () => void }) {
  const { currentUser, updateCurrentUser } = useCaminoStore((state) => ({
    currentUser: state.currentUser,
    updateCurrentUser: state.updateCurrentUser,
  }));
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { isDirty, errors, dirtyFields },
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...user },
  });
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const [pendingAvatar, setPendingAvatar] = useState<File | null>(null);
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading, error } = useMutation({
    mutationKey: userKeys.detail(user.id),
    mutationFn: (data: Partial<User> & { avatar?: { data: string; fileName: string } }) => apiClient.put<User>(generatePath(API_ROUTES.users.show, { id: user.id }), data),
    onSuccess: async (resp, data) => {
      toast({ title: "Profile updated successfully" });

      if (user.id === currentUser?.id) {
        if (data.avatar) {
          updateCurrentUser({ avatarUrl: resp.avatarUrl, ...data });
          setPendingAvatar(null);
        } else {
          updateCurrentUser(data);
        }
      }
      queryClient.invalidateQueries({ queryKey: userKeys.detail(user.id) });
      if (user.onboardingJourney?.id) {
        queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(user.onboardingJourney.id) });
      }
      onSave?.();
    },
    onError: (mutateError) => {
      console.log("mutateError", mutateError);
    },
  });

  const onSubmit = ({ avatarRef, ...formData }: Partial<User> & { avatarRef?: File }) => {
    const userData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => dirtyFields[key as keyof User] === true),
    );
    const reader = new FileReader();
    if (avatarRef) {
      reader.onload = () => {
        const data = reader.result as string;
        mutate({ avatar: { fileName: avatarRef.name, data }, ...userData });
      };
      reader.readAsDataURL(avatarRef);
    } else {
      mutate(userData);
    }
  };

  const isCurrentUser = currentUser?.id === user.id;

  return (
    <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: "4", md: "6" }}>
      <Container py={{ base: "4", md: "8" }}>
        <Stack spacing="5">
          <Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
            <Box>
              <Text textStyle="5xl" fontWeight="medium" mb="3" color="fg.subtle">
                {profilePageHeader(isCurrentUser)}
              </Text>
              <Text color="fg.muted" textStyle="sm">
                {profilePageHeaderHelpText(isCurrentUser)}
              </Text>
            </Box>
            <HStack align="end" justify="end">
              <Center h={10} mr={4}>
                <ErrorText fontWeight="600">
                  {(error as any)?.errors ? "There was an error updating the profile" : ""}
                </ErrorText>
              </Center>
              <Button
                isDisabled={!isDirty}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
                variant="primary"
                alignSelf="end"
              >
                Save
              </Button>
            </HStack>
          </Stack>
          <Divider />
          <Stack spacing="5" divider={<StackDivider />}>
            <SideBySideTextInputField {...FIRST_NAME_FIELD} register={register} />
            <SideBySideTextInputField {...LAST_NAME_FIELD} register={register} />
            <SideBySideTextInputField {...TITLE_FIELD} register={register} />
            <SideBySideEmailField {...EMAIL_FIELD} register={register} />
            <SideBySideEmailField {...PERSONAL_EMAIL_FIELD} register={register} />
            <SideBySideTextInputField {...PRONOUNS_FIELD} register={register} />

            <FormControl id={PROFILE_IMAGE_FIELD.name}>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">{PROFILE_IMAGE_FIELD.label}</FormLabel>
                <Stack
                  spacing={{ base: "3", md: "5" }}
                  direction={{ base: "column", sm: "row" }}
                  width="full"
                  maxW={{ md: "3xl" }}
                >
                  {pendingAvatar && <Avatar size="lg" src={URL.createObjectURL(pendingAvatar)} />}
                  <ProfileDropzone
                    width="full"
                    onFileAccept={(acceptedFile) => {
                      setValue(PROFILE_IMAGE_FIELD.name, acceptedFile, { shouldDirty: true });
                      setPendingAvatar(acceptedFile);
                    }}
                  />
                </Stack>
              </Stack>
            </FormControl>

            {/* <SideBySideCreateableMultiSelect {...TEAMS_FIELD} control={control} errors={errors} /> */}
            <SideBySideTextInputField {...LOCATION_FIELD} register={register} errors={errors} />
            <SideBySideTimezone {...TIMEZONE_FIELD} control={control} errors={errors} />
            <SideBySideTextInputField {...LINKEDIN_URL_FIELD} register={register} errors={errors} />
            {/* <SideBySideCreateableMultiSelect {...JOB_DOMAINS_FIELD} control={control} errors={errors} />
            <SideBySideCreateableMultiSelect {...GROUPS_FIELD} control={control} errors={errors} /> */}

            {isCurrentUser && <SideBySideTextAreaField {...BIO_FIELD} register={register} errors={errors} />}

            <Flex direction="row-reverse">
              <Button isActive={!isDirty} isLoading={isLoading} onClick={handleSubmit(onSubmit)} variant="primary">
                Save
              </Button>
              <Center h={10} mr={4}>
                <ErrorText fontWeight="600">
                  {(error as any)?.errors ? "There was an error updating the profile" : ""}
                </ErrorText>
              </Center>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

ProfileForm.defaultProps = {
  onSave: undefined,
};
