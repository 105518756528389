import { COMMUNICATION_TYPE, STAGE } from "definitions/constants";
import { isInPast } from "helpers/time";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import moment from "moment";
import { FiCheckSquare } from "react-icons/fi";
import { LuOctagonX } from "react-icons/lu";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { MdSync } from "react-icons/md";
import { BiCalendarCheck } from "react-icons/bi";

export function actionToStatusConverter(action: AssignedAction, prepend: string = "") {
  // Converting the status of an action to a human readable format
  // Communication Statuses =>
  //   awaiting_info => Missing Information
  //   error => Failed
  //   ready & datetime => Scheduled
  //   ready & task completion => Not Scheduled
  //   processed => Sent
  //   needs_attention & past => Failed
  //   needs_attention & future or unscheduled => Upcoming Failure
  //   skipped => Skipped
  //   removed => Removed
  switch (action.status) {
    case "processed":
      return {
        value: `${prepend}Sent`,
        bgColor: "var(--card-bg)",
        icon: FiCheckSquare,
        iconColor: "green.500",
      };
    case "error":
      return {
        value: `${prepend}Failed`,
        bgColor: "red.100",
        fontColor: "var(--chakra-colors-gray-800)",
        icon: LuOctagonX,
        iconColor: "rose.500",
      };
    case "awaiting_info":
      return { value: `${prepend}Missing Info`, bgColor: "orange.100", fontColor: "var(--chakra-colors-gray-800)" };
    case "needs_attention":
      if (action.trigger.data.datetime && isInPast(action.trigger.data.datetime)) {
        return {
          value: `${prepend}Failed`,
          bgColor: "red.100",
          fontColor: "var(--chakra-colors-gray-800)",
          icon: LuOctagonX,
          iconColor: "rose.500",
        };
      }
      return {
        value: `${prepend}Upcoming Failure`,
        bgColor: "red.100",
        fontColor: "var(--chakra-colors-gray-800)",
        icon: TbAlertTriangleFilled,
        iconColor: "sortaOrange.300",
      };
    case "skipped":
      return { value: `${prepend}Skipped`, bgColor: "gray.200", fontColor: "var(--chakra-colors-gray-800)" };
    case "removed":
      return { value: `${prepend}Removed`, bgColor: "gray.200", fontColor: "var(--chakra-colors-gray-800)" };
    case "ready":
      if (action.trigger.data.datetime) {
        return { value: `${prepend}Scheduled`, bgColor: "var(--card-bg)", icon: BiCalendarCheck };
      }
      return { value: `${prepend}Not Scheduled`, bgColor: "var(--card-bg)" };
    default:
      return { value: "", bgColor: "gray.500", fontColor: "var(--chakra-colors-gray-800)" };
  }
}

export function actionTaskStatusConverter(action: AssignedAction) {
  // Converting the status of an action that is a task to a human readable format
  // Task Statuses =>
  //   assigned => Communication Status (see actionToStatusConverter)
  //   notified => Notification Sent
  //   completed => Task Completed
  //   skipped => Task Skipped
  switch (action?.task?.status) {
    case "assigned":
      return actionToStatusConverter(action);
    case "notified":
      return { value: "Outstanding", bgColor: "var(--card-bg)", icon: MdSync };
    case "skipped":
      return { value: "Skipped", bgColor: "gray.200", fontColor: "var(--chakra-colors-gray-800)" };
    case "completed":
      return {
        value: "Completed",
        bgColor: "var(--card-bg)",
        icon: FiCheckSquare,
        iconColor: "green.500",
      };
    default:
      return { value: "", bgColor: "var(--card-bg)" };
  }
}

export function combinedActionTaskStatusConverter(action: AssignedAction) {
  if (COMMUNICATION_TYPE.TASK_NOTIFIER === action.actionType) {
    return actionTaskStatusConverter(action);
  }
  return actionToStatusConverter(action);
}

export function stageByStartDate(startDate: string) {
  // Takes a startDate and returns the stage that the action would probably be in
  // if the startDate is before today, return "pre";
  // if the startDate is today before today, return "day 1";
  // if the startDate is yesterday before today, return "day 2";
  // if the startDate is 2 - 5 days ago before today, return "Week 1";
  // if the startDate is 1 week ago, return "Week 2";
  // if the startDate is 2-4 weeks ago, return "Month 1";
  // if the startDate is 1 - 2 months ago, return "Month 2";
  // if the startDate is 2 - 3 months ago, return "Month 3";
  // if the startDate is 3 - 6 months ago, return "Months 3-6";
  const today = moment().startOf("day");
  const start = moment(startDate).startOf("day");

  const daysDiff = today.diff(start, "days");
  const weeksDiff = today.diff(start, "weeks");
  const monthsDiff = today.diff(start, "months");

  if (daysDiff < 0) {
    return STAGE.PRE;
  } else if (daysDiff === 0) {
    return STAGE.DAY_1;
  } else if (daysDiff === 1) {
    return STAGE.DAY_2;
  } else if (daysDiff >= 2 && daysDiff <= 5) {
    return STAGE.WEEK_1;
  } else if (weeksDiff === 1) {
    return STAGE.WEEK_2;
  } else if (weeksDiff >= 2 && weeksDiff <= 4) {
    return STAGE.MONTH_1;
  } else if (monthsDiff === 1) {
    return STAGE.MONTH_2;
  } else if (monthsDiff === 2) {
    return STAGE.MONTH_3;
  } else if (monthsDiff >= 3 && monthsDiff <= 6) {
    return STAGE.MONTHS_3_6;
  }
  return "";
}
