/* eslint-disable import/prefer-default-export */

import {
  ComponentWithAs, Flex, Icon, IconProps, Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface CardFieldProps extends PropsWithChildren {
  icon?: ComponentWithAs<"svg", IconProps>;
  label: string;
  textProps?: object;
}

export function CardField({ icon, label, textProps = {}, children }: CardFieldProps) {
  return (
    <Flex direction="column" gap="1">
      <Flex align="center" gap="1">
        {icon && <Icon boxSize="4" as={icon} />}
        <Text fontSize="xs" textAlign="center" color="notBlack.50" {...textProps}>
          {label}
        </Text>
      </Flex>
      {typeof children === "string" ? (
        <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
}

CardField.defaultProps = {
  icon: null,
};
