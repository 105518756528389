import SelectableScheduleType, { ScheduleType } from "components/Selectable/SelectableScheduleType";
import { ExtendedNewHireJourney } from "models";
import { useEffect, useState } from "react";
import { Stack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import ActionsByList from "./ActionsByList";
import ActionsByStage from "./ActionsByStage";

interface ScheduleProps {
  newHireJourney: ExtendedNewHireJourney;
  isLoading: boolean;
}

export default function Schedule({ newHireJourney, isLoading }: ScheduleProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Default to "stages" if the query parameter is missing or invalid
  const defaultView: ScheduleType = "stages";
  const queryView = searchParams.get("view") as ScheduleType;
  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    queryView === "list" || queryView === "stages" ? queryView : defaultView
  );

  // Update query params when `scheduleType` changes
  useEffect(() => {
    if (scheduleType !== queryView) {
      setSearchParams({ view: scheduleType });
    }
  }, [scheduleType, queryView, setSearchParams]);

  return (
    <Stack>
      <SelectableScheduleType scheduleType={scheduleType} setScheduleType={setScheduleType} />
      {scheduleType === "list" ? (
        <ActionsByList newHireJourney={newHireJourney} />
      ) : (
        <ActionsByStage
          newHireJourney={newHireJourney}
          isLoading={isLoading}
          showImmediateDate
        />
      )}
    </Stack>
  );
}
