import { SystemStyleObject } from "@chakra-ui/react";

export const DEFAULT_INPUT_VARIANT = "flushed";
export const DEFAULT_FIELD_GAP = "10px";

export const TABLE_BORDER_CONTAINER_PROPS: SystemStyleObject = {
  border: "1px solid var(--chakra-colors-tableBorder-DEFAULT)",
  borderRadius: "6px",
};

export const TABLE_STYLE_OVERRIDES: SystemStyleObject = {
  th: {
    paddingY: "18px",
  },
  thead: {},
};

export const DISPLAY_INFO_LABEL_PROPS = {
  fontSize: "sm",
};

export const DISPLAY_INFO_VALUE_PROPS = {
  fontSize: "md",
};

export const FLUSHED_BORDER_BASE = {
  border: "none",
  borderBottom: "1px solid",
  borderColor: "inherit",
  borderRadius: "0",
};

export const FLUSHED_ACTIVE_BOTTOM_BORDER = {
  borderBottom: "2px solid var(--chakra-colors-brand-500)",
};

export const FLUSHED_ERROR_BOTTOM_BORDER = {
  borderBottom: "2px solid var(--chakra-colors-error-500)",
};

export const floatingNoValueBorder = {
  borderBottomColor: "var(--chakra-colors-gray-200)",
  borderWidth: "2px",
  boxShadow: "none",
};

export const importantFloatingNoValueBorder = {
  border: "2px solid var(--chakra-colors-gray-200) !important",
  boxShadow: "none !important",
};

export const selectedInputBorder = {
  borderColor: "var(--chakra-colors-brand-500)",
  borderWidth: "2px",
  boxShadow: "0px 0px 10px 0px #BCECFD",
};

export const importantSelectedInputBorder = {
  border: "2px solid var(--chakra-colors-brand-500) !important",
  boxShadow: "0px 0px 10px 0px #BCECFD !important",
};

export const formInputWithValueBorder = {
  borderColor: "var(--chakra-colors-hasValueBorder-DEFAULT)",
  borderWidth: "2px",
  boxShadow: "none",
};

export const importantFormInputWithValueBorder = {
  border: "2px solid var(--chakra-colors-hasValueBorder-DEFAULT) !important",
  boxShadow: "none !important",
};
