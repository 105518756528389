import { Table } from "@tanstack/react-table";
import { OptionBase } from "chakra-react-select";
import CaminoSelect from "components/Select";

interface InputSortOption {
  id: string;
  desc: boolean;
  label: string;
}

interface SelectSortOption extends OptionBase {
  value: { id: string, desc: boolean };
  label: string;
}

interface TableSortSelectProps<T> {
  table: Table<T> | null;
  sortOptions: InputSortOption[];
}

export default function TableSortSelect({ table, sortOptions, ...rest }: TableSortSelectProps<SelectSortOption>) {
  if (!table) {
    return null;
  }

  const options: SelectSortOption[] = sortOptions.map(({ id, desc, label }) => ({
    value: { id, desc },
    label,
  }));

  const defaultSort = table.getState()?.sorting;

  return (
    <CaminoSelect<SelectSortOption>
      onChange={(selectedOption) => {
        if (selectedOption) {
          table.setSorting([selectedOption.value]);
        }
      }}
      useBasicStyles
      menuPortalTarget={document.body}
      name="tableSortBy"
      placeholder="Sort By"
      className="chakra-react-select-container-single-select"
      classNamePrefix="chakra-react-select"
      options={options}
      defaultValue={options.find((opt) => opt.value === defaultSort[0])}
      colorScheme="brand"
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          width: "220px",
        }),
      }}
      {...rest}
    />
  );
}
