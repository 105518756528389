import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import SimpleEmployeeForm from "components/Forms/SimpleEmployeeForm";
import { useQueryClient } from "@tanstack/react-query";
import { userKeys } from "features/User/hooks";
import ModalFormWrapper from "./ModalFormWrapper";

export default function NewHireButtonWithModal({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();
  return (
    <ModalFormWrapper modalHeader="Add New Employee" button={(children as JSX.Element) || <Button>Add New Employee</Button>}>
      <SimpleEmployeeForm onSuccess={() => queryClient.invalidateQueries({ queryKey: userKeys.lists() })} />
    </ModalFormWrapper>
  );
}
