import {
  HStack, Link, SystemStyleObject, Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link as RouterLink } from "react-router-dom";
import { NoPathsAssignedYetState } from "components/EmptyComponents/RandomEmptyState";
import { assignedPathPathById } from "helpers/url";
import { DashboardNewHire } from "pages/PeopleTeam/DashboardPage/models";
import { BaseAssignedPath } from "models/automation";
import RemovePathConfirmation from "components/Alert/RemovePathConfirmation";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import ControlledDataTable, { ControlledDataTableProps } from "components/Table/ControlledDataTable";
import HasError, { ActionSentStatus, EditPathButton, TaskCompletionStatus } from "./Components";

const columnHelper = createColumnHelper<BaseAssignedPath>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "workflow.name",
    header: "Path Name",
    minSize: 250,
    size: 250,
    cell: (row) => (
      <Link
        as={RouterLink}
        _hover={{ textDecoration: "none" }}
        to={{
          pathname: assignedPathPathById(row.getValue().id),
        }}
        width="fit-content"
      >
        <Text color="fg.muted">{row.getValue().workflow?.name || ""}</Text>
      </Link>
    ),
  }),
  columnHelper.accessor((row) => row, {
    id: "hasError",
    header: "",
    cell: (row) => (
      <HStack w="100%" justify="center">
        <HasError path={row.getValue()} />
      </HStack>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row, {
    id: "automations",
    header: "Sent Actions",
    cell: (row) => (
      <HStack w="100%" justify="center">
        <ActionSentStatus path={row.getValue()} />
      </HStack>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "tasks",
    header: "Tasks",
    cell: (row) => (
      <HStack w="100%" justify="center">
        <TaskCompletionStatus path={row.getValue()} />
      </HStack>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "actions",
    header: "",
    cell: (row) => (
      <HStack w="100%" spacing="4">
        <EditPathButton path={row.getValue()} />
        <RemovePathConfirmation
          assignedPathId={row.getValue()?.id}
          newHireJourneyId={row.getValue()?.onboardingJourneyId}
          iconSize="2xs"
        />
      </HStack>
    ),
  }),
];

interface AssignedPathsTableProps {
  data?: AssignedPath[];
  isLoading?: boolean;
  sx?: SystemStyleObject;
}

export default function AssignedPathsTable({ data = [], isLoading = false, sx = undefined }: AssignedPathsTableProps) {
  const tableProps: ControlledDataTableProps<DashboardNewHire> = {
    isLoading,
    data: data || [],
    columns,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [{ id: "workflow.name", desc: false }],
    textWrap: true,
    emptyStateComponent: <NoPathsAssignedYetState />,
    variant: "simple",
  };

  return (
    <ControlledDataTable
      containerProps={{
        borderRadius: "8px",
      }}
      sx={{
        width: { base: "100%", xl: "973px" },
        minWidth: { base: "400px", xl: "973px" },
        ...sx,
      }}
      {...tableProps}
    />
  );
}
