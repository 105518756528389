import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { pathKeys } from "features/Path/hooks";
import { Action } from "models/automation";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function useSubmitForm<FormData>({
  workflowId,
  onSuccess,
  transformer,
}: {
  workflowId: string;
  onSuccess: () => void;
  /// Transforms the form data into the backend's expected type
  transformer: (workflowId: string, data: FormData) => any;
}): {
  onSubmit: (data: any) => void;
  isLoading: boolean;
} {
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const queryClient = useQueryClient();

  const { mutate: onSubmit, isPending: isLoading } = useMutation(
    {
      mutationFn: async (data: any) => apiClient.post<Action>(generatePath(API_ROUTES.actions.base), transformer(workflowId, data)),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: pathKeys.detail(workflowId) });
        onSuccess();
        toast({ title: "Action added" });
      },
      onError: () => toast({ title: "Error", description: "There was an error, please try again", status: "error" }),
    },
  );

  return { onSubmit, isLoading };
}
