import { ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import ProcessedTask from "components/Forms/AssignedActions/Task/ProcessedTask";
import UpdateTaskForm from "components/Forms/AssignedActions/Task/UpdateTaskForm";
import { assignedActionSentOrSkippedAt } from "features/AssignedAction/DisplayInfo";
import { useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { useIssuesByActionIdQuery } from "features/Issues/hooks";
import { findTaskNotifier } from "features/Task/helpers";
import { titleize } from "helpers/string";
import { Task } from "models";
import { CombinedAction } from "models/automation/combinedAction";
import { useMemo, useRef, useState } from "react";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this task now!",
  body: "This cannot be undone! Be sure you want to send this task now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface TaskDrawerFormProps {
  onClose: () => void;
  combinedAction: CombinedAction;
}

export default function TaskDrawerForm({ onClose, combinedAction }: TaskDrawerFormProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const initialNotifier = useMemo(() => findTaskNotifier(combinedAction as Task), [combinedAction]);
  const issues = useIssuesByActionIdQuery(initialNotifier?.id, { enabled: !!initialNotifier?.id });

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { mutate: sendNow, isReady } = useForceSendAssignedAction({
    action: initialNotifier,
  });

  if (!combinedAction) {
    return null;
  }

  return (
    <Flex direction="column">
      <Heading mb="4" size="xs">{combinedAction.title}</Heading>
      <HorizontalGreyBlackField size="lg" label="Task Type">
        {titleize(combinedAction?.taskType)}
      </HorizontalGreyBlackField>
      {combinedAction && (
        ["skipped", "processed"].includes(findTaskNotifier(combinedAction)?.status) ? (
          <ProcessedTask task={combinedAction} />
        ) : (
          <>
            <UpdateTaskForm
              ref={formRef}
              task={combinedAction}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <ButtonWithConfirm
                intent="warning"
                isDisabled={!isReady}
                {...CONFIRM_SEND_NOW}
                handleConfirm={() => handleSubmit(() => sendNow())}
              />
              <ProceedCancelButtons
                handleSubmit={() => handleSubmit(() => {})}
                onClose={onClose}
                proceedText="Update Task"
                proceedDisabled={!isFormDirty}
                isLoading={isSubmitLoading}
              />
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
}
