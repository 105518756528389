import UserUpdateForm, { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import BaseDialogFormHeader from "components/Forms/FormHeader";
import { useUserDetailQuery } from "features/User/hooks";

interface IssuesUserFormProps {
  userId: string;
  title: string;
  cardLink?: string;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess: () => void;
  onClose: () => void;
}

export default function IssuesUserForm({
  userId, fields, title, cardLink, onSuccess, onClose,
}: IssuesUserFormProps) {
  const { data: user, isLoading } = useUserDetailQuery(userId || "");
  return (
    <BaseDialogFormHeader
      linkTo={cardLink as string}
      label={title}
      value={user?.fullName as string}
      imageUrl={user?.avatarUrl}
      isLoaded={!isLoading}
    >
      <UserUpdateForm userId={userId} fields={fields} onSuccess={onSuccess} onClose={onClose} />
    </BaseDialogFormHeader>
  );
}

IssuesUserForm.defaultProps = {
  cardLink: null,
};
