import { UseFormReturn } from "react-hook-form";
import useQueryString from "hooks/useQueryString";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import { useActionListQuery } from "features/Action/hooks";
import { DEPENDENCY_ID_FIELD } from "../fields";
import { SharedSchema } from "../schema";

export default function TaskCompletion({
  workflowId,
  workflowActionId,
  form: {
    control,
    formState: { errors },
  },
}: {
  workflowId: string;
  workflowActionId?: string;
  form: UseFormReturn<SharedSchema>;
}) {
  const { queryString } = useQueryString({
    filters: [
      { fieldName: "actionable_type", value: "WorkflowActionableTask", operator: "=" },
      { fieldName: "workflow_id", value: workflowId, operator: "=" },
    ],
  });
  const { data } = useActionListQuery(queryString);

  return (
    <SingleSelectField
      control={control}
      {...DEPENDENCY_ID_FIELD}
      options={
        data
          ?.filter((v) => v.id !== workflowActionId)
          .map((v) => ({ value: v.id, label: `${v.actionable.name}`, rawValue: v })) || []
      }
      errors={errors}
    />
  );
}

TaskCompletion.defaultProps = {
  workflowActionId: undefined,
};
