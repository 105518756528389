import {
  Flex, CardProps, Center, Badge, Box,
} from "@chakra-ui/react";
import moment from "moment";
import { timeAtDayMonth } from "helpers/time";
import { titleize } from "helpers/string";
import CardFieldRow from "components/DataDisplay/CardFieldRow";
import { Integration } from "models/integration";
import { useIntegrationListQuery } from "features/Integrations/hooks";
import IntegrationSection from "./IntegrationSection";
import IntegrationCard from "./IntegrationCard";
import SyncChannelsButton from "./SyncChannelsButton";

function WebhookBasedIntegration({ integration }: { integration: Integration }) {
  return (
    <IntegrationCard
      pauseable
      name={titleize(integration.partner)}
      id={integration?.id || ""}
      isActive={integration?.isActive}
      includeDelete
    >
      <Flex direction="column" justify="space-between" height="100%">
        <Flex direction="column" align="start">
          <CardFieldRow
            fieldName="Status"
            displayValue={(
              <Box>
                <Badge variant="subtle" colorScheme={integration.isActive ? "green" : "orange"}>
                  {integration.isActive ? "Active" : "Paused"}
                </Badge>
              </Box>
            )}
          />
          <CardFieldRow fieldName="Event" displayValue={titleize(integration.event || "")} mt="2" />
        </Flex>
        <Flex direction="row" mt="4">
          <CardFieldRow fieldName="Added" displayValue={timeAtDayMonth(integration.createdAt)} />
        </Flex>
      </Flex>
    </IntegrationCard>
  );
}

function SlackIntegration({ integration }: { integration: Integration }) {
  return (
    <IntegrationCard name={titleize(integration.partner)} id={integration?.id || ""} includeDelete>
      <Flex direction="column" justify="space-between" height="100%">
        <Flex direction="column" align="start">
          {`Channels synced ${moment(integration.messageService?.channelsLastSyncedAt).fromNow()}`}
        </Flex>
        <Center>
          <SyncChannelsButton />
        </Center>
        <Flex direction="row" justify="space-between" mt="4">
          <CardFieldRow fieldName="Last Updated" displayValue={timeAtDayMonth(integration.updatedAt)} />
          <CardFieldRow fieldName="Created At" displayValue={timeAtDayMonth(integration.createdAt)} />
        </Flex>
      </Flex>
    </IntegrationCard>
  );
}

function CompanyIntegration({ integration }: { integration: Integration }) {
  switch (integration.partner) {
    case "ashby":
      return <WebhookBasedIntegration {...{ integration }} />;
    case "greenhouse":
      return <WebhookBasedIntegration {...{ integration }} />;
    case "lever":
      return <WebhookBasedIntegration {...{ integration }} />;
    case "slack":
      return <SlackIntegration {...{ integration }} />;
    default:
      return null;
  }
}

export default function ConnectedIntegrations({ ...rest }: CardProps) {
  const { data: integrations } = useIntegrationListQuery({
    placeholderData: [],
  });
  return (
    <IntegrationSection title="Connected" isLoaded={!!integrations} {...rest}>
      <Flex direction="row" gap="4" wrap="wrap">
        {(integrations || []).map((integration: Integration) => (
          <CompanyIntegration key={integration.id} integration={integration} />
        ))}
      </Flex>
    </IntegrationSection>
  );
}
