import { User } from "models/user";
import {
  Card,
  HStack,
  Avatar,
  Flex,
  Box,
  IconButton,
  Tag,
  TagLabel,
  TagLeftIcon,
  FlexProps,
  Icon,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FiEdit, FiUserPlus } from "react-icons/fi";
import { LiaUserEditSolid } from "react-icons/lia";
import { RiUserStarLine } from "react-icons/ri";
import { LuUsers } from "react-icons/lu";
import { PiUsersFour } from "react-icons/pi";
import useCaminoStore from "hooks/useCaminoStore";
import ROLE from "models/role";
import { IconType } from "react-icons";
import { capitalize } from "lodash";
import { InfoField, InfoSpacer } from "components/DataDisplay/InfoField";
import ExternalLink from "components/Link";
import { PropsWithChildren } from "react";
import { newHireJourneyPathById, removeUrlScheme } from "helpers/url";
import { titleize } from "helpers/string";
import LinkButton from "components/Button/LinkButton";
import Exists from "components/DataDisplay/Exists";
import CaminoNewHireIcon from "components/Icon/CaminoNewHire";
import LoadingCard from "./LoadingCard";

interface EmployeeCardProps {
  employee: User | undefined;
  editClickedHandler: () => void;
}

function UserInfoColumn({ children, ...rest }: PropsWithChildren & FlexProps) {
  return (
    <Flex direction="column" justifyContent="space-between" h="220px" {...rest}>
      {children}
    </Flex>
  );
}

const roleBadges: Record<ROLE, { badge: IconType; color: string } | undefined> = {
  [ROLE.ONBOARDER]: { badge: FiUserPlus, color: "blue" },
  [ROLE.MANAGER]: { badge: RiUserStarLine, color: "red" },
  [ROLE.PEOPLE_TEAM]: { badge: PiUsersFour, color: "green" },
  [ROLE.COMPANY_ADMIN]: { badge: LiaUserEditSolid, color: "yellow" },
  [ROLE.BASIC]: { badge: LuUsers, color: "orange" },
  [ROLE.CAMINO_TEAM]: undefined,
};

function EmployeeRoles({ permission }: { permission?: ROLE[] }) {
  return (
    <Flex maxW={{ base: "300px", "2xl": "500px" }} rowGap="4px" wrap="wrap">
      {permission?.map((role) => (
        <Tag
          size="md"
          borderRadius="full"
          variant="subtle"
          colorScheme={roleBadges[role]?.color}
          key={role}
          ml={1}
          mr={1}
        >
          <TagLeftIcon boxSize="12px" as={roleBadges[role]?.badge} />
          <TagLabel>{titleize(role)}</TagLabel>
        </Tag>
      ))}
    </Flex>
  );
}

EmployeeRoles.defaultProps = {
  permission: [],
};

export default function EmployeeCard({ employee, editClickedHandler }: EmployeeCardProps) {
  const { company } = useCaminoStore((state) => ({ company: state.currentCompany }));

  if (!employee) {
    return <LoadingCard />;
  }

  const messageServiceUrl = company?.messageService && employee.messageServiceId
    ? new URL(`/team/${employee.messageServiceId}`, company.messageService.url).toString()
    : undefined;
  const messageServiceLink = messageServiceUrl ? (
    <ExternalLink to={messageServiceUrl} text={capitalize(company?.messageService?.name)} />
  ) : undefined;

  return (
    <Card direction="row" maxW="1400px">
      <HStack px={20} py={10} w="100%">
        <Flex ml={3} direction="column" h="100%" w="100%">
          <Flex justifyContent="space-between" align="flex-start">
            <Flex align="center" gap="12">
              <Avatar variant="roundedSquare" size="xl" src={employee.avatarUrl} name={employee.fullName} />
              <Flex alignItems="flex-start" direction="column" h="100%">
                <Heading
                  size="xs"
                  fontSize="xl"
                >
                  {`${employee?.deactivatedAt ? "(deactivated) " : ""}${employee?.fullName || "Employee Info"}`}
                </Heading>
                <Text fontSize="sm" color="helperText.DEFAULT">{employee?.title}</Text>
                <Flex align="center" mt="2">
                  {employee?.onboardingJourney?.id && (
                    <LinkButton
                      size="xs"
                      variant="outline"
                      leftIcon={<Icon as={CaminoNewHireIcon} />}
                      to={newHireJourneyPathById(employee?.onboardingJourney?.id)}
                    >
                      New Hire Page
                    </LinkButton>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" gap="4">
              <IconButton onClick={editClickedHandler} aria-label="edit user info" size="sm" icon={<FiEdit />} />
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" borderTop="1px solid" mt={3} pt={3} flexWrap="wrap">
            <UserInfoColumn width="33%">
              <InfoField label="Work Email" value={employee.workEmail || "None"} />
              <InfoField label="Personal Email" value={employee.personalEmail || "None"} />
              <InfoField
                label="LinkedIn"
                value={
                  employee.linkedinUrl ? (
                    <ExternalLink to={employee.linkedinUrl} text={removeUrlScheme(employee.linkedinUrl)} />
                  ) : (
                    "No LinkedIn Url..."
                  )
                }
              />
            </UserInfoColumn>
            <UserInfoColumn width="33%">
              <InfoField label="Location" value={employee.location || "No Location Provided"} />
              <InfoField label="Time Zone" value={employee.timezone || "No Timezone"} />
              <InfoField
                label="Slack"
                value={(
                  <Exists
                    labelYes="Account Synced"
                    labelNo="No Account Found"
                    condition={!!employee.messageServiceId}
                  />
                )}
              />
            </UserInfoColumn>
            <UserInfoColumn width="33%">
              <InfoField label="Bio" value={employee.bio || "No bio yet... ask them to add one!"} />
              <InfoField label="Camino ID" value={employee.id} />
              <InfoField
                label="Roles"
                value={
                  (employee.permission?.length ?? 0) > 0 ? (
                    <EmployeeRoles permission={employee.permission} />
                  ) : (
                    <Box>&nbsp;</Box>
                  )
                }
              />
              {messageServiceUrl ? <InfoField label="Direct Message" value={messageServiceLink} /> : <InfoSpacer />}
            </UserInfoColumn>
          </Flex>
        </Flex>
      </HStack>
    </Card>
  );
}
