import { MenuItem, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import Alert from "components/Alert/Alert";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { VThreeDotMenu } from "components/Menu/ThreeDotMenu";
import AssignedActionButtonWithModal from "components/ModalForm/AssignedActionForm/AssignedActionButtonWithModal";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { assignedPathKeys } from "features/AssignedPath/hooks";
import { issueKeys } from "features/Issues/hooks";
import useCaminoStore from "hooks/useCaminoStore";
import { AlertState } from "models/alert";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { PropsWithChildren, useCallback, useState } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import apiClient from "services/ApiClient";
import { assignedActionKeys } from "./hooks";

interface AssignedActionMenuButtonProps extends PropsWithChildren {
  assignedAction: AssignedAction;
  editRedirect?: boolean;
}

const CONFIRM_REMOVE = {
  header: "CONFIRM removing this Assigned Action",
  body: "Are you sure you want to remove this Assigned Action?",
  confirmButtonText: "Yes, remove",
};

const CONFIRM_SEND_NOW = {
  header: "CONFIRM sending this Assigned Action now",
  body: "This cannot be undone! Be sure you want to send this action now.",
  confirmButtonText: "Confirm send now",
};

const ALERT_SUCCESS_TITLE = "Message Sent!";
const ALERT_SUCCESS_BODY = (destination: string) => `A test was sent to ${destination}`;
const ALERT_FAILURE_TITLE = "Error";
const ALERT_FAILURE_BODY = (destination: string) => `There was an error trying to send to ${destination}`;
const ALERT_BUTTON_TITLE = "Close";

export default function AssignedActionMenuButton({ assignedAction, editRedirect }: AssignedActionMenuButtonProps) {
  const [alertState, setAlertState] = useState<AlertState>({ isOpen: false, isFailure: false });
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const queryClient = useQueryClient();
  const toast = useToast({
    title: "Success!",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const handleTestSend = async (id: string) => {
    try {
      const resp = await apiClient.post<{ success: boolean }>(
        generatePath(API_ROUTES.assignedActions.testSend, { id }),
        {},
      );
      console.log(resp);
      setAlertState({ ...{ isOpen: true, isFailure: false } });
    } catch (error) {
      setAlertState({ ...{ isOpen: true, isFailure: true } });
    }
  };

  const resetActionQueries = useCallback(
    (scheduledWorkflowId: string, newHireJourneyId: string) => {
      queryClient.invalidateQueries({ queryKey: assignedPathKeys.detail(scheduledWorkflowId) });
      queryClient.invalidateQueries({ queryKey: assignedActionKeys.newHireJourney(newHireJourneyId) });
      queryClient.invalidateQueries({ queryKey: issueKeys.lists() });
    },
    [queryClient],
  );

  const createActionRequest = useCallback(
    (activityType: "DELETE" | "PAUSE" | "UPDATE" | "SEND", actionUrlString: string, successMessage?: string) => async (action: AssignedAction, payload = {}) => {
      try {
        let resp;
        switch (activityType) {
          case "DELETE":
            resp = await apiClient.delete<{ success: boolean }>(generatePath(actionUrlString, { id: action.id }));
            break;
          case "UPDATE":
            resp = await apiClient.put<{ success: boolean }>(
              generatePath(actionUrlString, { id: action.id }),
              payload,
            );
            break;
          case "PAUSE":
          case "SEND":
            resp = await apiClient.post<{ success: boolean }>(
              generatePath(actionUrlString, { id: action.id }),
              payload,
            );
            break;
          default:
            throw new Error("Invalid method");
            break;
        }
        console.log(resp);
        if (successMessage) {
          toast({ title: successMessage });
        }
        resetActionQueries(action.scheduledWorkflowId, action?.scheduledWorkflow?.onboardingJourneyId);
      } catch (error) {
        console.log(error);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      }
    },
    [resetActionQueries, toast],
  );

  const onClose = () => {
    setAlertState({ isOpen: false, isFailure: false });
  };

  const handleForceSend = createActionRequest("SEND", API_ROUTES.assignedActions.forceSend, "Action Successfully Sent");
  const handlePause = createActionRequest("PAUSE", API_ROUTES.assignedActions.pause, "Success");
  const handleDestroy = createActionRequest("DELETE", API_ROUTES.assignedActions.show, "Action Deleted");

  if (!assignedAction) {
    return null;
  }

  return (
    <>
      <VThreeDotMenu inPortal>
        <ButtonWithConfirm
          {...CONFIRM_SEND_NOW}
          intent="confirmation"
          handleConfirm={() => handleForceSend(assignedAction)}
        >
          <MenuItem>Send Now</MenuItem>
        </ButtonWithConfirm>
        {editRedirect ? (
          <MenuItem
            as={RouterLink}
            to={`${generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: assignedAction.id })}?eid=${assignedAction.id}`}
          >
            Edit
          </MenuItem>
        ) : (
          <AssignedActionButtonWithModal assignedAction={assignedAction}>
            <MenuItem>Edit</MenuItem>
          </AssignedActionButtonWithModal>
        )}
        <MenuItem onClick={() => handlePause(assignedAction)}>
          {assignedAction.status === "skipped" ? "Resume" : "Skip"}
        </MenuItem>
        <ButtonWithConfirm {...CONFIRM_REMOVE} intent="warning" handleConfirm={() => handleDestroy(assignedAction)}>
          <MenuItem>Remove</MenuItem>
        </ButtonWithConfirm>
        {["email", "chat", "task"].includes(assignedAction?.actionType) ? (
          <MenuItem onClick={() => handleTestSend(assignedAction?.id)}>Test Send</MenuItem>
        ) : null}
      </VThreeDotMenu>
      <Alert
        title={alertState.isFailure ? ALERT_FAILURE_TITLE : ALERT_SUCCESS_TITLE}
        body={
          alertState.isFailure
            ? ALERT_FAILURE_BODY(user?.fullName as string)
            : ALERT_SUCCESS_BODY(user?.fullName as string)
        }
        buttonTitle={ALERT_BUTTON_TITLE}
        isOpen={alertState.isOpen}
        onClose={onClose}
      />
    </>
  );
}

AssignedActionMenuButton.defaultProps = {
  editRedirect: true,
};
