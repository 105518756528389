import {
  Card,
  CardBody,
  CardProps,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  MenuItem,
  useColorModeValue,
} from "@chakra-ui/react";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { HThreeDotMenu } from "components/Menu/ThreeDotMenu";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { useDeleteIntegration, useToggleActiveIntegration } from "features/Integrations/hooks";
import { PropsWithChildren } from "react";
import { generatePath, Link } from "react-router-dom";

const CONFIRM_DELETE = {
  header: "Remove Integration",
  body: "Are you sure you'd like to remove the credentials for this integration?",
  confirmButtonText: "Yes, remove",
};

interface IntegrationCardProps extends CardProps, PropsWithChildren {
  id: string;
  name: string;
  isActive?: boolean;
  includeDelete?: boolean;
  pauseable?: boolean;
}

export default function IntegrationCard({
  id,
  name,
  isActive = undefined,
  includeDelete = false,
  pauseable = false,
  children,
  ...rest
}: IntegrationCardProps) {
  const bgColor = useColorModeValue("inherit", "gray.600");
  const { performRequest: handleDelete } = useDeleteIntegration(id || "");

  const { performRequest: toggleIsActive } = useToggleActiveIntegration(id || "");

  return (
    <LinkBox
      as={Card}
      minWidth="380px"
      maxWidth="380px"
      bg={bgColor}
      _hover={{ bg: "background.tertiary", border: "1px solid #fff" }}
      {...rest}
    >
      <CardBody height="100%" display="flex" flexDirection="column">
        <Flex justifyContent={includeDelete ? "space-between" : "center"} alignItems="top">
          <Heading size="xs" color="fg.muted" mb="2">
            <LinkOverlay as={Link} to={generatePath(PEOPLE_TEAM_ROUTES.company.integrations.show, { id })}>
              {name}
            </LinkOverlay>
          </Heading>
          {includeDelete && (
            <HThreeDotMenu>
              {pauseable && (
                <MenuItem onClick={() => toggleIsActive({ integration: { isActive: !isActive } })}>
                  {isActive ? "Pause Integration" : "Enable Integration"}
                </MenuItem>
              )}
              <ButtonWithConfirm {...CONFIRM_DELETE} intent="warning" handleConfirm={handleDelete}>
                <MenuItem>Remove Integration</MenuItem>
              </ButtonWithConfirm>
            </HThreeDotMenu>
          )}
        </Flex>
        {children}
      </CardBody>
    </LinkBox>
  );
}
