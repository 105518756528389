import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Tag,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import NewHireJourneyEditButtonWithModal from "components/ModalForm/NewHireJourneyEditForm/NewHireJourneyEditButtonWithModal";
import AddNewPathModal from "components/Path/AddNewPathModal";
import { SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { dateToAbbrevMonthYear, dateToAbrevString, isInPast } from "helpers/time";
import { generatePath, Link as RouterLink, useLocation } from "react-router-dom";
import { NewHireJourney } from "models/newHire";
import StartStopAutomationsButton from "components/NewHireJourney/StartStopAutomationsButton";
import { ExtendedNewHireJourney } from "models";
import LinkButton from "components/Button/LinkButton";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { pendingNewHirePath, userPathById } from "helpers/url";
import GreyBlackField from "components/DataDisplay/GreyBlackField";
import { titleize } from "helpers/string";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import CaminoCalendar from "components/Icon/CaminoCalendar";
import CaminoManager from "components/Icon/CaminoManager";
import CaminoBuddy from "components/Icon/CaminoBuddy";
import CaminoPeopleTeam from "components/Icon/CaminoPeopleTeam";
import Linkify from "components/DataDisplay/Linkify";
import { FiEdit, FiExternalLink, FiPlus } from "react-icons/fi";
import CaminoEmail from "components/Icon/CaminoEmail";
import { HiOutlineGlobeEuropeAfrica } from "react-icons/hi2";
import { PiMapPinArea } from "react-icons/pi";
import { PropsWithChildren } from "react";
import { TiDocumentAdd } from "react-icons/ti";

interface NewHireTitleBarProps extends PropsWithChildren {
  newHireJourney: ExtendedNewHireJourney | NewHireJourney | undefined;
}

export default function NewHireTitleBar({ newHireJourney, children, ...restProps }: NewHireTitleBarProps) {
  const { pathname } = useLocation();
  const { data: company } = useCurrentCompanyQuery();
  const isNewHirePage = pathname.includes(generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" }));
  return (
    <Skeleton isLoaded={!!newHireJourney}>
      <Card width="100%" {...restProps}>
        <CardBody mx="8">
          <Stack direction="row" justify="space-between" align="flex-start">
            <Stack gap={0}>
              {isNewHirePage ? (
                <Heading
                  size="xs"
                  fontSize="xl"
                >
                  {newHireJourney?.user?.fullName}
                </Heading>
              ) : (
                <Heading
                  as={RouterLink}
                  size="xs"
                  fontSize="xl"
                  to={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" })}
                  _hover={{ textDecoration: "underline" }}
                >
                  {newHireJourney?.user?.fullName}
                </Heading>
              )}
              <Stack gap="1">
                <Text fontSize="sm" color="helperText.DEFAULT">{newHireJourney?.user?.title}</Text>
                <Flex align="center" gap="4">
                  <Flex gap="1" align="center">
                    <Icon as={CaminoCalendar} boxSize={3} />
                    <Text fontSize="sm" color="helperText.DEFAULT">
                      {isInPast(newHireJourney?.startDate) ? `Started ${dateToAbbrevMonthYear(newHireJourney?.startDate)}` : `Starting ${dateToAbrevString(newHireJourney?.startDate)}`}
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center" gap="4">
                  <Flex gap="1" align="center">
                    <Icon as={CaminoEmail} boxSize={3} />
                    <Text fontSize="sm" color="helperText.DEFAULT">
                      {newHireJourney?.user?.workEmail || "No Work Email"}
                    </Text>
                  </Flex>
                  {company?.messageService && (
                    <Tag size="sm" variant="outline" colorScheme="gray" boxShadow="inset 0 0 0px .5px" color="helperText.DEFAULT">
                      <TagLabel>
                        <Flex align="center" gap="1">
                          <Badge
                            boxShadow="none"
                            variant="solid"
                            colorScheme={newHireJourney?.user?.messageServiceId ? "green" : "red"}
                            borderRadius="8px"
                            size="xs"
                            minWidth="8px"
                            minHeight="8px"
                          />
                          {}
                          {newHireJourney?.user?.messageServiceId ? `${titleize(company?.messageService?.name)} Account Synced` : `No ${titleize(company?.messageService?.name)} Account Found`}
                        </Flex>
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
                <Flex align="center" gap="4">
                  <Flex gap="1" align="center">
                    <Icon as={PiMapPinArea} boxSize={3} />
                    <Text fontSize="sm" color="helperText.DEFAULT">
                      {newHireJourney?.user?.location || "No Location Provided"}
                    </Text>
                  </Flex>
                  <Flex gap="1" align="center">
                    <Icon as={HiOutlineGlobeEuropeAfrica} boxSize={3} />
                    <Text fontSize="sm" color="helperText.DEFAULT">
                      {newHireJourney?.user?.timezone ? newHireJourney?.user?.timezone : "No Timezone"}
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Stack>
            <Box>
              {newHireJourney && isNewHirePage && (
                <NewHireJourneyEditButtonWithModal alignSelf="center" newHireJourney={newHireJourney}>
                  <IconButton aria-label="edit new hire's information" size="sm" icon={<FiEdit />} />
                </NewHireJourneyEditButtonWithModal>
              )}
            </Box>
          </Stack>
          <Wrap
            spacingX="16"
            spacingY="4"
            mt="4"
            mb="8"
          >
            <WrapItem>
              <Linkify linkTo={newHireJourney?.manager?.id ? userPathById(newHireJourney?.manager?.id) : undefined}>
                <GreyBlackField
                  icon={CaminoManager}
                  label="Manager"
                  valueProps={{ textAlign: "left" }}
                >
                  {newHireJourney?.manager?.fullName || "TBD"}
                </GreyBlackField>
              </Linkify>
            </WrapItem>
            <WrapItem>
              <Linkify linkTo={newHireJourney?.peopleTeamContactId ? userPathById(newHireJourney?.peopleTeamContactId) : undefined}>
                <GreyBlackField
                  icon={CaminoPeopleTeam}
                  label="People Team Contact"
                  valueProps={{ textAlign: "left" }}
                >
                  {newHireJourney?.peopleTeamContact?.fullName || "TBD"}
                </GreyBlackField>
              </Linkify>
            </WrapItem>
            <WrapItem>
              <Linkify linkTo={newHireJourney?.buddy?.id ? userPathById(newHireJourney?.buddy?.id) : undefined}>
                <GreyBlackField
                  icon={CaminoBuddy}
                  label="Buddy"
                  valueProps={{ textAlign: "left" }}
                >
                  {newHireJourney?.buddy?.fullName || "TBD"}
                </GreyBlackField>
              </Linkify>
            </WrapItem>
          </Wrap>
          <Wrap>
            <WrapItem>
              <LinkButton
                size="sm"
                variant="outline"
                to={generatePath(SHARED_ROUTES.showUser, { id: newHireJourney?.user?.id || "" })}
              >
                View Profile
              </LinkButton>
            </WrapItem>
            {newHireJourney?.isPending ? (
              <WrapItem>
                <LinkButton to={pendingNewHirePath(newHireJourney)} variant="alternative" size="sm">
                  Kick Off Onboarding
                </LinkButton>
              </WrapItem>
            ) : (
              <>
                {newHireJourney?.assignedPaths && newHireJourney?.assignedPaths.length > 0 && (
                  <WrapItem>
                    <StartStopAutomationsButton size="sm" newHireJourney={newHireJourney} />
                  </WrapItem>
                )}
                <WrapItem>
                  <AddNewPathModal
                    newHireJourneyId={newHireJourney?.id}
                    currentWorkflowIds={(newHireJourney?.assignedPaths || []).map(
                      (assignedPath: AssignedPath) => assignedPath.workflow.id,
                    )}
                  >
                    <Button variant="camino-secondary" leftIcon={<FiPlus />} size="sm">
                      Add Path(s)
                    </Button>
                  </AddNewPathModal>
                </WrapItem>
                {newHireJourney && isNewHirePage && company?.hasOnboardingPlans && (
                  <WrapItem>
                    {newHireJourney?.onboardingPlanUrl ? (
                      <LinkButton
                        size="sm"
                        variant="outline"
                        rightIcon={<FiExternalLink />}
                        to={newHireJourney.onboardingPlanUrl}
                        linkProps={{ target: "_blank" }}
                      >
                        View Onboarding Plan
                      </LinkButton>
                    ) : (
                      <NewHireJourneyEditButtonWithModal alignSelf="center" newHireJourney={newHireJourney}>
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="gray"
                          color="notBlack.50"
                          leftIcon={<TiDocumentAdd color="notBlack.50" />}
                        >
                          Add Missing Onboarding Plan
                        </Button>
                      </NewHireJourneyEditButtonWithModal>
                    )}
                  </WrapItem>
                )}
              </>
            )}
          </Wrap>
          {children}
        </CardBody>
      </Card>
    </Skeleton>
  );
}
