import {
  Box,
  Card,
  Center,
  Divider,
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import AssignedActionSideDrawer from "components/AssignedActionSideDrawer";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { actionIcon } from "components/Icon";
import SearchBar from "components/SearchBar";
import CombinedActionStatusTag from "components/Tag/CombinedActionStatusTag";
import {
  combinedActionDateInfo,
  combinedActionDateTime,
  isDependencyAssignedAction,
  isImmediateAssignedAction,
  sortAssignedActionsAndTaskByTriggerDate,
} from "helpers/assignedAction";
import { toActionId, filterActions, mapNotifiersToTasks } from "helpers/newHireJourney";
import { titleize, truncateEnd } from "helpers/string";
import { isTask } from "features/Task/helpers";
import { camelCase, uniqBy } from "lodash";
import { ActionableType, BaseAssignedAction, PathRelationTarget } from "models/automation";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { MessageServiceChannel } from "models/integration/messageService";
import { ExtendedNewHireJourney } from "models";
import { SelectableStageType } from "models/stage";
import { BaseTask } from "models/task";
import { BasicUser } from "models/user";
import moment from "moment";
import SelectableActionType from "components/Selectable/SelectableActionType";
import {
  MouseEventHandler, PropsWithChildren, useCallback, useMemo, useState,
} from "react";
import SelectableTargetType from "components/Selectable/SelectableTargetType";
import DotMenu from "features/AssignedAction/Components/DotMenu";
import { useCombinedActionDetailQuery } from "features/CombinedAction/hooks";
import { useStageListQuery } from "features/Stage/hooks";

function actionStageName(startDate: Date, action: BaseAssignedAction | BaseTask) {
  const assignedAction = isTask(action) ? action.taskNotifier : action;
  if (!assignedAction) {
    console.error(`No assigned action for action ${action.id} ${action.name}`);
    return;
  }
  if (isImmediateAssignedAction(assignedAction)) {
    return "immediate";
  }
  if (isDependencyAssignedAction(assignedAction)) {
    return "dependency";
  }

  const targetDate = moment(combinedActionDateTime(assignedAction as BaseAssignedAction));
  const momentStartDate = moment(startDate);

  if (targetDate.isBefore(momentStartDate, "day")) {
    return "preboarding";
  } else if (targetDate.isSame(momentStartDate, "day")) {
    return "day1";
  } else if (targetDate.isSame(momentStartDate.clone().add(1, "day"), "day")) {
    return "day2";
  } else if (targetDate.isBefore(momentStartDate.clone().add(1, "week"), "day")) {
    return "week1";
  } else if (targetDate.isBefore(momentStartDate.clone().add(2, "week"), "day")) {
    return "week2";
  } else if (targetDate.isBefore(momentStartDate.clone().add(1, "month"), "day")) {
    return "month1";
  } else if (targetDate.isBefore(momentStartDate.clone().add(2, "month"), "day")) {
    return "month2";
  } else if (targetDate.isBefore(momentStartDate.clone().add(3, "month"), "day")) {
    return "month3";
  } else if (targetDate.isSameOrAfter(momentStartDate.clone().add(3, "month"), "day")) {
    return "afterMonth3";
  }
  return "";
}

interface StageInfo {
  stage: SelectableStageType & { label: string };
  actions: Array<BaseTask | BaseAssignedAction>;
}

interface MapActionsToStages {
  startDate: Date;
  stages: SelectableStageType[];
  actions: (BaseAssignedAction | BaseTask)[];
}

function mapActionsToStages({ startDate, stages, actions }: MapActionsToStages) {
  const initialStageMap = {
    immediate: {
      stage: { name: "immediate", label: "Send Immediately", id: "immediate", position: -1 },
      actions: [],
    },
    afterMonth3: {
      stage: { name: "afterMonth3", label: "After 3 Months", id: "afterMonth3", position: 100 },
      actions: [],
    },
    dependency: {
      stage: { name: "dependency", label: "After Task Completion", id: "dependency", position: 101 },
      actions: [],
    },
  };
  const stageMap: Record<string, StageInfo> = stages.reduce(
    (acc, stage) => {
      acc[camelCase(stage.name)] = {
        stage: { ...stage, label: stage.name },
        actions: [],
      };
      return acc;
    },
    initialStageMap as Record<string, StageInfo>,
  );

  actions.forEach((action) => {
    const stageName = actionStageName(startDate, action);
    if (!stageName) {
      return;
    }
    if (stageMap[stageName]) {
      stageMap[stageName].actions.push(action);
    } else {
      stageMap[stageName] = {
        stage: { name: stageName, label: titleize(stageName), id: stageName, position: 11 },
        actions: [action],
      };
    }
  });
  return Object.values(stageMap).sort((a, b) => a.stage.position - b.stage.position);
}

function getRecipientDisplay(recipient: BasicUser | MessageServiceChannel | undefined): string {
  if (!recipient) {
    return "TBD";
  }

  if ("fullName" in recipient) {
    // It's a User
    return `${recipient?.firstName?.charAt(0)}. ${recipient?.lastName}`;
  }

  if ("name" in recipient) {
    // It's a MessageServiceChannel
    return `#${recipient.name}`;
  }

  return "TBD";
}

function toValue(action: BaseAssignedAction | BaseTask): string {
  const recipient = action?.assignee || action?.targetable;
  const returnText = getRecipientDisplay(recipient);
  const relationship = action?.workflowAction?.actionable?.relationTarget
    ? ` (${titleize(action.workflowAction.actionable.relationTarget)})`
    : "";

  return `${returnText}${relationship}`;
}

function BlankCard() {
  return <Box width="250px" h="120px" />;
}

interface MiniCombinedActionCardProps {
  combinedActionId: CombinedActionId;
  handleClick: MouseEventHandler<HTMLAnchorElement>;
  showImmediateDate?: boolean;
  placeholderData: CombinedAction;
}

function MiniCombinedActionCard({ combinedActionId, placeholderData, showImmediateDate = false, handleClick }: MiniCombinedActionCardProps) {
  const headerTextColor = useColorModeValue("notBlack.600", "white");
  const {
    data: combinedAction,
  } = useCombinedActionDetailQuery(combinedActionId, {
    placeholderData,
    staleTime: 0,
  });

  const action = combinedAction || placeholderData;

  if (!action) {
    console.error("Action is undefined");
    return null;
  }
  const { label, value } = combinedActionDateInfo(action, showImmediateDate);

  const { icon, iconStyles } = actionIcon(action);
  return (
    <LinkBox as={Card} width="250px" height="auto">
      <Flex py="4" direction="column" justify="space-between" h="100%">
        <Flex direction="column">
          <Flex px="3" align="flex-start" justify="space-between" w="100%">
            <Flex align="flex-start" gap="2">
              <Flex align="center" {...iconStyles}>
                <Icon boxSize="4" as={icon} />
              </Flex>
              <Text fontSize="sm" fontWeight="bold" color={headerTextColor} textAlign="left">
                <LinkOverlay href="#" onClick={handleClick}>
                  {truncateEnd(action?.title || action?.name, 42)}
                </LinkOverlay>
              </Text>
            </Flex>
            <DotMenu combinedAction={action} handleEdit={handleClick} />
          </Flex>
          <Flex direction="column" px="9" mt="1" gap="1">
            <HorizontalGreyBlackField size="xs" label="To">{toValue(action)}</HorizontalGreyBlackField>
            <HorizontalGreyBlackField size="xs" label={label}>{value}</HorizontalGreyBlackField>
          </Flex>
        </Flex>
        <Flex width="fit-content" px="9">
          <CombinedActionStatusTag action={action} mt="2" />
        </Flex>
      </Flex>
    </LinkBox>
  );
}

interface ReviewColumnProps {
  title: string;
}

function ReviewColumn({ title, children }: ReviewColumnProps & PropsWithChildren) {
  return (
    <Flex direction="column" align="start" width="250px" mt="2">
      <Text fontSize="sm" fontWeight="bold" color="notBlack.400">
        {title}
      </Text>
      <Divider my="2" borderColor="notBlack.50" borderWidth="1.25px" />
      <Flex direction="column" mt="2" gap="2">
        {children}
      </Flex>
    </Flex>
  );
}

interface ActionsByStageProps {
  newHireJourney: ExtendedNewHireJourney;
  isLoading: boolean;
  showImmediateDate?: boolean
}

export default function ActionsByStage({ newHireJourney, isLoading, showImmediateDate = false }: ActionsByStageProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedActionId, setSelectedActionId] = useState<CombinedActionId | "">("");
  const [searchText, setSearchText] = useState("");
  const [actionType, setActionType] = useState<ActionableType | "">("");
  const [relationTargetType, setRelationTargetType] = useState<PathRelationTarget | "">("");

  const handleOpenDrawer = useCallback(
    (action: CombinedAction) => {
      setSelectedActionId(toActionId(action));
      onOpen();
    },
    [onOpen],
  );

  const createClickHandler = useCallback(
    (action: CombinedAction) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      handleOpenDrawer(action);
    },
    [handleOpenDrawer],
  );

  const { data: stages } = useStageListQuery();

  const { actionsByStage, viewableActionIds } = useMemo(() => {
    if (!newHireJourney?.startDate) {
      return { actionsByStage: [], viewableActionIds: new Set() };
    }

    // Map notifiers to tasks
    mapNotifiersToTasks(newHireJourney);

    // Combine tasks and assigned actions
    const combinedActions = uniqBy(
      [
        ...newHireJourney.tasks,
        ...newHireJourney.assignedPaths
          .flatMap((path) => path.actions)
          .filter((action) => action.actionType === "email" || action.actionType === "chat"),
      ],
      "id",
    );

    // Filter actions based on search and action type
    const filteredActions = filterActions(combinedActions, searchText, actionType, relationTargetType);
    const viewableActionIdsSet = new Set(filteredActions.map((action) => toActionId(action)));

    // Map actions to stages using filtered actions
    const mappedStages = mapActionsToStages({
      startDate: newHireJourney.startDate,
      stages,
      actions: combinedActions,
    });

    return { actionsByStage: mappedStages, viewableActionIds: viewableActionIdsSet };
  }, [
    newHireJourney,
    stages,
    searchText,
    actionType,
    relationTargetType,
  ]);

  if (isLoading || !stages.length) {
    return (
      <Center mt="4">
        <Text color="fg.emphasized">Loading...</Text>
      </Center>
    );
  }

  return (
    <Flex direction="column">
      <Box>
        <Stack mt="2">
          <SearchBar
            border="1px solid var(--stroke-vibes, #F1F1F1)"
            minWidth="200px"
            flexShrink="1"
            mr="2"
            name="actionsSearch"
            onInput={setSearchText}
          />
          <Stack w="100%" direction={{ base: "column", lg: "row" }} gap="4" mt="4" justify="space-between" align={{ base: "flex-start", lg: "flex-end" }}>
            <Flex gap="4" flexShrink="0" minWidth="342px" direction={{ base: "column", xl: "row" }} mr={{ base: "2", xxl: "8" }}>
              <SelectableTargetType relationTargetType={relationTargetType} setRelationTargetType={setRelationTargetType} />
            </Flex>
            <Flex gap="4" flexShrink="0" minWidth="342px" direction={{ base: "column", xl: "row" }} mr={{ base: "2", xxl: "8" }}>
              <SelectableActionType actionType={actionType} setActionType={setActionType} />
            </Flex>
          </Stack>
        </Stack>
      </Box>
      <Box overflowX="auto" width="100%">
        <Flex mt={4} gap="2" pb="1" minWidth="max-content">
          {(actionsByStage || []).map((stageInfo) => (
            <ReviewColumn key={stageInfo.stage.id} title={stageInfo.stage.label}>
              {stageInfo.actions.length ? (
                sortAssignedActionsAndTaskByTriggerDate(stageInfo.actions).map((action) => {
                  if (!viewableActionIds.has(toActionId(action))) return null;
                  return (
                    <MiniCombinedActionCard
                      key={toActionId(action)}
                      combinedActionId={toActionId(action)}
                      placeholderData={action}
                      handleClick={createClickHandler(action)}
                      showImmediateDate={showImmediateDate}
                    />
                  );
                })
              ) : (
                <BlankCard />
              )}
            </ReviewColumn>
          ))}
        </Flex>
      </Box>
      <AssignedActionSideDrawer isOpen={isOpen} onClose={onClose} resourceId={selectedActionId} />
    </Flex>
  );
}
