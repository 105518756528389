import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { Path } from "models/automation";
import React, { useState } from "react";
import PathAssignForm from ".";

interface CreateScheduledWorkflowButtonWithModalProps {
  workflow: Path;
}

export default function CreateScheduledWorkflowButtonWithModal({
  workflow,
}: CreateScheduledWorkflowButtonWithModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [peopleTeamContactId, updatePeopleTeamContactId] = useState("");

  const initialRef = React.useRef(null);

  return (
    <>
      <Button variant="solid" onClick={onOpen} colorScheme="brand">
        Assign to New Hire
      </Button>

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign to New Hire</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <PathAssignForm
              workflowId={workflow?.id}
              onClose={onClose}
              peopleTeamContactId={peopleTeamContactId}
              updatePeopleTeamContactId={updatePeopleTeamContactId}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
