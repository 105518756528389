import { useMemo } from "react";
import { Link, SystemStyleObject } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import { User } from "models/user";
import { timeOnUSDate } from "helpers/time";
import ROLE from "models/role";
import { getAvailableRolesOptions } from "features/Role/helpers";
import { userKeys } from "features/User/hooks";
import GenericTable, { GenericTableProps, simpleColumn } from "./GenericTable";

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "Name",
    cell: (row) => (
      <Link
        as={RouterLink}
        to={{
          pathname: SHARED_ROUTES.showUser.replace(":id", row.getValue().id),
        }}
      >
        {row.getValue().fullName}
      </Link>
    ),
    enableMultiSort: true,
  }),
  simpleColumn({ attribute: "title", label: "Title" }, columnHelper, true),
  simpleColumn({ attribute: "workEmail", label: "Work Email" }, columnHelper, true),
  columnHelper.accessor("permission", {
    cell: (info) => (info.getValue()
      ? getAvailableRolesOptions(info.getValue() as ROLE[])
        .map((value) => value.label)
        .join(", ")
      : ""),
    header: "Roles",
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row, {
    id: "createdAt",
    header: "Date Added",
    cell: (row) => timeOnUSDate(row.getValue().createdAt),
    enableMultiSort: true,
  }),
];

interface WorkforceTableProps {
  searchText?: string;
  filters?: FilterMeta[];
  sx?: SystemStyleObject;
}

export default function WorkforceTable({ filters, searchText, sx }: WorkforceTableProps) {
  const additionalQueries = useMemo(() => {
    const queries: Record<string, string> = {};
    queries.roles = String(true);
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);
  const tableProps: GenericTableProps<User> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<User[]>>(`${API_ROUTES.users.base}${query}`, signal)
      .then((response) => response.payload),
    // Generic table adds the filter to the end of this automatically
    queryKey: userKeys.lists(),
    columns,
    meta: { pagination: { perPage: 100 }, filters },
    additionalQueries,
  };

  return <GenericTable sx={sx} {...tableProps} />;
}

WorkforceTable.defaultProps = {
  searchText: undefined,
  filters: undefined,
  sx: undefined,
};
