import { useQuery } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { Notifier } from "models/notifiers";
import apiClient from "services/ApiClient";

export const notifierKeys = {
  all: ["notifiers"] as const,
  lists: () => [...notifierKeys.all, "list"] as const,
  list: (filters: string) => [...notifierKeys.lists(), { filters }] as const,
  details: () => [...notifierKeys.all, "detail"] as const,
  detail: (id: string) => [...notifierKeys.details(), id] as const,
};

export const useNotifierListQuery = (options = {}) => useQuery({
  queryKey: notifierKeys.lists(),
  queryFn: async () => apiClient.get(API_ROUTES.notifiers.base),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useNotifierDetailQuery = (notifierId: string, options = {}) => useNotifierListQuery({
  select: (data: Notifier[]) => (data.find((notifier) => (notifier.id === notifierId))),
});
