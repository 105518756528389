/* eslint-disable react/jsx-one-expression-per-line */
import {
  Box, BoxProps, ButtonGroup, HStack, IconButton, Skeleton, Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Table } from "@tanstack/react-table";
import { range } from "lodash";
import Select from "components/Select";

interface PaginationProps<T> extends BoxProps {
  pageSizeOptions?: number[];
  table: Table<T>;
  isLoading?: boolean;
}

export default function SmallArrowPagination<T>({ table, isLoading, pageSizeOptions, ...props }: PaginationProps<T>) {
  return (
    <Box px={{ base: "4", md: "6" }} pb="5" {...props}>
      <Skeleton isLoaded={!isLoading}>
        <HStack spacing="3" justify="end">
          {pageSizeOptions
            && pageSizeOptions.length > 0
            && Math.min(...pageSizeOptions) < table.getFilteredRowModel().rows.length && (
              <>
                <Text color="fg.muted" textStyle="sm" mr={4}>
                  Page Size
                </Text>
                <Select
                  useBasicStyles
                  onChange={(newValue) => {
                    if (newValue) table.setPageSize(Number(newValue.value));
                  }}
                  name="Page Size"
                  placeholder="Page Size"
                  options={pageSizeOptions.map((i) => ({ value: i, label: `${i}` }))}
                  defaultValue={{
                    value: table.getState().pagination.pageSize,
                    label: `${table.getState().pagination.pageSize}`,
                  }}
                />
              </>
          )}
          {table.getPageCount() > 1 && (
            <>
              <Text color="fg.muted" textStyle="sm" mr={4}>
                Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </Text>
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: "full", md: "auto" }}
                variant="camino-secondary"
              >
                <IconButton
                  aria-label="Previous Page"
                  icon={<ChevronLeftIcon boxSize={5} />}
                  onClick={table.previousPage}
                  isDisabled={!table.getCanPreviousPage()}
                  _hover={{ bg: "coral.700", _disabled: { bg: "inherit" } }}
                  _disabled={{
                    backgroundColor: "gray.300",
                    color: "gray.300",
                    pointerEvents: "none",
                  }}
                />
                <IconButton
                  aria-label="Next Page"
                  icon={<ChevronRightIcon boxSize={5} />}
                  onClick={table.nextPage}
                  isDisabled={!table.getCanNextPage()}
                  _hover={{ bg: "coral.700", _disabled: { bg: "inherit" } }}
                  _disabled={{
                    backgroundColor: "gray.300",
                    color: "gray.300",
                    pointerEvents: "none",
                  }}
                />
              </ButtonGroup>
            </>
          )}
        </HStack>
      </Skeleton>
    </Box>
  );
}

SmallArrowPagination.defaultProps = {
  pageSizeOptions: range(20, 100, 20),
  isLoading: false,
};
