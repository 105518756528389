import * as yup from "yup";
import { EmailType } from "models/automation";
import {
  ACTION_TYPE_FIELD,
  BODY_FIELD,
  EMAIL_TYPE_FIELD,
  MESSAGE_FIELD,
  NAME_FIELD,
  SUBJECT_FIELD,
  TIME_OF_DAY_FIELD,
  TIMEZONE_FIELD,
  TRIGGER_DATE_FIELD,
} from "./definitions";

const dateTime = yup.object({
  datetime: yup.date().required(),
});

type DateTimeSchema = yup.InferType<typeof dateTime>;

const dependency = yup.object({
  dependencyType: yup.mixed<"Task">().required(),
  dependencyId: yup.string().required(),
});

type DependencySchema = yup.InferType<typeof dependency>;

export const formSchema = yup.object({
  actionType: yup
    .mixed<"email" | "chat" | "task_notifier" | "task_reminder">()
    .label(ACTION_TYPE_FIELD.label)
    .required(),
  name: yup.string().label(NAME_FIELD.label).required(),
  trigger: yup.object({
    type: yup.mixed<"date_time" | "dependency">().required(),
    data: yup.mixed<DateTimeSchema | DependencySchema>().when("type", {
      is: "date_time",
      then: () => dateTime.required(),
      otherwise: () => dependency.required(),
    }),
  }),
  subject: yup.string().when(ACTION_TYPE_FIELD.name, {
    is: "email",
    then: (schema) => schema.label(SUBJECT_FIELD.label).required(),
    otherwise: (schema) => schema.optional(),
  }),
  emailType: yup.mixed<EmailType>().when(ACTION_TYPE_FIELD.name, {
    is: "email",
    then: (schema) => schema.label(EMAIL_TYPE_FIELD.label).required(),
    otherwise: (schema) => schema.optional(),
  }),
  body: yup.string().when(ACTION_TYPE_FIELD.name, {
    is: "email" || "chat",
    then: (schema) => schema.label(BODY_FIELD.label).required(),
    otherwise: (schema) => schema.optional(),
  }),
  message: yup.string().when(ACTION_TYPE_FIELD.name, {
    is: "task_reminder",
    then: (schema) => schema.label(MESSAGE_FIELD.label).required(),
    otherwise: (schema) => schema.optional(),
  }),
  triggerDate: yup.date().label(TRIGGER_DATE_FIELD.label).notRequired(),
  timezone: yup.string().label(TIMEZONE_FIELD.label).notRequired(),
  timeOfDay: yup.string().label(TIME_OF_DAY_FIELD.label).notRequired(),
});

export type Schema = yup.InferType<typeof formSchema>;
