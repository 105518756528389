import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import AssignedTaskCard from "features/Task/AssignedTaskCard";
import { useTaskDetailQuery } from "features/Task/hooks";
import { newHireJourneyPathById } from "helpers/url";

export default function TaskPage() {
  const { id, newHireJourneyId } = useParams<{ id: string; newHireJourneyId: string }>();

  const { data, isLoading } = useTaskDetailQuery(id || "");

  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("My Tasks");
    if (data && !isLoading) {
      if (newHireJourneyId) {
        setBreadcrumbs([
          {
            name: "New Hire",
            toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourneyId }),
            isCurrentPage: false,
          },
          {
            name: "Tasks",
            toUrl: `${newHireJourneyPathById(newHireJourneyId, "tasks")}`,
            isCurrentPage: false,
          },
          {
            name: data?.title,
            isCurrentPage: true,
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: "My Tasks",
            toUrl: generatePath(SHARED_ROUTES.tasks.index),
            isCurrentPage: false,
          },
          {
            name: data?.title,
            isCurrentPage: true,
          },
        ]);
      }
    }
  }, [newHireJourneyId, data, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <AssignedTaskCard task={data} isLoading={isLoading} />
    </AppPage>
  );
}
