import LoadingCard from "components/Card/LoadingCard";
import ProfileForm from "components/Forms/ProfileForm";
import { useUserDetailQuery } from "features/User/hooks";
import useCaminoStore from "hooks/useCaminoStore";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { useEffect } from "react";

export default function ProfilePage() {
  const id = useCaminoStore((state) => state.currentUser?.id);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const { data: user, isLoading } = useUserDetailQuery(id || "");

  useEffect(() => {
    if (user && !isLoading) {
      setPageTitle("My Profile");
      setBreadcrumbs([
        {
          name: "My Profile",
          isCurrentPage: true,
        },
      ]);
    }
  }, [user, isLoading, setBreadcrumbs, setPageTitle]);

  return <AppPage>{user ? <ProfileForm user={user} /> : <LoadingCard />}</AppPage>;
}
