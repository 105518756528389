/* eslint-disable import/prefer-default-export */

import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { apiAssignedPathPathById } from "helpers/url";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import apiClient from "services/ApiClient";

export const assignedPathKeys = {
  all: ["assignedPaths"] as const,
  lists: () => [...assignedPathKeys.all, "list"] as const,
  list: (filters: string) => [...assignedPathKeys.lists(), { filters }] as const,
  details: () => [...assignedPathKeys.all, "detail"] as const,
  detail: (id: string) => [...assignedPathKeys.details(), id] as const,
};

export const useAssignedPathDetailQuery = (id: string, options = {}) => useQuery<AssignedPath>({
  queryKey: assignedPathKeys.detail(id),
  queryFn: async () => apiClient.get(apiAssignedPathPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export function useRemoveAssignedPath(assignedPathId: string, onClose?: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: () => apiClient.delete<AssignedPath>(apiAssignedPathPathById(assignedPathId)),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(res.onboardingJourneyId) });
      toast({
        title: "Path Removed",
        status: "success",
      });
      onClose?.();
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}
